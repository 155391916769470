import { all, fork } from 'redux-saga/effects';
import homepageSaga from './homepageSaga';
import authSaga from './authSaga';
import cardHoldersSaga from './cardHoldersSaga';
import newsSaga from './newsSaga';
import campaignsSaga from './campaignsSaga';
import memberWorkplacesSaga from './memberWorkplacesSaga';
import categoriesSaga from './categoriesSaga';
import bannersSaga from './bannersSaga';
import complainsSaga from './complainsSaga';
import faqsSaga from './faqsSaga';
import tknsSaga from './tknsSaga';
import gknsSaga from './gknsSaga';
import adminUsersSaga from './adminUsersSaga';
import apiUsersSaga from './apiUsersSaga';
import transactionsSaga from './transactionsSaga';
import allTransactionsSaga from './allTransactionsSaga';
import organizationSaga from './organizationsSaga';
import cardtypesSaga from './cardtypesSaga';
import memberWorkplaceCampaignsSaga from './memberWorkplaceCampaignsSaga';
import memberWorkplaceEmployeesSaga from './memberWorkplaceEmployeesSaga';
import professionsSaga from './professionsSaga';
import dashboardSaga from './dashboardSaga';
import linksSaga from './linksSaga';
import reportsSaga from './reportsSaga';

export default function* sagas() {
  yield all([
    fork(homepageSaga),
    fork(authSaga),
    fork(cardHoldersSaga),
    fork(newsSaga),
    fork(campaignsSaga),
    fork(memberWorkplacesSaga),
    fork(categoriesSaga),
    fork(bannersSaga),
    fork(complainsSaga),
    fork(faqsSaga),
    fork(tknsSaga),
    fork(gknsSaga),
    fork(adminUsersSaga),
    fork(apiUsersSaga),
    fork(transactionsSaga),
    fork(allTransactionsSaga),
    fork(organizationSaga),
    fork(cardtypesSaga),
    fork(memberWorkplaceCampaignsSaga),
    fork(memberWorkplaceEmployeesSaga),
    fork(professionsSaga),
    fork(dashboardSaga),
    fork(linksSaga),
    fork(reportsSaga),
  ]);
}
