import * as types from "../actionTypes";

export const getTKMemberWorkplacesRequest = (reqParams) => ({
  type: types.GET_TK_MEMBER_WORKPLACES_REQUEST,
  payload: reqParams,
});

export const getTKMemberWorkplacesSucceeded = (data) => ({
  type: types.GET_TK_MEMBER_WORKPLACES_SUCCEEDED,
  payload: data,
});

export const getTKMemberWorkplacesFailed = () => ({
  type: types.GET_TK_MEMBER_WORKPLACES_FAILED,
});

// pre-register
export const preregisterTKMemberWorkplacesRequest = (reqParams) => ({
  type: types.PREREGISTER_TK_MEMBER_WORKPLACES_REQUEST,
  payload: reqParams,
});

export const preregisterTKMemberWorkplacesSucceeded = () => ({
  type: types.PREREGISTER_TK_MEMBER_WORKPLACES_SUCCEEDED,
});

export const preregisterTKMemberWorkplacesFailed = () => ({
  type: types.PREREGISTER_TK_MEMBER_WORKPLACES_FAILED,
});

export const getTKMWPByIdRequest = (id) => ({
  type: types.GET_TK_MWP_BY_ID_REQUEST,
  payload: id,
});

export const getTKMWPByIdSucceeded = (data) => ({
  type: types.GET_TK_MWP_BY_ID_SUCCEEDED,
  payload: data,
});

export const getTKMWPByIdFailed = () => ({
  type: types.GET_TK_MWP_BY_ID_FAILED,
});

export const getTKMWPNamesRequest = (categories) => ({
  type: types.GET_TK_MWP_NAMES_REQUEST,
  payload: categories,
});

export const getTKMWPNamesSucceeded = (data) => ({
  type: types.GET_TK_MWP_NAMES_SUCCEEDED,
  payload: data,
});

export const getTKMWPNamesFailed = () => ({
  type: types.GET_TK_MWP_NAMES_FAILED,
});

export const clearTKMemberWorkplaces = () => ({
  type: types.CLEAR_TK_MEMBER_WORKPLACES,
});

// ADMIN
// LIST
export const getTKAdminMWPRequest = (reqParams) => ({
  type: types.GET_TK_ADMIN_MWP_REQUEST,
  payload: reqParams,
});

export const getTKAdminMWPSucceeded = ({
  data,
  page,
  search,
  status,
  totalPages,
}) => ({
  type: types.GET_TK_ADMIN_MWP_SUCCEEDED,
  payload: {
    data,
    page,
    search,
    status,
    totalPages,
  },
});

export const getTKAdminMWPFailed = () => ({
  type: types.GET_TK_ADMIN_MWP_FAILED,
});

// CREATE
export const createTKAdminMWPRequest = (reqParams) => ({
  type: types.CREATE_TK_ADMIN_MWP_REQUEST,
  payload: reqParams,
});

export const setShowMWPAddModal = (show) => ({
  type: types.SET_SHOW_MWP_ADD_MODAL,
  payload: show,
});

// EDIT
export const editTKAdminMWPRequest = (reqParams) => ({
  type: types.EDIT_TK_ADMIN_MWP_REQUEST,
  payload: reqParams,
});

export const setShowMWPEditModal = (show) => ({
  type: types.SET_SHOW_MWP_EDIT_MODAL,
  payload: show,
});

// DELETE
export const deleteTKAdminMWPRequest = (reqParams) => ({
  type: types.DELETE_TK_ADMIN_MWP_REQUEST,
  payload: reqParams,
});

export const setShowMWPDeleteModal = (show) => ({
  type: types.SET_SHOW_MWP_DELETE_MODAL,
  payload: show,
});

export const processTKAdminMWPSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_MWP_SUCCEEDED,
});

export const processTKAdminMWPFailed = () => ({
  type: types.PROCESS_TK_ADMIN_MWP_FAILED,
});

export const postTKMWPExportExcelRequest = (reqParams) => ({
  type: types.POST_TK_MWP_EXPORT_EXCEL_REQUEST,
  payload: reqParams,
});

export const exportTKCompanyDiscountExportExcelRequest = (reqParams) => ({
  type: types.EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_REQUEST,
  payload: reqParams,
});

export const exportTKCompanyDiscountExportExcelWithoutFileRequest = (data) => ({
  type: types.EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_REQUEST,
  payload: data,
});

export const exportTKCompanyDiscountExportExcelWithoutFileSucceeded = (
  data
) => ({
  type: types.EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_SUCCEEDED,
  payload: data,
});

export const exportTKCompanyDiscountExportExcelWithoutFileFailed = () => ({
  type: types.EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_FAILED,
});

export const tkMWPForgotPasswordRequest = (data) => ({
  type: types.TK_MWP_FORGOTPASSWORD_REQUEST,
  payload: data,
});

export const tkMWPForgotPasswordSucceeded = (data) => ({
  type: types.TK_MWP_FORGOTPASSWORD_SUCCEEDED,
  payload: data,
});

export const tkMWPForgotPasswordFailed = () => ({
  type: types.TK_MWP_FORGOTPASSWORD_FAILED,
});

export const tkMWPNewPasswordRequest = (data) => ({
  type: types.TK_MWP_NEWPASSWORD_REQUEST,
  payload: data,
});

export const tkMWPNewPasswordSucceeded = (data) => ({
  type: types.TK_MWP_NEWPASSWORD_SUCCEEDED,
  payload: data,
});

export const tkMWPNewPasswordFailed = () => ({
  type: types.TK_MWP_NEWPASSWORD_FAILED,
});

export const setShowMWPTransModal = (show) => ({
  type: types.SET_SHOW_MWP_TRANS_MODAL,
  payload: show,
});
