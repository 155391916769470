import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/professions';
import { 
  GET_TK_PROFESSIONS_REQUEST,
  GET_TK_ADMIN_PROFESSIONS_REQUEST,
  DELETE_TK_ADMIN_PROFESSIONS_REQUEST,
  CREATE_TK_ADMIN_PROFESSIONS_REQUEST,
  SEARCH_TK_PROFESSIONS_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';

function* watchGetTKProfessions() {
  yield takeEvery(GET_TK_PROFESSIONS_REQUEST, getTKProfessions);
}

function* getTKProfessions(action) {
  try {
    const res = yield call(apiCall, 
      'get', 
      'public/professions/getProfessions'
    );

    yield put(actions.getTKProfessionsSucceeded({
      data: [...res.data.data]
    }));
    
  } catch (err) {
    console.log(err)
    yield put(actions.getTKProfessionsFailed());
  }
}


function* watchGetTKAdminProfessions() {
  yield takeEvery(GET_TK_ADMIN_PROFESSIONS_REQUEST, getTKAdminProfessions);
}

function* getTKAdminProfessions(action) {
  try {
    const res = yield call(apiCall, 
      'get', 
      'api/loyalty/profession/getProfessions'
    );

    yield put(actions.getTKAdminProfessionsSucceeded({
      data: [...res.data.data]
    }));
    
  } catch (err) {
    console.log(err)
    yield put(actions.getTKAdminProfessionsFailed());
  }
}



function* watchSearchTKProfessions() {
  yield takeEvery(SEARCH_TK_PROFESSIONS_REQUEST, searchTKProfessions);
}

function* searchTKProfessions(action) {
  try {
    const {search} = action.payload;
    const res = yield call(apiCall, 
      'post', 
      'api/loyalty/profession/searchprofessions',
      {
        search
      }
    );
    
    yield put(actions.searchTKProfessionsSucceeded([...res.data.data]));
  } catch (err) {
    console.log(err)
    yield put(actions.searchTKProfessionsFailed());
  }
}

function* watchCreateTKAdminProfessions() {
  yield takeLatest(CREATE_TK_ADMIN_PROFESSIONS_REQUEST, createTKAdminProfessions);
}

function* createTKAdminProfessions(action) {
  try {
    const {data, handleSetShowProfessionsAddModal} = action.payload;
    yield call(apiCall, 
      'post', 
      'api/loyalty/profession', 
      {
        ...data
      }
    );
    yield put(actions.processTKAdminProfessionsSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kart Tipi başarıyla oluşturuldu',
      'success'
    );
    yield call(handleSetShowProfessionsAddModal, false);
    yield put(actions.getTKAdminProfessionsRequest());
  } catch (err) {
    console.log(err)
    yield put(actions.processTKAdminProfessionsFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


function* watchDeleteTKAdminProfessions() {
  yield takeLatest(DELETE_TK_ADMIN_PROFESSIONS_REQUEST, deleteTKAdminProfessions);
}

function* deleteTKAdminProfessions(action) {
  try {
    const {selectedId} = action.payload;
    yield call(apiCall, 
      'put', 
      `api/loyalty/profession/${selectedId}/delete`
    );
    yield put(actions.processTKAdminProfessionsSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kart Tipi başarıyla silindi',
      'success'
    );
    yield put(actions.setShowProfessionsDeleteModal(false));
    yield put(actions.getTKAdminProfessionsRequest());
  } catch (err) {
    yield put(actions.processTKAdminProfessionsFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


export default function* professionsSaga() {
  yield all([
    fork(watchGetTKProfessions),
    fork(watchGetTKAdminProfessions),
    fork(watchCreateTKAdminProfessions),
    fork(watchDeleteTKAdminProfessions),
    fork(watchSearchTKProfessions)
  ]);
}
