import React from 'react';
import './TKExcelExportButton.scss';
import TKButton from '../../TKButton/TKButton';
import TKIcon from '../../TKIcon/TKIcon';

const TKExcelExportButton = ({ btnTitle,onClick}) => {
  return (
    <div className="tk_excel-export-btn">
      <TKButton 
        onClick={onClick}
      >
        {`${btnTitle}`}
        <TKIcon
          name="file-excel"
          size="16"
        />
      </TKButton>
    </div>
  );
};

export default TKExcelExportButton;

TKExcelExportButton.defaultProps = {
  btnTitle: ""
}