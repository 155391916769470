import * as types from '../actionTypes';

const initialState = {
  listProfessions: [],
  loading: false,
  error: false,
  search:'',
  admin: {
    processLoading: false,
    processError: false,
    showAddModal: false,
    showDeleteModal: false
  }
}

const professions = (state = initialState, action) => {

  switch(action.type) {
    case types.GET_TK_PROFESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_PROFESSIONS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        listProfessions: [...action.payload.data]
      }
    case types.GET_TK_PROFESSIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.GET_TK_ADMIN_PROFESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_ADMIN_PROFESSIONS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        listProfessions: [...action.payload.data]
      }
    case types.GET_TK_ADMIN_PROFESSIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    
    case types.SEARCH_TK_PROFESSIONS_REQUEST:
        return {
          ...state,
          loading: true,
          error: false
        }
    case types.SEARCH_TK_PROFESSIONS_SUCCEEDED:
        return {
          ...state,
          listProfessions: [...action.payload],
          loading: false,
          error: false
        }
    case types.SEARCH_TK_PROFESSIONS_FAILED:
        return {
          ...state,
          loading: false,
          error: true
        }
    case types.CREATE_TK_ADMIN_PROFESSIONS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_PROFESSIONS_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showAddModal: action.payload
        }
      }
    case types.PROCESS_TK_ADMIN_PROFESSIONS_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_ADMIN_PROFESSIONS_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: true
        }
      }
    case types.SET_SHOW_PROFESSIONS_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload,
        }
      }
    case types.DELETE_TK_ADMIN_PROFESSIONS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    default:
      return state;
  }
};

export default professions;