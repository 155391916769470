import * as types from '../actionTypes';

// PUBLIC
export const getTKNewsRequest = page => ({
  type: types.GET_TK_NEWS_REQUEST,
  payload: {
    page
  }
});

export const getTKNewsSucceeded = ({data, totalCount}) => ({
  type: types.GET_TK_NEWS_SUCCEEDED,
  payload: {
    data,
    totalCount
  }
});

export const getTKNewsFailed = () => ({
  type: types.GET_TK_NEWS_FAILED
});

export const getTKNewsByIdRequest = id => ({
  type: types.GET_TK_NEWS_BY_ID_REQUEST,
  payload: id
});

export const getTKNewsByIdSucceeded = data => ({
  type: types.GET_TK_NEWS_BY_ID_SUCCEEDED,
  payload: data
});

export const getTKNewsByIdFailed = () => ({
  type: types.GET_TK_NEWS_BY_ID_FAILED
});

export const clearTKNews = () => ({
  type: types.CLEAR_TK_NEWS
});

// ADMIN
// LIST
export const getTKAdminNewsRequest = reqParams => ({
  type: types.GET_TK_ADMIN_NEWS_REQUEST,
  payload: reqParams
});

export const getTKAdminNewsSucceeded = ({data, page, search, status, totalPages}) => ({
  type: types.GET_TK_ADMIN_NEWS_SUCCEEDED,
  payload: {
    data,
    page,
    search,
    status,
    totalPages
  }
});

export const getTKAdminNewsFailed = () => ({
  type: types.GET_TK_ADMIN_NEWS_FAILED
});

// CREATE
export const createTKAdminNewsRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_NEWS_REQUEST,
  payload: reqParams
});

export const setShowNewsAddModal = show => ({
  type: types.SET_SHOW_NEWS_ADD_MODAL,
  payload: show
});

// EDIT
export const editTKAdminNewsRequest = reqParams => ({
  type: types.EDIT_TK_ADMIN_NEWS_REQUEST,
  payload: reqParams
});

export const setShowNewsEditModal = show => ({
  type: types.SET_SHOW_NEWS_EDIT_MODAL,
  payload: show
});

// DELETE
export const deleteTKAdminNewsRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_NEWS_REQUEST,
  payload: reqParams
});

export const setShowNewsDeleteModal = show => ({
  type: types.SET_SHOW_NEWS_DELETE_MODAL,
  payload: show
});

export const processTKAdminNewsSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_NEWS_SUCCEEDED
});

export const processTKAdminNewsFailed = () => ({
  type: types.PROCESS_TK_ADMIN_NEWS_FAILED
});

export const postTKNewsExportExcelRequest = reqParams => ({
  type: types.POST_TK_NEWS_EXPORT_EXCEL_REQUEST,
  payload: reqParams
});