import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";
import { AuthContext } from "./context/authContext";
import { PublicLayout } from "./containers/layouts";
import NotFound from "./containers/pages/PublicLayout/NotFound/NotFound";
import { tkLoginSucceeded, tkGetUserInfo } from "./store/actions/auth";

const App = () => {
  const dispatch = useDispatch();
  const {access_token} = useSelector(state => state.auth.user);
  
  const token = localStorage.getItem("token");
  const expires_in = localStorage.getItem("expires_in");
  const user = JSON.parse(localStorage.getItem('user'));

  if (token && !access_token) {
    dispatch(tkLoginSucceeded({...user, access_token: token, expires_in}));
    dispatch(tkGetUserInfo())
  }

  const keys = Object.keys(routes);

  return (
    <AuthContext.Provider value={token}>
      <Router>
        <Switch>
          {keys.map((layoutName) => {
            let layout = routes[layoutName];
            return routes[layoutName].children.map((route, index) => {
              return (
                <route.routeComponent
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  render={(props) => (
                    <layout.component>
                      <route.component {...props} />
                    </layout.component>
                  )}
                />
              );
            });
          })}
          <Route
            path="*"
            render={() => (
              <PublicLayout>
                <NotFound />
              </PublicLayout>
            )}
          />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
