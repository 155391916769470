import * as types from '../actionTypes';

// ADMIN
// LIST
export const getTKAdminAURequest = reqParams => ({
  type: types.GET_TK_ADMIN_AU_REQUEST,
  payload: reqParams
});

export const getTKAdminAUSucceeded = ({data, page, totalPages}) => ({
  type: types.GET_TK_ADMIN_AU_SUCCEEDED,
  payload: {
    data,
    page,
    totalPages
  }
});

export const getTKAdminAUFailed = () => ({
  type: types.GET_TK_ADMIN_AU_FAILED
});

// CREATE
export const createTKAdminAURequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_AU_REQUEST,
  payload: reqParams
});

export const setShowAUAddModal = show => ({
  type: types.SET_SHOW_AU_ADD_MODAL,
  payload: show
});

// EDIT
export const editTKAdminAURequest = reqParams => ({
  type: types.EDIT_TK_ADMIN_AU_REQUEST,
  payload: reqParams
});

export const setShowAUEditModal = show => ({
  type: types.SET_SHOW_AU_EDIT_MODAL,
  payload: show
});

// DELETE
export const deleteTKAdminAURequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_AU_REQUEST,
  payload: reqParams
});

export const setShowAUDeleteModal = show => ({
  type: types.SET_SHOW_AU_DELETE_MODAL,
  payload: show
});

export const processTKAdminAUSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_AU_SUCCEEDED
});

export const processTKAdminAUFailed = () => ({
  type: types.PROCESS_TK_ADMIN_AU_FAILED
});


export const tkUpdateLinks = ({data}) => ({
  type: types.TK_UPDATE_LINKS,
  payload: {
    data
  }
})