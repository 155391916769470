import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const TKMap = ({google, position, center, onClick, ...props}) => {
  return (
    <Map
      google={google}
      zoom={10}
      initialCenter={position}
      center={{...position, ...center}}
      onClick = {onClick}
      { ...props }
    >
      <Marker position={position}/>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyDvtycOiZ8Ipd2XhmiA59dB6NJc2ByuSS8",
  language: "tr"
})(TKMap);

TKMap.defaultProps = {
  position: {lat: 40.89573333940974, lng: 29.356143369314236},
  center: {},
  onClick: () => {},
}