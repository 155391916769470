import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import {
  getTKAdminCampsRequest,
  createTKAdminCampsRequest,
  setShowCampsAddModal,
  editTKAdminCampsRequest,
  setShowCampsEditModal,
  deleteTKAdminCampsRequest,
  setShowCampsDeleteModal,
  postTKCampsExportExcelRequest,
  getTKCampsParticipantExportExcelRequest
} from "../../../../../store/actions/campaigns";
import { contentColumns } from "../../../../../constants/columnsConfigurations";
import TKContentForm from "../../../../../components/AdminLayout/TKForm/TKContentForm/TKContentForm";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKAddExportContainer from "../../../../../components/TKAddExportContainer/TKAddExportContainer";
import TKSearchText from "../../../../../components/TKSearchText/TKSearchText";
import { formatUrl } from "../../../../../helpers/utilities";

const TKCampaigns = () => {
  const DEFAULT_TYPE = "PERCENT";
  const DEFAULT_STATUS = "ACTIVE";
  const DEFAULT_CAMPAIGN_CODE_TYPE = "GENERATE";


  const SEARCH_PARAMS =
    [
      {
        name: 'short_detail',
        holder: 'Kısa Detay ile Ara'
      },
      {
        name: 'detail',
        holder: 'Detay ile Ara'
      },
      {
        name: 'owner',
        holder: 'İşletme Adı ile Ara'
      }
    ];



  const initialSearchFormData = {
    short_detail: "",
    detail: "",
    owner: "",
    status: DEFAULT_STATUS
  }


  const initialFormData = {
    short_detail: "",
    detail: "",
    plain_detail: "",
    image: null,
    previousimage: null,
    start_at: null,
    finish_at: null,
    campaign_type: DEFAULT_TYPE,
    amount: "",
    cardtype: "",
    owner: null,
    status: DEFAULT_STATUS,
    max_participant: "0",
    max_join_for_holders: "1",
    campaign_code: {
      type: DEFAULT_CAMPAIGN_CODE_TYPE,
      excel: null,
      fixed_code: null,
    },
  };

  const initialFormDataValidate = {
    short_detail: {
      required: true
    },
    detail: {
      required: true
    },
    image: {
      required: true
    },
    start_at: {
      required: true
    },
    finish_at: {
      required: true
    },
    campaign_type: {
      required: true
    },
    amount: {
      required: true,
      isNumeric: true,
      isPercent: "campaign_type",
    },
    owner: {
      required: true,
    },
    status: {
      required: true,
    },
    campaign_code: {
      isValidCampaignCodeGenerator: true,
    },
    max_participant: {
      isNumeric: true,
    },
    max_join_for_holders: {
      isNumeric: true,
    }
  };
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.campaigns);
  const { list, page, search, status, totalPages, processLoading, showAddModal, showEditModal, showDeleteModal } = useSelector(state => state.campaigns.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({ ...initialFormDataValidate });
  const [searchFormData, setSearchFormData] = useState({ ...initialSearchFormData });
  const [smsFlag, setSmsFlag] = useState(false);


  useEffect(() => {
    filterData(page, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const doSearch = (name, value, stat) => {

    setSearchFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    dispatch(getTKAdminCampsRequest({
      page: 0,
      searchFormData: {
        ...searchFormData,
        [name]: value
      },
      status: stat,
    }));

  }

  const filterData = (page) => {
    dispatch(getTKAdminCampsRequest({
      page,
      searchFormData,
      status
    }));
  }

  const setIfItIsBlank = () => {

    if (formData.max_participant === '') {

      setFormData(prevState => ({
        ...prevState,
        max_participant: 0
      }));

    }


    if (formData.max_join_for_holders === '') {

      setFormData(prevState => ({
        ...prevState,
        max_join_for_holders: 1
      }));

    }



  }

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }));
    if (name !== "image") {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
    else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
        previousimage: null
      }));
    }
  }

  const checkValidation = () => {

    const errorMsgs = validate(formData, formDataValidate);

    if (Object.keys(errorMsgs).length > 1) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    else {
      if (Object.keys(errorMsgs).length == 1) {

        if (Object.keys(errorMsgs).includes('max_participant')) {

          if (formData.max_participant !== "0") {
            setFormDataErrors({ ...errorMsgs });
            return false;

          }
          else {
            return true;
          }

        }

      }

    }
    return true;

  }

  const handleSetShowCampsAddModal = show => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowCampsAddModal(show));
  }

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(createTKAdminCampsRequest({
        data: {
          ...formData,
          short_detail: formData.short_detail.trim(),
          s_short_detail: formatUrl(formData.short_detail.trim())
        },
        page,
        searchFormData,
        status,
        handleSetShowCampsAddModal
      }));
    }
  }

  const handleSetShowCampsEditModal = show => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowCampsEditModal(show));
  }

  const handleShowEditModalClick = row => {
    const { _id, short_detail, detail, unused_campaign_codes, start_at, finish_at, campaign_type, amount, owner, status, max_participant, max_join_for_holders, image, cardtype, isLimitless,is_campaign_codes_stabil,is_campaign_codes_generated,is_campaign_codes_uploaded } = row;
    console.log(row)
    setSelectedId(_id);
    if (status === "WFA") {
      setSmsFlag(true)
    }
    else {
      setSmsFlag(false)
    }
    setFormData(prevState => ({
      short_detail,
      detail,
      unused_campaign_codes,
      start_at: new Date(start_at),
      finish_at: new Date(finish_at),
      campaign_type: campaign_type || prevState.campaign_type,
      amount,
      owner: owner?._id,
      ownerentitle: owner?.entitle,
      status, 
      cardtype,
      max_participant,
      max_join_for_holders,
      image: null,
      isLimitless,
      is_campaign_codes_stabil,
      is_campaign_codes_uploaded,
      is_campaign_codes_generated,
      previousimage: image
    }));
    setFormDataValidate(prevState => ({
      ...prevState,
      image: {}
    }));
    handleSetShowCampsEditModal(true);
  }

  const handleEditOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(editTKAdminCampsRequest({
        data: {
          ...formData,
          short_detail: formData.short_detail.trim(),
          s_short_detail: formatUrl(formData.short_detail.trim())
        },
        selectedId,
        page,
        searchFormData,
        status,
        smsFlag,
        handleSetShowCampsEditModal
      }));
    }
  }

  const handleExportClick = () => {

    dispatch(postTKCampsExportExcelRequest({
      searchFormData
    }));

  }

  const handleCampaignParticipantExportClick = (id) => {

    dispatch(getTKCampsParticipantExportExcelRequest(
      id
    ));



  }



  const handleSetShowCampsDeleteModal = show => {
    dispatch(setShowCampsDeleteModal(show));
  }

  const handleShowDeleteModalClick = id => {
    setSelectedId(id);
    handleSetShowCampsDeleteModal(true);
  }

  const handleDeleteOnClick = () => {
    dispatch(deleteTKAdminCampsRequest({
      selectedId,
      page,
      searchFormData,
      status
    }));
  }

  const campaignsColumns = contentColumns(false, true, {
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
    onExport: (id) => handleCampaignParticipantExportClick(id)
  });

  return (
    <>
      <TKAdminPageHeader
        header="kampanyalar yönetimi"
      />

      <TKSearchText
        searchparams={SEARCH_PARAMS}
        doSearch={doSearch}
        isStatus={true}
        status={searchFormData.status}
      />


      <TKAddExportContainer
        type="Kampanya"
        showAddModal={showAddModal}
        handleSetShowAddModal={handleSetShowCampsAddModal}
        modalHeader="Kampanya formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
        size="xl"
        formData={formData}
        formDataErrors={formDataErrors}
        handleOnChange={handleOnChange}
        handleExportClick={handleExportClick}
        setIfItIsBlank={setIfItIsBlank}
      >
      </TKAddExportContainer>

      <TKTableCard>
        {!loading &&
          <TKDataTable
            columns={campaignsColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            activePage={page}
            totalPages={totalPages}
            handleFetchData={filterData}
            searchedText={search}
          />
        }
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowCampsEditModal}
        header="kampanya güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
        size="xl"
      >
        <TKContentForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isEdit={true}
          setIfItIsBlank={setIfItIsBlank}
          searchStatus={searchFormData.status}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowCampsDeleteModal}
        header="kampanya silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Kampanya silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKCampaigns;
