import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import TKLogo from "../../TKLogo/TKLogo";
import TKIcon from "../../TKIcon/TKIcon";
import { useDispatch, useSelector } from "react-redux";
import { tkGetTransactionsRequest } from "../../../store/actions/transactions";
import TKTransactionsContainer from "./TKTransactionsContainer/TKTransactionsContainer";
import TKWorkplaceManagement from "../TKWorkplaceManagement/TKWorkplaceManagement";
import { userRoles } from "../../../constants/general";
import "./TKUserInfo.scss";
import { exportTKCompanyDiscountExportExcelWithoutFileRequest } from "../../../store/actions/memberWorkplaces";
import TKDatePicker from "../../AdminLayout/TKInputs/TKDatePicker/TKDatePicker";
import { subtractMonths } from "../../../helpers/utilities";

const TKUserInfo = () => {
  const initialFormData = {
    start_at: subtractMonths(new Date(), 1),
    finish_at: new Date(),
  };
  const dispatch = useDispatch();
  const info = useSelector((state) => state.auth.userInfo);
  const { list, loading, error } = useSelector((state) => state.transactions);
  const { exportList } = useSelector((state) => state.memberWorkplaces);

  const [discount, setDiscount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [companyAmount, setCompanyAmount] = useState(0);
  const [companyDiscount, setCompanyDiscount] = useState(0);
  const [formData, setFormData] = useState({ ...initialFormData });

  useEffect(() => {
    if (info) {
      dispatch(tkGetTransactionsRequest());
    }
  }, [info]);

  const onChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(
      exportTKCompanyDiscountExportExcelWithoutFileRequest({
        selectedId: info?.company?._id,
        start_at: formData?.start_at,
        finish_at: formData?.finish_at,
      })
    );
  }, [info, formData]);

  useEffect(() => {
    setDiscount(0);
    setAmount(0);
    if (list) {
      list.map((values) => {
        if (values.discount) {
          if (values.campaign_type == "PERCENT") {
            setDiscount(
              (prevstate) => prevstate + (values.amount * values.discount) / 100
            );
          } else {
            setDiscount((prevstate) => prevstate + values.discount);
          }
        }
        if (values.amount) {
          setAmount((prevstate) => prevstate + values.amount);
        }
      });
    }
  }, [list, formData, info]);

  useEffect(() => {
    setCompanyDiscount(0);
    setCompanyAmount(0);
    if (exportList) {
      exportList.map((values) => {
        if (values.campaign_type == "PERCENT") {
          setCompanyDiscount(
            (prevstate) => prevstate + (values.amount * values.discount) / 100
          );
        } else {
          setCompanyDiscount((prevstate) => prevstate + values.discount);
        }
        if (values.amount) {
          setCompanyAmount((prevstate) => prevstate + values.amount);
        }
      });
    }
  }, [exportList, formData, info]);

  return (
    <>
      <Card className="border-0" style={{ marginTop: "-30px" }}>
        <Card.Body className="p-5">
          {info?.role === userRoles.COMPANY && (
            <div
              className=" d-flex justify-content-end"
              style={{ gap: "10px" }}
            >
              <Form.Group className="d-flex flex-column d-md-block w-25">
                <Form.Label>başlama tarihi</Form.Label>
                <TKDatePicker
                  dateFormat="P"
                  showTimeInput={false}
                  dateValue={formData.start_at}
                  onChange={(date) => onChange("start_at", date)}
                />
              </Form.Group>
              <Form.Group className="d-flex flex-column d-md-block w-25">
                <Form.Label>bitiş tarihi</Form.Label>
                <TKDatePicker
                  dateFormat="P"
                  showTimeInput={false}
                  dateValue={formData.finish_at}
                  onChange={(date) => onChange("finish_at", date)}
                />
              </Form.Group>
            </div>
          )}
          {info?.role ? (
            <div
              className="tk_user-data"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <h5
                style={{
                  color: "#343a40",
                  fontWeight: "600",
                  fontSize: "32px",
                }}
              >
                Tuzlakart ile :
              </h5>
              <div className="row mb-6 text-center">
                <div className="col-sm-12 col-md-4 d-flex align-items-end">
                  <TKIcon name="payment" color="#343a40" size="70" />
                  <div>
                    <h4 style={{ color: "#343a40", fontWeight: "600" }}>
                      {info?.role === userRoles.COMPANY
                        ? companyAmount
                        : amount}
                      TL
                    </h4>
                    <h5 style={{ color: "#343a40" }}>HARCAMA</h5>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-end">
                  <TKIcon name="payment" color="#343a40" size="70" />
                  <div>
                    <h4 style={{ color: "#343a40", fontWeight: "600" }}>
                      {info?.role === userRoles.COMPANY
                        ? companyDiscount
                        : discount}
                      TL
                    </h4>
                    <h5 style={{ color: "#343a40" }}>KAZANÇ</h5>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 d-flex align-items-end">
                  <TKIcon name="payment" color="#343a40" size="70" />
                  <div>
                    <h4 style={{ color: "#343a40", fontWeight: "600" }}>
                      {info?.role === userRoles.COMPANY
                        ? exportList?.length
                        : list.length}
                    </h4>
                    <h5 style={{ color: "#343a40" }}>İŞLEM</h5>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "Lütfen giriş yapınız."
          )}

          {info?.role === userRoles.USER && (
            <div className="tk_user-data">
              <Row className="tk_user-row">
                <div className="user-col col-sm-12 col-md-4">
                  <div className="text-dark">İsim Soyisim</div>
                  <div className="text-dark tk_balance-modal-name">{`${info?.name} ${info?.surname}`}</div>
                </div>
                <div className="user-col col-sm-12 col-md-4">
                  <div className="text-dark">Bakiye</div>
                  <div className="text-dark tk_balance-modal-balance">{`${info?.balance} TL`}</div>
                </div>
                <div className="user-col col-sm-12 col-md-4">
                  <div className="text-dark">Meslek</div>
                  <div className="text-dark tk_balance-modal-balance">{`${info?.profession.name}`}</div>
                </div>
              </Row>
              <Row>
                <div className="user-col col-sm-12 col-md-4">
                  <div className="text-dark">TCKN</div>
                  <div className="text-dark tk_balance-modal-name">{`${info?.tckn}`}</div>
                </div>
                <div className="user-col col-sm-12 col-md-4">
                  <div className="text-dark">Telefon</div>
                  <div className="text-dark tk_balance-modal-balance">{`${info?.telephone_number}`}</div>
                </div>
                <div className="user-col col-sm-12 col-md-4">
                  <div className="text-dark">E-mail</div>
                  <div className="text-dark tk_balance-modal-balance">{`${info?.email}`}</div>
                </div>
              </Row>
            </div>
          )}
          {info?.role === userRoles.COMPANY && (
            <Row>
              <Col>
                <TKWorkplaceManagement />
              </Col>
            </Row>
          )}
          {info?.role === userRoles.USER && (
            <Row>
              <Col>
                <TKTransactionsContainer
                  list={list}
                  loading={loading}
                  error={error}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default TKUserInfo;

TKUserInfo.defaultProps = {
  setShowModal: () => {},
};
