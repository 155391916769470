import * as types from '../actionTypes';

export const getTKFAQAdminRequest = reqParams => ({
    type: types.GET_TK_FAQ_ADMIN_REQUEST,
    payload: reqParams
  });

  export const getTKFAQAdminSucceeded = ({data}) => ({
    type: types.GET_TK_FAQ_ADMIN_SUCCEEDED,
    payload: {
      data
    }
  });
  
  export const getTKFAQAdminFailed = () => ({
    type: types.GET_TK_FAQ_ADMIN_FAILED
  });

  export const setShowFAQAddModal = show => ({
    type: types.SET_SHOW_FAQ_ADD_MODAL,
    payload: show
  });

  export const setShowFAQEditModal = show => ({
    type: types.SET_SHOW_FAQ_EDIT_MODAL,
    payload: show
  });

  export const setShowFAQDeleteModal = show => ({
    type: types.SET_SHOW_FAQ_DELETE_MODAL,
    payload: show
  });


  export const createTKAdminFAQRequest = reqParams => ({
    type: types.CREATE_TK_ADMIN_FAQ_REQUEST,
    payload: reqParams
  
  })

  export const editTKAdminFAQRequest = reqParams => ({
    type: types.EDIT_TK_ADMIN_FAQ_REQUEST,
    payload: reqParams
  
  })
  
  export const deleteTKAdminFAQRequest = reqParams => ({
    type: types.DELETE_TK_ADMIN_FAQ_REQUEST,
    payload: reqParams
  
  })