import React, { useEffect, useState } from 'react';
import './TKPagination.scss';
import TKButton from '../../../TKButton/TKButton';
import TKIcon from '../../../TKIcon/TKIcon';

const TKPagination = ({pagesLength, activePage, onPageClick}) => {
  const [value, setValue] = useState(1);
  const [listedPages, setListedPages] = useState([]);

  useEffect(() => {
    renderPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    setValue(activePage);
  }, [activePage])

  const renderPages = () => {
    let pages = [];
    if (pagesLength <= 10) {
      for (let i = 1; i < pagesLength + 1; i++) {
        pages.push({ no: i, content: i});
      }
      setListedPages(pages);
    }
    if (pagesLength > 10) {
      if (value <= 5) {
        for (let i = 1; i < 6; i++) {
          pages.push({ no: i, content: i});
        }
        pages.push({ no: pagesLength - 5, content: '...' });
        pages.push({ no: pagesLength, content: pagesLength });
      }
      if (value > 5) {
        if (pagesLength - 5 <= value) {
          pages.push({ no: 1, content: 1});
          pages.push({ no: 5, content: '...'});
          for (let i = pagesLength - 5; i < pagesLength + 1; i++) {
            pages.push({ no: i, content: i });
          }
        } else {
          for (let i = value - 4; i < value + 1; i++) {
            pages.push({ no: i, content: i });
          }
          pages.push({ no: pagesLength - 5, content: '...' });
          pages.push({ no: pagesLength, content: pagesLength });
        }
      }
    }
    setListedPages(pages);
  }

  const handleChangePage = no => {
    setValue(no);
    onPageClick(no);
  }

  const prevPage = () => {
    handleChangePage(value - 1);
  }

  const nextPage = () => {
    handleChangePage(value + 1);
  }

  return (
    <ul className="tk_pagination">
      <li>
        <TKButton 
          variant="light"
          onClick={prevPage}
          disabled={value === 1}
        >
          <TKIcon
            name="arrow"
            color="#8c959d"
            size="13"
          />
        </TKButton>
      </li>
      {listedPages.map(page => (
        <li key={page.no}>
          <TKButton 
            variant="light"
            className={page.no === value ? "tk_pagination-active" : ""}
            onClick={() => handleChangePage(page.no)}
            disabled={page.no === value}
          >
            {page.content}
          </TKButton>
        </li>
      ))}
      <li>
        <TKButton 
          className="tk_pagination-chev-right"
          variant="light"
          onClick={nextPage}
          disabled={value === pagesLength}
        >
          <TKIcon
            name="arrow"
            color="#8c959d"
            size="13"
          />
        </TKButton>
      </li>
    </ul>
  );
};

export default TKPagination;

TKPagination.defaultProps = {
  pagesLength: 0,
  activePage: 0,
  onPageClick: () => {}
}