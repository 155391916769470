import TKIcon from "../components/TKIcon/TKIcon";
import {
  formatDate,
  formDateWithNullChecker,
  isJsonString,
} from "../helpers/utilities";
import { statusLabel, cardStatusLabel } from "./general";
import { Form } from "react-bootstrap";
import TKDownloadCardButton from "../components/TKButton/TKDownloadCardButton/TKDownloadCardButton";
import { convertFromRaw, EditorState } from "draft-js";

export const cardHoldersColumnsConfig = ({
  onEdit,
  onDelete,
  onClickBalance,
}) => [
  {
    name: "TC Kimlik No",
    selector: "tckn",
  },
  {
    name: "adı",
    selector: "name",
  },
  {
    name: "soyadı",
    selector: "surname",
  },
  {
    name: "Durum",
    selector: "status",
    cell: (row) => statusLabel[row.status],
  },
  {
    name: "Aktif Kart",
    selector: "activeCard",
  },
  {
    name: " Bakiye",
    selector: "balance",
  },
  {
    name: " Sms Doğrulama",
    selector: "valid",
    cell: (row) => (row.valid ? "Var" : "Yok"),
  },
  {
    name: "Oluşturulma Tarihi",
    selector: "created_at",
    cell: (row) => formatDate(new Date(row.created_at), "P p"),
  },
  {
    name: "Onaylanma Tarihi",
    selector: "approved_at",
    cell: (row) =>
      row.approved_at ? formatDate(new Date(row.approved_at), "P p") : "",
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => {
      return (
        <div>
          <i
            style={{
              width: `20px`,
              height: `20px`,
              fontSize: 25,
              cursor: "pointer",
            }}
            onClick={() => onClickBalance(row)}
          >
            {" "}
            {"\u20BA"}
          </i>
          {row?.status === "WFA" ? (
            <TKIcon
              name="plus"
              color="#969fa6"
              className="tk_delete mr-2"
              style={{ transform: "rotate(45deg)" }}
              size="20"
              title="Reddet"
              onClick={() => onDelete(row._id)}
            />
          ) : (
            <TKIcon
              name="delete"
              color="#969fa6"
              className="tk_delete mr-2"
              size="22"
              title="Sil"
              onClick={() => onDelete(row._id)}
            />
          )}
          <TKIcon
            name="edit"
            color="#969fa6"
            className="tk_edit"
            size="22"
            title="Güncelle"
            onClick={() => onEdit(row)}
          />
        </div>
      );
    },
  },
];

export const complainColumnsConfig = ({ onView }) => [
  {
    name: "Şikayet Başlığı",
    selector: "title",
    sortable: true,
  },
  {
    name: "Şikayet Yapan Kişi",
    selector: "sender",
    sortable: true,
  },
  {
    name: "Şikayet Konusu",
    selector: "content",
    sortable: true,
  },
  {
    name: "Şikayet Tarihi",
    selector: "created_at",
    sortable: true,
    cell: (row) => formatDate(new Date(row.created_at), "P p"),
  },
  {
    name: "Görülme Tarihi",
    selector: "seen_at",
    sortable: true,
    cell: (row) => formDateWithNullChecker(row.seen_at, "P p"),
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onView(row)}
        />
      </div>
    ),
  },
];

export const faqColumnsConfig = ({ onEdit, onDelete }) => [
  {
    name: "Başlığı",
    selector: "caption",
    sortable: true,
  },
  {
    name: "Detay",
    selector: "detail",
    cell: (row) => {
      if (isJsonString(row?.detail)) {
        return row?.plain_detail
          ? row.plain_detail
          : EditorState.createWithContent(
              convertFromRaw(JSON.parse(row?.detail))
            )
              .getCurrentContent()
              .getPlainText()
              .substring(0, 200) + "...";
      } else {
        return row?.detail.substring(0, 200) + "...";
      }
    },
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete mr-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
        <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        />
      </div>
    ),
  },
];

export const apiUsersColumnConfig = ({ onShowKey, onDelete }) => [
  {
    name: "Kullanici Adi",
    selector: "name",
    sortable: true,
  },
  {
    name: "Anahtar Tipi",
    selector: "type",
    sortable: true,
  },
  {
    name: "Anahtar",
    selector: "key",
    sortable: true,
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="bar-chart"
          color="#969fa6"
          className="tk_edit mr-2"
          size="22"
          title="Anahtar Göster"
          onClick={() => onShowKey(row)}
        />
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete mr-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
      </div>
    ),
  },
];

export const cardColumnsConfig = ({
  onEdit,
  onDelete,
  onSetActive,
  downloadButton,
}) => [
  {
    name: "Aktif Kart",
    selector: "status",
    width: "10px",
    cell: (row) => (
      <div className="d-flex justify-content-center">
        <Form.Control
          type="checkbox"
          style={{ width: "15px", height: "15px" }}
          checked={row.status === "ACTIVE"}
          onChange={(e) => onSetActive(row, e.target.checked)}
        />
      </div>
    ),
  },
  {
    name: "kart no",
    selector: "number",
  },
  {
    name: "kart veriliş tarihi",
    selector: "given_date",
    width: "10px",
    cell: (row) => row.given_date && formatDate(new Date(row.given_date), "P"),
  },
  {
    name: "kart tipi",
    selector: "name",
    width: "10px",
    cell: (row) => row.type.name,
  },

  {
    name: "Durum",
    width: "10px",
    selector: "status",
    cell: (row) => cardStatusLabel[row.status ? row.status : "PASSIVE"],
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "10px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete mr-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
        <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        />
        <TKDownloadCardButton user={downloadButton.user} card={row} />
      </div>
    ),
  },
];

export const contentColumns = (
  isNews,
  isCampaign,
  { onEdit, onDelete, onExport }
) => {
  let customColumns = [];
  if (isNews) {
    customColumns.push({
      name: "başlık",
      selector: "caption",
      sortable: true,
    });
  }
  let columns = [
    ...customColumns,
    {
      name: "kısa detay",
      selector: "short_detail",
      sortable: true,
    },
    {
      name: "detayı",
      selector: "detail",
      cell: (row) => {
        if (isNews) {
          return row?.plain_detail
            ? row.plain_detail
            : EditorState.createWithContent(
                convertFromRaw(JSON.parse(row?.detail))
              )
                .getCurrentContent()
                .getPlainText()
                .substring(0, 200) + "...";
        } else {
          return row?.detail.substring(0, 200) + "...";
        }
      },
    },
    {
      name: "işletme adı",
      selector: "entitle",
      sortable: true,
      cell: (row) => (row?.owner ? row.owner.entitle : ""),
    },
    {
      name: "durum",
      selector: "status",
      cell: (row) => statusLabel[row.status],
      sortable: true,
    },
    {
      name: "başlama tarihi",
      selector: "start_at",
      cell: (row) => formatDate(new Date(row.start_at), "P p"),
      sortable: true,
    },
    {
      name: "bitiş tarihi",
      selector: "finish_at",
      cell: (row) => formatDate(new Date(row.finish_at), "P p"),
      sortable: true,
    },
    {
      name: "silme tarihi",
      selector: "deleted_at",
      cell: (row) =>
        row.deleted_at && formatDate(new Date(row.deleted_at), "P p"),
    },
    {
      name: "ayarlar",
      selector: "settings",
      width: "150px",
      cell: (row) => (
        <div>
          {isCampaign && (
            <TKIcon
              name="file-excel"
              color="#969fa6"
              className="tk_file-excel mr-2"
              size="22"
              title="Rapor Al"
              onClick={() => onExport(row._id)}
            />
          )}
          <TKIcon
            name="delete"
            color="#969fa6"
            className="tk_delete mr-2"
            size="22"
            title="Sil"
            onClick={() => onDelete(row._id)}
          />
          <TKIcon
            name="edit"
            color="#969fa6"
            className="tk_edit"
            size="22"
            title="Güncelle"
            onClick={() => onEdit(row)}
          />
        </div>
      ),
    },
  ];
  return columns;
};

export const mwpColumnsConfig = ({ onEdit, onDelete, onExport }) => [
  {
    name: "Vergi Kimlik Numarası",
    selector: "vkn",
    sortable: true,
    width: "150px",
  },
  {
    name: "işletme adı",
    selector: "entitle",
    sortable: true,
  },
  {
    name: "durum",
    selector: "status",
    cell: (row) => statusLabel[row.status],
  },
  {
    name: "Başvuru Tarihi",
    selector: "created_at",
    cell: (row) => formatDate(new Date(row.created_at), "P p"),
  },
  {
    name: "Onaylanma Tarihi",
    selector: "approved_at",
    cell: (row) =>
      row.approved_at ? formatDate(new Date(row.approved_at), "P p") : "",
  },

  {
    name: "Pasife Alınma Tarihi",
    selector: "deactivation_date",
    cell: (row) =>
      row.status == "PASSIVE" && row.deactivation_date
        ? formatDate(new Date(row?.deactivation_date), "P p")
        : "",
  },

  {
    name: "Yetkili Telefonu",
    selector: "managerphone",
  },
  {
    name: "telefon",
    selector: "phone",
  },
  {
    name: "Online Başvuru",
    selector: "isOnline",
    cell: (row) =>
      row.isOnline === "true" ? (
        <span style={{ color: "green" }}>Evet</span>
      ) : (
        <span style={{ color: "red" }}>Hayır</span>
      ),
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="download"
          color="#969fa6"
          className=" mr-2"
          size="22"
          title="Üye İş Yeri Rapor Al"
          onClick={() => onExport(row._id)}
        />
        {row?.status === "WFA" ? (
          <TKIcon
            name="plus"
            color="#969fa6"
            className="tk_delete mr-2"
            style={{ transform: "rotate(45deg)" }}
            size="20"
            title="Reddet"
            onClick={() => onDelete(row._id)}
          />
        ) : (
          <TKIcon
            name="delete"
            color="#969fa6"
            className="tk_delete mr-2"
            size="22"
            title="Sil"
            onClick={() => onDelete(row._id)}
          />
        )}
        <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        />
      </div>
    ),
  },
];

export const mwpCampaignsColumns = ({ onEdit }) => {
  let columns = [
    {
      name: "kısa detay",
      selector: "short_detail",
      sortable: true,
    },
    {
      name: "detayı",
      selector: "detail",
      cell: (row) => (row?.plain_detail ? row.plain_detail : row.detail),
    },

    {
      name: "durum",
      selector: "status",
      cell: (row) => statusLabel[row.status],
      sortable: true,
    },
    {
      name: "başlama tarihi",
      selector: "start_at",
      sortable: true,
      cell: (row) => formatDate(new Date(row.start_at), "P p"),
    },
    {
      name: "bitiş tarihi",
      selector: "finish_at",
      sortable: true,
      cell: (row) => formatDate(new Date(row.finish_at), "P p"),
    },
    {
      name: "silme tarihi",
      selector: "deleted_at",
      cell: (row) =>
        row.deleted_at && formatDate(new Date(row.deleted_at), "P p"),
    },
    {
      name: "ayarlar",
      selector: "settings",
      width: "150px",
      cell: (row) => (
        <div>
          {/* <TKIcon
            name="delete"
            color="#969fa6"
            className="tk_delete mr-2"
            size="22"
            title="Sil"
            onClick={() => onDelete(row._id)}
          /> */}
          {row.status !== "ACTIVE" && (
            <TKIcon
              name="edit"
              color="#969fa6"
              className="tk_edit"
              size="22"
              title="Güncelle"
              onClick={() => onEdit(row)}
            />
          )}
        </div>
      ),
    },
  ];
  return columns;
};

export const categoriesColumnsConfig = ({ onDelete }) => [
  {
    name: "adı",
    selector: "name",
    sortable: true,
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete m-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
        {/* <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        /> */}
      </div>
    ),
  },
];

export const organizationsColumnsConfig = ({ onDelete }) => [
  {
    name: "adı",
    selector: "name",
    sortable: true,
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete m-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
        {/* <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        /> */}
      </div>
    ),
  },
];

export const mwpEmployeesColumnsConfig = (user, { onDelete }) => [
  {
    name: "TCKN",
    selector: "tckn",
    sortable: true,
  },
  {
    name: "adı",
    selector: "name",
    sortable: true,
  },
  {
    name: "Soyadı",
    selector: "surname",
    sortable: true,
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        {user._id !== row._id && (
          <TKIcon
            name="delete"
            color="#969fa6"
            className="tk_delete m-2"
            size="22"
            title="Sil"
            onClick={() => onDelete(row._id)}
          />
        )}
      </div>
    ),
  },
];

export const cardTypesColumnsConfig = ({ onDelete }) => [
  {
    name: "adı",
    selector: "name",
    sortable: true,
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete m-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
        {/* <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        /> */}
      </div>
    ),
  },
];

export const professionsColumnsConfig = ({ onDelete }) => [
  {
    name: "adı",
    selector: "name",
    sortable: true,
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete m-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
        {/* <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        /> */}
      </div>
    ),
  },
];

export const bannersColumnsConfig = ({ onDelete, onEdit }) => [
  {
    name: "başlık",
    selector: "caption",
    sortable: true,
  },
  {
    name: "tür",
    selector: "banner",
    sortable: true,
  },
  {
    name: "durum",
    selector: "status",
  },
  {
    name: "sıra numarası",
    selector: "order_number",
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete mr-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
        <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        />
      </div>
    ),
  },
];

export const adminUsersColumnsConfig = ({ onEdit, onDelete }) => [
  {
    name: "Kullanıcı Adı",
    selector: "email",
    sortable: true,
  },
  {
    name: "adı",
    selector: "name",
    sortable: true,
  },
  {
    name: "soyadı",
    selector: "surname",
    sortable: true,
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete mr-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
        <TKIcon
          name="edit"
          color="#969fa6"
          className="tk_edit"
          size="22"
          title="Güncelle"
          onClick={() => onEdit(row)}
        />
      </div>
    ),
  },
];

export const transactionsConfig = ({ onEdit, onDelete }) => [
  {
    name: "İşlem Tipi",
    selector: "type",
    sortable: true,
  },
  {
    name: "Kampanya Kısa Detay",
    selector: "short_detail",
    sortable: true,
  },
  {
    name: "Ad",
    selector: "name",
    sortable: true,
  },
  {
    name: "Soyad",
    selector: "surname",
    sortable: true,
  },
  {
    name: "Kampanya Kodu",
    selector: "cdata",
    sortable: true,
  },
  {
    name: "Katılım tarihi",
    selector: "at",
    sortable: true,
    cell: (row) => formatDate(new Date(row.at), "P p"),
  },
  {
    name: "Ücret",
    selector: "amount",
    sortable: true,
  },
  {
    name: "Harcanan",
    selector: "usedBalance",
    sortable: true,
  },
  ,
  {
    name: "İndirim",
    selector: "discount",
    sortable: true,
  },
];

export const UsersPaymentConfig = ({ onEdit, onDelete }) => [
  {
    name: "Firma",
    sortable: true,
    cell: (row) => "TUZLA BEL.",
  },
  {
    name: "Tip",
    selector: "type",
    cell: (row) => (row.type === "DEPOSIT" ? "YÜKLEME" : "HARCAMA"),
    sortable: true,
  },
  {
    name: "Tarih",
    selector: "at",
    sortable: true,
    cell: (row) => formatDate(new Date(row.at), "P p"),
  },
  {
    name: "Toplam Tutar",
    selector: "amount",
    sortable: true,
  },
  {
    name: "İndirim",
    selector: "discount",
    sortable: true,
  },
];

export const UsersCampaignConfig = ({ onEdit, onDelete }) => [
  {
    name: "Şirket",
    selector: "owner",
    sortable: true,
  },
  {
    name: "Kampanya",
    selector: "short_detail",
    sortable: true,
  },
  {
    name: "Tarih",
    selector: "at",
    sortable: true,
    cell: (row) => formatDate(new Date(row.at), "P p"),
  },
  {
    name: "Toplam Tutar",
    selector: "amount",
    sortable: true,
  },
  {
    name: "İndirim",
    selector: "discount",
    sortable: true,
  },
];

export const linksColumnsConfig = ({ onDelete, onEdit }) => [
  {
    name: "başlık",
    selector: "title",
  },
  {
    name: "link",
    selector: "link",
    sortable: true,
  },
  {
    name: "ayarlar",
    selector: "settings",
    width: "150px",
    cell: (row) => (
      <div>
        <TKIcon
          name="delete"
          color="#969fa6"
          className="tk_delete mr-2"
          size="22"
          title="Sil"
          onClick={() => onDelete(row._id)}
        />
      </div>
    ),
  },
];
