import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import {
  getTKAdminNewsRequest,
  createTKAdminNewsRequest,
  setShowNewsAddModal,
  editTKAdminNewsRequest,
  setShowNewsEditModal,
  deleteTKAdminNewsRequest,
  setShowNewsDeleteModal,
  postTKNewsExportExcelRequest,
} from "../../../../../store/actions/news";
import { contentColumns } from "../../../../../constants/columnsConfigurations";
import TKContentForm from "../../../../../components/AdminLayout/TKForm/TKContentForm/TKContentForm";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKAddExportContainer from "../../../../../components/TKAddExportContainer/TKAddExportContainer";
import TKSearchText from "../../../../../components/TKSearchText/TKSearchText";
import { formatUrl, isJsonString } from "../../../../../helpers/utilities";
import { EditorState, convertFromRaw } from "draft-js";

const SEARCH_PARAMS = [
  {
    name: "caption",
    holder: "Başlık ile Ara",
  },
  {
    name: "short_detail",
    holder: "Kısa Detay ile Ara",
  },
  {
    name: "detail",
    holder: "Detayda Arama Yap",
  },
];

const initialSearchFormData = {
  caption: "",
  short_detail: "",
  detail: "",
  status: "ACTIVE",
};

const TKNews = () => {
  const initialFormData = {
    caption: "",
    short_detail: "",
    detail: "",
    plain_detail: "",
    image: null,
    previousimage: null,
    start_at: null,
    finish_at: null,
    status: "ACTIVE",
    order_number: "",
  };
  const initialFormDataValidate = {
    caption: {
      required: true,
    },
    short_detail: {
      required: true,
    },
    detail: {
      required: true,
    },
    image: {
      required: true,
    },
    start_at: {
      required: true,
    },
    finish_at: {
      required: true,
    },
    status: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.news);
  const {
    list,
    page,
    search,
    status,
    totalPages,
    processLoading,
    showAddModal,
    showEditModal,
    showDeleteModal,
  } = useSelector((state) => state.news.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });
  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });

  useEffect(() => {
    filterData(page, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doSearch = (name, value, stat) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    dispatch(
      getTKAdminNewsRequest({
        page: 0,
        searchFormData: {
          ...searchFormData,
          [name]: value,
        },
        status: stat,
      })
    );
  };

  const filterData = (page) => {
    dispatch(
      getTKAdminNewsRequest({
        page,
        searchFormData,
        status,
      })
    );
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

    if (name !== "image") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        previousimage: null,
      }));
    }
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    console.log(errorMsgs);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowNewsAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowNewsAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        createTKAdminNewsRequest({
          data: {
            ...formData,
            s_short_detail: formatUrl(formData.short_detail.trim()),
          },
          page,
          searchFormData,
          status,
          handleSetShowNewsAddModal,
        })
      );
    }
  };

  const handleSetShowNewsEditModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowNewsEditModal(show));
  };

  const handleShowEditModalClick = (row) => {
    const {
      _id,
      caption,
      short_detail,
      detail,
      start_at,
      finish_at,
      status,
      image,
      order_number,
    } = row;
    setSelectedId(_id);
    setFormData({
      caption,
      detail: isJsonString(detail)
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(detail)))
        : detail,
      short_detail,
      start_at: new Date(start_at),
      finish_at: new Date(finish_at),
      status,
      image: null,
      previousimage: image,
      order_number,
    });
    setFormDataValidate((prevState) => ({
      ...prevState,
      image: {},
    }));
    handleSetShowNewsEditModal(true);
  };

  const handleEditOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        editTKAdminNewsRequest({
          data: {
            ...formData,
            s_short_detail: formatUrl(formData.short_detail.trim()),
          },
          selectedId,
          page,
          searchFormData,
          status,
          handleSetShowNewsEditModal,
        })
      );
    }
  };

  const handleSetShowNewsDeleteModal = (show) => {
    dispatch(setShowNewsDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowNewsDeleteModal(true);
  };

  const handleExportClick = () => {
    dispatch(
      postTKNewsExportExcelRequest({
        searchFormData,
      })
    );
  };

  const handleDeleteOnClick = () => {
    dispatch(
      deleteTKAdminNewsRequest({
        selectedId,
        page,
        searchFormData,
        status,
      })
    );
  };

  const newsColumns = contentColumns(true, false, {
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader header="haberler yönetimi" />

      <TKSearchText
        searchparams={SEARCH_PARAMS}
        doSearch={doSearch}
        isStatus={true}
        status={searchFormData.status}
      />

      <TKAddExportContainer
        type="Haber"
        showAddModal={showAddModal}
        handleSetShowAddModal={handleSetShowNewsAddModal}
        modalHeader="Haber formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
        size="xl"
        formData={formData}
        formDataErrors={formDataErrors}
        handleOnChange={handleOnChange}
        handleExportClick={handleExportClick}
      ></TKAddExportContainer>
      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={newsColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            activePage={page}
            totalPages={totalPages}
            handleFetchData={filterData}
            searchedText={search}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowNewsEditModal}
        header="haber güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
      >
        <TKContentForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isNews={true}
          isEdit={true}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowNewsDeleteModal}
        header="haber silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Haber silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKNews;
