import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/reports";
import {
  GET_TK_ADMIN_REPORTS_ACTIVECOMPANIES_REQUEST,
  GET_TK_ADMIN_REPORTS_ACTIVEUSERS_REQUEST,
  GET_TK_ADMIN_REPORTS_GAZIKART_REQUEST,
  GET_TK_ADMIN_REPORTS_GENCKART_REQUEST,
  GET_TK_ADMIN_REPORTS_MOSTCAMPAIGNS_REQUEST,
  GET_TK_ADMIN_REPORTS_TKART_REQUEST,
} from "../actionTypes";
import download from "downloadjs";
import moment from "moment";

function* watchGetTKAdminReportTkartStatus() {
  yield takeEvery(GET_TK_ADMIN_REPORTS_TKART_REQUEST, getTKAdminReportsTkart);
}

function* getTKAdminReportsTkart(action) {
  try {
    const res = yield call(apiCall, "post", "api/report/tuzlaCardCounts", {
      startDate: action.payload.start_at,
      endDate: action.payload.finish_at,
    });

    yield put(actions.getTKAdminReportsTkartSucceeded(res.data));
  } catch (err) {
    console.log(err);
    yield put(actions.getTKAdminReportsTkartFailed());
  }
}

function* watchGetTKAdminReportGenckardStatus() {
  yield takeEvery(
    GET_TK_ADMIN_REPORTS_GENCKART_REQUEST,
    getTKAdminReportsGenckart
  );
}

function* getTKAdminReportsGenckart(action) {
  try {
    const res = yield call(apiCall, "post", "api/report/gencCardCounts", {
      startDate: action.payload.start_at,
      endDate: action.payload.finish_at,
    });

    yield put(actions.getTKAdminReportsGencKartSucceeded(res.data));
  } catch (err) {
    console.log(err);
    yield put(actions.getTKAdminReportsGencKartFailed());
  }
}

function* watchGetTKAdminReportGazikardStatus() {
  yield takeEvery(
    GET_TK_ADMIN_REPORTS_GAZIKART_REQUEST,
    getTKAdminReportsGazikart
  );
}

function* getTKAdminReportsGazikart(action) {
  try {
    const res = yield call(apiCall, "post", "api/report/gaziCardCounts", {
      startDate: action.payload.start_at,
      endDate: action.payload.finish_at,
    });

    yield put(actions.getTKAdminReportsGaziKartSucceeded(res.data));
  } catch (err) {
    console.log(err);
    yield put(actions.getTKAdminReportsGaziKartFailed());
  }
}

function* watchGetTKAdminReportsActiveUsers() {
  yield takeLatest(
    GET_TK_ADMIN_REPORTS_ACTIVEUSERS_REQUEST,
    getTKAdminReportsActiveUsers
  );
}

function* getTKAdminReportsActiveUsers(action) {
  try {
    const res = yield call(
      apiCall,
      "get",
      "api/report/mostActiveUsers",
      {
        startDate: "03-06-2023",
        endDate: "04-06-2023",
      },
      {},
      "blob"
    );

    download(res.data, "aktif_kullanicilar.xlsx", "text/xlsx");
  } catch (err) {
    console.log(err);
  }
}

function* watchGetTKAdminReportsActiveCompanies() {
  yield takeLatest(
    GET_TK_ADMIN_REPORTS_ACTIVECOMPANIES_REQUEST,
    getTKAdminReportsActiveCompanies
  );
}

function* getTKAdminReportsActiveCompanies(action) {
  try {
    const res = yield call(
      apiCall,
      "get",
      "api/report/mostActiveCompanies",
      {
        startDate: "03-06-2023",
        endDate: "04-06-2023",
      },
      {},
      "blob"
    );

    download(res.data, "aktif_firmalar.xlsx", "text/xlsx");
  } catch (err) {
    console.log(err);
  }
}

function* watchGetTKAdminReportsMostCampaigns() {
  yield takeLatest(
    GET_TK_ADMIN_REPORTS_MOSTCAMPAIGNS_REQUEST,
    getTKAdminReportsMostCampaigns
  );
}

function* getTKAdminReportsMostCampaigns(action) {
  try {
    const res = yield call(
      apiCall,
      "get",
      "api/report/campaignParticipants",
      {
        startDate: "03-06-2023",
        endDate: "04-06-2023",
      },
      {},
      "blob"
    );

    download(res.data, "en_cok_kampanyaya_katilanlar.xlsx", "text/xlsx");
  } catch (err) {
    console.log(err);
  }
}

export default function* reportsSaga() {
  yield all([fork(watchGetTKAdminReportTkartStatus)]);
  yield all([fork(watchGetTKAdminReportGenckardStatus)]);
  yield all([fork(watchGetTKAdminReportGazikardStatus)]);
  yield all([fork(watchGetTKAdminReportsActiveUsers)]);
  yield all([fork(watchGetTKAdminReportsActiveCompanies)]);
  yield all([fork(watchGetTKAdminReportsMostCampaigns)]);
}
