import React from 'react';
import './TKSectionHeader.scss';
import TKIcon from '../TKIcon/TKIcon';

const TKSectionHeader = ({header, isShowAll, showAllOnClick}) => {
  return (
    <div className="tk_section-header">
      <div className="tk_section-header-text">
        <TKIcon
          name="newspaper"
          color="#8c959d"
        />
        {header}
      </div>
      {isShowAll && 
        <div className="tk_section-header-showAll" onClick={showAllOnClick}>
          Tümünü Gör
        </div>
      }
    </div>
  );
};

export default TKSectionHeader;

TKSectionHeader.defaultProps = {
  header: "",
  isShowAll: false,
  showAllOnClick: () => {}
}