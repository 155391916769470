import './TKAdminUserForm.scss';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { adminUserLinks } from '../../../../constants/general';
import { useState } from 'react';

const TKAdminUserForm = ({formData, formDataErrors, onChange}) => {
  const [showPassword, setShowPassword] = useState(true);

  const handleLinksOnChange = (id) => {
    const links = [...formData?.links];
    const idIndex = links.indexOf(id);
    if (idIndex !== -1) {
      links.splice(idIndex, 1);
      return onChange('links', links);
    }
    onChange('links', [...links, id]);
  }

  return (
    <Form className="tk_banner-form">
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>E-posta *</Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="Eposta"
              value={formData.email || ""}
              maxLength={100}
              onChange={e => onChange(e.target.name, e.target.value)}
              isInvalid={formDataErrors.email}
            />
            <Form.Text className="text-danger">{formDataErrors?.email}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>İsim *</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="İsim"
              value={formData.name || ""}
              maxLength={100}
              onChange={e => onChange(e.target.name, e.target.value)}
              isInvalid={formDataErrors.name}
            />
            <Form.Text className="text-danger">{formDataErrors?.name}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Soyisim *</Form.Label>
            <Form.Control
              type="text"
              name="surname"
              placeholder="Soyisim"
              value={formData.surname || ""}
              maxLength={100}
              onChange={e => onChange(e.target.name, e.target.value)}
              isInvalid={formDataErrors.surname}
            />
            <Form.Text className="text-danger">{formDataErrors?.surname}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>şifre *</Form.Label>
            <Form.Control
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Şifre"
              value={formData.password || ""}
              maxLength={100}
              onChange={e => onChange(e.target.name, e.target.value)}
              isInvalid={formDataErrors.password}
            />
            <Form.Text className="text-danger">{formDataErrors?.password}</Form.Text>
          </Form.Group>
        </Col>
        <Col sm={5}>
          <Form.Group>
            <Form.Label>Yetkiler</Form.Label>
            <div className="pl-2">
              {adminUserLinks.map(({title, id}, index) => (
                  <>
                    <Form.Check 
                      type="checkbox"
                      id={`admin-user-links-checkbox-${id}`}
                      label={title}
                      key={index}
                      checked={formData?.links?.includes(id)}
                      onChange={() => handleLinksOnChange(id)}
                    />
                  </>
              ))}
            </div>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

export default TKAdminUserForm;