import * as types from '../actionTypes'

const initialState = {
  loading: true,
  error: false,
  admin: {
    list: [],
    page: 0,
    totalPages: 0,
    processLoading: false,
    processError: false,
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false
  }
}

const adminUsers = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_TK_ADMIN_AU_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_ADMIN_AU_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...action.payload.data],
          page: action.payload.page,
          totalPages: action.payload.totalPages
        }
      }
    case types.GET_TK_ADMIN_AU_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.CREATE_TK_ADMIN_AU_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_AU_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showAddModal: action.payload
        }
      }
    case types.EDIT_TK_ADMIN_AU_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_AU_EDIT_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showEditModal: action.payload
        }
      }
    case types.DELETE_TK_ADMIN_AU_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_AU_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload
        }
      }
    case types.PROCESS_TK_ADMIN_AU_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_ADMIN_AU_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: true
        }
      }
    default:
      return state;
  }
}

export default adminUsers;