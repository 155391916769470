import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TKStatusContainer from "../../../../../components/TKStatusContainer/TKStatusContainer";
import { getTKAdminDashboardRequest } from "../../../../../store/actions/dashboard";
import {
  getTKAdminReportsGaziKartRequest,
  getTKAdminReportsGencKartRequest,
  getTKAdminReportsTkartRequest,
} from "../../../../../store/actions/reports";

const TKAdminPage = () => {
  const dispatch = useDispatch();
  const { dashboardStatus, dashboardStatusLoading, dashboardStatusError } =
    useSelector((state) => state.dashboard);

  const { tkartList, genckartList, gazikartList, reportsLoading } = useSelector(
    (state) => state.reports
  );

  const initialFormData = {
    start_at: "01-01-2023",
    finish_at: "02-01-2023",
  };

  useEffect(() => {
    dispatch(getTKAdminDashboardRequest());
    dispatch(getTKAdminReportsTkartRequest(initialFormData));
    dispatch(getTKAdminReportsGencKartRequest(initialFormData));
    dispatch(getTKAdminReportsGaziKartRequest(initialFormData));
  }, [dispatch]);
  return (
    <>
      <TKStatusContainer
        isDashboard={true}
        dashboardStatus={dashboardStatus}
        dashboardStatusLoading={dashboardStatusLoading}
        tkartList={tkartList}
        genckartList={genckartList}
        gazikartList={gazikartList}
        reportsLoading={reportsLoading}
      ></TKStatusContainer>
    </>
  );
};

export default TKAdminPage;
