import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from '../actions/dashboard';
import {  
    GET_TK_ADMIN_DASHBOARD_REQUEST } from "../actionTypes";


function* watchGetTKAdminDashboardStatus() {
  yield takeEvery(GET_TK_ADMIN_DASHBOARD_REQUEST, getTKAdminDashboardStatus);
}

function* getTKAdminDashboardStatus(action) {
  try {
    const res = yield call(apiCall, 
      'get', 
      'api/report/dashboardReport'
    );


    yield put(actions.getTKAdminDashboardSucceeded(res.data));
  } catch (err) {
    console.log(err)
    yield put(actions.getTKAdminDashboardFailed());
  }
}


export default function* dashboardSaga() {
  yield all([
    fork(watchGetTKAdminDashboardStatus)
  ])
}