import React from 'react';
import './TKSliderContainer.scss';
import TKSlider from './TKSlider/TKSlider';
import TKSectionHeader from '../TKSectionHeader/TKSectionHeader';
import TKLoader from '../TKLoaderContainer/TKLoader/TKLoader';

const TKSliderContainer = ({header, isShowAll, showAllOnClick, customSettings, sliderClassName, loading, children, placeholder}) => {
  return (
    <div className="tk_slider-container">
      {header && 
        <TKSectionHeader header={header} isShowAll={isShowAll} showAllOnClick={showAllOnClick}/>
      }
      {loading && <TKLoader/>}
      {!loading && 
        <>
        {placeholder && !children?.length ?
          (
            placeholder
          ) :
          (
            <TKSlider
              customSettings={customSettings}
              className={sliderClassName}
            >
              {children}
            </TKSlider>
          )
        }
        </>
      }
    </div>
  );
};

export default TKSliderContainer;

TKSliderContainer.defaultProps = {
  header: "",
  customSettings: {},
  sliderClassName: "tk_slider-five",
  loading: false
}