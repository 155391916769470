import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/categories';
import { 
  GET_TK_CATEGORIES_REQUEST,
  CREATE_TK_ADMIN_CATEGORIES_REQUEST,
  DELETE_TK_ADMIN_CATEGORIES_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';

function* watchGetTKCategories() {
  yield takeEvery(GET_TK_CATEGORIES_REQUEST, getTKCategories);
}

function* getTKCategories(action) {
  try {
    const res = yield call(apiCall, 
      'get', 
      'public/category'
    );
    yield put(actions.getTKCategoriesSucceeded([...res.data.data]));
  } catch (err) {
    yield put(actions.getTKCategoriesFailed());
  }
}

function* watchCreateTKAdminCategories() {
  yield takeLatest(CREATE_TK_ADMIN_CATEGORIES_REQUEST, createTKAdminCategories);
}

function* createTKAdminCategories(action) {
  try {
    const {data, handleSetShowCategoriesAddModal} = action.payload;
    yield call(apiCall, 
      'post', 
      'api/loyalty/category', 
      {
        ...data
      }
    );
    yield put(actions.processTKAdminCategoriesSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kategori başarıyla oluşturuldu',
      'success'
    );
    yield call(handleSetShowCategoriesAddModal, false);
    yield put(actions.getTKCategoriesRequest());
  } catch (err) {
    yield put(actions.processTKAdminCategoriesFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


function* watchDeleteTKAdminCategories() {
  yield takeLatest(DELETE_TK_ADMIN_CATEGORIES_REQUEST, deleteTKAdminCategories);
}

function* deleteTKAdminCategories(action) {
  try {
    const {selectedId} = action.payload;
    yield call(apiCall, 
      'put', 
      `api/loyalty/category/${selectedId}/delete`
    );
    yield put(actions.processTKAdminCategoriesSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kategori başarıyla silindi',
      'success'
    );
    yield put(actions.setShowCategoriesDeleteModal(false));
    yield put(actions.getTKCategoriesRequest());
  } catch (err) {
    yield put(actions.processTKAdminCategoriesFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

export default function* categoriesSaga() {
  yield all([
    fork(watchGetTKCategories),
    fork(watchCreateTKAdminCategories),
    fork(watchDeleteTKAdminCategories),
  ]);
}
