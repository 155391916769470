import React, { useState } from 'react';
import './TKCardsConfigureContainer.scss';
import { useDispatch, useSelector } from 'react-redux';
import TKAddNewButton from '../../../TKAddNewButton/TKAddNewButton';
import TKDataTable from '../../../TKDataTable/TKDataTable';
import { 
  createTKAdminCardRequest, 
  setShowCardAddModal, 
  editTKAdminCardRequest, 
  setShowCardEditModal, 
  deleteTKAdminCardRequest, 
  setShowCardDeleteModal,
  updateTKAdminAllCardsRequest,
  isCardNumberAlreadyUsedRequest,
  isCardNumberAlreadyUsedResponse
} from '../../../../../store/actions/cardHolders';
import { cardColumnsConfig } from '../../../../../constants/columnsConfigurations';
import TKCardsConfigureForm from './TKCardsConfigureForm/TKCardsConfigureForm';
import { validate } from '../../../../../helpers/validate';
import TKModal from '../../../TKModal/TKModal';

const TKCardsConfigureContainer = ({userId, user}) => {
  const initialFormData = {
    number: "",
    type: [],
    foodCard: false,
    magnetic_code: '',
  };
  const formDataValidate = {
    number: {
      required: true,
      length: 14
    },
    type: {
      required: true
    }
  };
  const dispatch = useDispatch();
  const {list, processLoading, showAddModal, showEditModal, showDeleteModal} = useSelector(state => state.cardHolders.admin.cards);
  const {page,cardError,searchFormData} = useSelector(state => state.cardHolders.admin);
  const [formData, setFormData] = useState({...initialFormData});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  const handleOnChange = (name, value) => {
    
    formDataErrors[name] && 
      setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }));
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }


  const handleCheckCardNumberAlreadyUsed = () => {
    dispatch(isCardNumberAlreadyUsedRequest({cardNumber:formData.number}))
  }

  const checkValidation = () => {
    let validationObj = {...formDataValidate};
    if (formData.foodCard) {
      validationObj = {...validationObj, magnetic_code: { required: true }};
    }
    const errorMsgs = validate(formData, validationObj);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({...errorMsgs});
      return false;
    }
    return true;
  }

  const handleSetShowCardAddModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataErrors({});
    }
    dispatch(setShowCardAddModal(show));
  }

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check && !cardError) {
      dispatch(createTKAdminCardRequest({
        data: {...formData},
        userId,
        list,
        page,
        searchFormData,
        handleSetShowCardAddModal
      }));
    }
  }

  const handleSetShowCardEditModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataErrors({});
    }
    dispatch(setShowCardEditModal(show));
  }

  const handleShowEditModalClick = row => {
    const {_id, number, type, magnetic_code} = row;
    setSelectedId(_id);
    setFormData({
      number,
      type,
      magnetic_code,
      foodCard: Boolean(magnetic_code)
    });
    handleSetShowCardEditModal(true);
  }

  const handleEditOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(editTKAdminCardRequest({
        data: {...formData},
        selectedId,
        userId,
        list,
        page,
        searchFormData,
        handleSetShowCardEditModal
      }));
    }
  }

  const handleSetShowCardDeleteModal = show => {
    dispatch(setShowCardDeleteModal(show));
  }

  const handleShowDeleteModalClick = id => {
    setSelectedId(id);
    handleSetShowCardDeleteModal(true);
  }

  const handleDeleteOnClick = () => {
    dispatch(deleteTKAdminCardRequest({
      selectedId,
      userId,
      list,
      page,
      searchFormData
    }));
  }

  const cardColumns = cardColumnsConfig({
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
    onSetActive: (row, isActive) => handleSetActiveCard(row, isActive),
    downloadButton: { user: user }
  });

  const handleSetActiveCard = (row, isActive) => {
    let newList
    if (isActive) {
      newList = list.map(card => card._id === row._id ? {...card, status: "ACTIVE"} : {...card, status: "PASSIVE"});
    } else {
      newList = list.map(card => card._id === row._id ? {...card, status: "PASSIVE"} : {...card});
    }
    dispatch(updateTKAdminAllCardsRequest({
      data: newList,
      userId,
      selectedId,
      page,
      searchFormData,
    }));
  }

  return (
    <div className="tk_ccc">
      <TKAddNewButton
        backdropClassName="tk_ccc-backdrop"
        showModal={showAddModal}
        setShowModal={handleSetShowCardAddModal}
        btnTitle="kart"
        modalHeader="kart ekle formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKCardsConfigureForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          checkCardNumberAlreadyUsed = {handleCheckCardNumberAlreadyUsed}
          cardError = {cardError}
          />
      </TKAddNewButton>
      <div className="tk_ccc-table-container" style={{width: "700px"}}>
        <TKDataTable
          columns={cardColumns}
          data={list}
          pagination={false}
        />
      </div>
      <TKModal
        backdropClassName="tk_ccc-backdrop"
        show={showEditModal}
        setShow={handleSetShowCardEditModal}
        header="kart güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
      >
        <TKCardsConfigureForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          checkCardNumberAlreadyUsed = {()=>{}}
          isEdit = {true}
        />
      </TKModal>
      <TKModal
        backdropClassName="tk_ccc-backdrop"
        show={showDeleteModal}
        setShow={handleSetShowCardDeleteModal}
        header="kart işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Kart silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </div>
  );
};

export default TKCardsConfigureContainer;