import React from 'react';
import './TKAdminPageHeader.scss';

const TKAdminPageHeader = ({header}) => {
  return (
    <div className="tk_display-l tk_admin-ph">
      {header}
      <hr/>
    </div>
  );
};

export default TKAdminPageHeader;