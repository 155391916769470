import React, { useState } from 'react';
import './TKFileInput.scss';
import { Form } from 'react-bootstrap';
import {customSwal} from '../../../../helpers/customSwal';

const TKFileInput = ({selectedFile, onChange, className, customLabel, id, disabled, fileType = "image", imgsrc,isEdit}) => {
  const imageTypes = ['image/png', 'image/jpeg'];
  const spreadsheetTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  const fileTypes = {
    image: imageTypes,
    spreadsheet: spreadsheetTypes,
  }
  const inputLabels = { 
    image: "Fotoğraf Seç", 
    spreadsheet: "Excel Dosyası Seç"
  };
  const INPUT_LABEL = customLabel ? customLabel : inputLabels[fileType];

  const [label, setLabel] = useState(selectedFile ? selectedFile.name : INPUT_LABEL);

  const handleOnChange = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      if ((fileType !== "image" || (file?.size / 1024 / 1024) < 1) && fileType !== 'spreadsheet') {
        if (fileTypes[fileType]?.includes(file.type)) {
          setLabel(e.target.files[0].name);
          onChange(file);
        } else {
          selectedFile && onChange(null);
          label !== INPUT_LABEL && setLabel(INPUT_LABEL);
        }
      }
      else if(fileType === "spreadsheet"){
        if( e.target.files[0].name.split(".")[1] == 'xlsx'){
          setLabel(e.target.files[0].name);
          onChange(file);
        }
        else {
          customSwal(
            '',
            `Lütfen xlsx uzantılı bir dosya ekleyiniz!`,
            'info',
          );
          selectedFile && onChange(null);
          label !== INPUT_LABEL && setLabel(INPUT_LABEL);
        }
        }
      else {
        customSwal(
          '',
          `Yüklemeye çalıştığınız resim 1 MB'tan daha büyük!`,
          'info',
        );
        selectedFile && onChange(null);
        label !== INPUT_LABEL && setLabel(INPUT_LABEL);
      }
    }    
    else {
      selectedFile && onChange(null);
      label !== INPUT_LABEL && setLabel(INPUT_LABEL);
    }
  }
  return (
    <>  
    <Form.File 
      id= {"custom-file"+id}
      className={`tk_file-input ${className}`.trim()}
      label={<span>{label}</span>}
      custom
      onChange={handleOnChange}
      data-browse="Yükle"
      disabled={disabled}
    />
    

    { ( imgsrc && 
    <img src={`${process.env.REACT_APP_IMAGES_URL}${imgsrc}`} style={{marginTop:10}} width="150" height="150" /> ) }


    { ( !imgsrc && isEdit && 
          <div style={{marginTop:10}} >Resminiz işleminizi kaydettikten sonra güncellenecektir.</div> ) }
    </>

  );
};

export default TKFileInput;

TKFileInput.defaultProps = {
  selectedFile: null,
  onChange: () => {},
  className: "",
  fileType: "image",
  customLabel: "",
}