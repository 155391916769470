// ALERTS
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

// PUBLIC
// HOMEPAGE ... CAMPS -> CAMPAIGNS
export const GET_TK_HP_CAMPS_REQUEST = "GET_TK_HP_CAMPS_REQUEST";
export const GET_TK_HP_CAMPS_SUCCEEDED = "GET_TK_HP_CAMPS_SUCCEEDED";
export const GET_TK_HP_CAMPS_FAILED = "GET_TK_HP_CAMPS_FAILED";
export const GET_TK_HP_NEWS_REQUEST = "GET_TK_HP_NEWS_REQUEST";
export const GET_TK_HP_NEWS_SUCCEEDED = "GET_TK_HP_NEWS_SUCCEEDED";
export const GET_TK_HP_NEWS_FAILED = "GET_TK_HP_NEWS_FAILED";

export const GET_TK_HP_BANNERS_REQUEST = "GET_TK_HP_BANNERS_REQUEST";
export const GET_TK_HP_BANNERS_SUCCEEDED = "GET_TK_HP_BANNERS_SUCCEEDED";
export const GET_TK_HP_BANNERS_FAILED = "GET_TK_HP_BANNERS_FAILED";

export const GET_TK_HP_STATUS_REQUEST = "GET_TK_HP_STATUS_REQUEST";
export const GET_TK_HP_STATUS_SUCCEEDED = "GET_TK_HP_STATUS_SUCCEEDED";
export const GET_TK_HP_STATUS_FAILED = "GET_TK_HP_STATUS_FAILED";

// NEWS
export const GET_TK_NEWS_REQUEST = "GET_TK_NEWS_REQUEST";
export const GET_TK_NEWS_SUCCEEDED = "GET_TK_NEWS_SUCCEEDED";
export const GET_TK_NEWS_FAILED = "GET_TK_NEWS_FAILED";
export const GET_TK_NEWS_BY_ID_REQUEST = "GET_TK_NEWS_BY_ID_REQUEST";
export const GET_TK_NEWS_BY_ID_SUCCEEDED = "GET_TK_NEWS_BY_ID_SUCCEEDED";
export const GET_TK_NEWS_BY_ID_FAILED = "GET_TK_NEWS_BY_ID_FAILED";
export const CLEAR_TK_NEWS = "CLEAR_TK_NEWS";

// COMPLAIN
export const GET_TK_COMPLAIN_REQUEST = "GET_TK_COMPLAIN_REQUEST";
export const GET_TK_COMPLAIN_SUCCEEDED = "GET_TK_COMPLAIN_SUCCEEDED";
export const GET_TK_COMPLAIN_FAILED = "GET_TK_COMPLAIN_FAILED";
export const GET_TK_COMPLAIN_BY_ID_REQUEST = "GET_TK_COMPLAIN_BY_ID_REQUEST";
export const GET_TK_COMPLAIN_BY_ID_SUCCEEDED =
  "GET_TK_COMPLAIN_BY_ID_SUCCEEDED";
export const GET_TK_COMPLAIN_BY_ID_FAILED = "GET_TK_COMPLAIN_BY_ID_FAILED";
export const CLEAR_TK_COMPLAIN = "CLEAR_TK_COMPLAIN";
export const SEEN_TK_COMPLAIN_REQUEST = "SEEN_TK_COMPLAIN_REQUEST";
export const SET_SHOW_COMPLAIN_VIEW_MODAL = "SET_SHOW_COMPLAIN_VIEW_MODAL";

//FAQ
export const GET_TK_FAQ_ADMIN_REQUEST = "GET_TK_FAQ_ADMIN_REQUEST";
export const GET_TK_FAQ_ADMIN_SUCCEEDED = "GET_TK_FAQ_ADMIN_SUCCEEDED";
export const GET_TK_FAQ_ADMIN_FAILED = "GET_TK_FAQ_ADMIN_FAILED";
export const SET_SHOW_FAQ_ADD_MODAL = "SET_SHOW_FAQ_ADD_MODAL";
export const SET_SHOW_FAQ_EDIT_MODAL = "SET_SHOW_FAQ_EDIT_MODAL";
export const SET_SHOW_FAQ_DELETE_MODAL = "SET_SHOW_FAQ_DELETE_MODAL";
export const CREATE_TK_ADMIN_FAQ_REQUEST = "CREATE_TK_ADMIN_FAQ_REQUEST";
export const EDIT_TK_ADMIN_FAQ_REQUEST = "EDIT_TK_ADMIN_FAQ_REQUEST";
export const DELETE_TK_ADMIN_FAQ_REQUEST = "DELETE_TK_ADMIN_FAQ_REQUEST";

//TKN
export const GET_TK_TKN_ADMIN_REQUEST = "GET_TK_TKN_ADMIN_REQUEST";
export const GET_TK_TKN_ADMIN_SUCCEEDED = "GET_TK_TKN_ADMIN_SUCCEEDED";
export const GET_TK_TKN_ADMIN_FAILED = "GET_TK_TKN_ADMIN_FAILED";
export const SET_SHOW_TKN_ADD_MODAL = "SET_SHOW_TKN_ADD_MODAL";
export const SET_SHOW_TKN_EDIT_MODAL = "SET_SHOW_TKN_EDIT_MODAL";
export const SET_SHOW_TKN_DELETE_MODAL = "SET_SHOW_TKN_DELETE_MODAL";
export const CREATE_TK_ADMIN_TKN_REQUEST = "CREATE_TK_ADMIN_TKN_REQUEST";
export const EDIT_TK_ADMIN_TKN_REQUEST = "EDIT_TK_ADMIN_TKN_REQUEST";
export const DELETE_TK_ADMIN_TKN_REQUEST = "DELETE_TK_ADMIN_TKN_REQUEST";

//GKN
export const GET_TK_GKN_ADMIN_REQUEST = "GET_TK_GKN_ADMIN_REQUEST";
export const GET_TK_GKN_ADMIN_SUCCEEDED = "GET_TK_GKN_ADMIN_SUCCEEDED";
export const GET_TK_GKN_ADMIN_FAILED = "GET_TK_GKN_ADMIN_FAILED";
export const SET_SHOW_GKN_ADD_MODAL = "SET_SHOW_GKN_ADD_MODAL";
export const SET_SHOW_GKN_EDIT_MODAL = "SET_SHOW_GKN_EDIT_MODAL";
export const SET_SHOW_GKN_DELETE_MODAL = "SET_SHOW_GKN_DELETE_MODAL";
export const CREATE_TK_ADMIN_GKN_REQUEST = "CREATE_TK_ADMIN_GKN_REQUEST";
export const EDIT_TK_ADMIN_GKN_REQUEST = "EDIT_TK_ADMIN_GKN_REQUEST";
export const DELETE_TK_ADMIN_GKN_REQUEST = "DELETE_TK_ADMIN_GKN_REQUEST";

// CAMPS -> CAMPAIGNS
export const GET_TK_CAMPS_REQUEST = "GET_TK_CAMPS_REQUEST";
export const GET_TK_CAMPS_SUCCEEDED = "GET_TK_CAMPS_SUCCEEDED";
export const GET_TK_CAMPS_FAILED = "GET_TK_CAMPS_FAILED";
export const GET_TK_TIMED_CAMPS_REQUEST = "GET_TK_TIMED_CAMPS_REQUEST";
export const GET_TK_TIMED_CAMPS_SUCCEEDED = "GET_TK_TIMED_CAMPS_SUCCEEDED";
export const GET_TK_TIMED_CAMPS_FAILED = "GET_TK_TIMED_CAMPS_FAILED";
export const GET_TK_CAMPS_BY_ID_REQUEST = "GET_TK_CAMPS_BY_ID_REQUEST";
export const GET_TK_CAMPS_BY_ID_SUCCEEDED = "GET_TK_CAMPS_BY_ID_SUCCEEDED";
export const GET_TK_CAMPS_BY_ID_FAILED = "GET_TK_CAMPS_BY_ID_FAILED";
export const CLEAR_TK_CAMPS = "CLEAR_TK_CAMPS";

// MWP -> MEMBER WORKPLACES
export const GET_TK_MEMBER_WORKPLACES_REQUEST =
  "GET_TK_MEMBER_WORKPLACES_REQUEST";
export const GET_TK_MEMBER_WORKPLACES_SUCCEEDED =
  "GET_TK_MEMBER_WORKPLACES_SUCCEEDED";
export const GET_TK_MEMBER_WORKPLACES_FAILED =
  "GET_TK_MEMBER_WORKPLACES_FAILED";
export const GET_TK_MWP_BY_ID_REQUEST = "GET_TK_MWP_BY_ID_REQUEST";
export const GET_TK_MWP_BY_ID_SUCCEEDED = "GET_TK_MWP_BY_ID_SUCCEEDED";
export const GET_TK_MWP_BY_ID_FAILED = "GET_TK_MWP_BY_ID_FAILED";
export const GET_TK_MWP_NAMES_REQUEST = "GET_TK_MWP_NAMES_REQUEST";
export const GET_TK_MWP_NAMES_SUCCEEDED = "GET_TK_MWP_NAMES_SUCCEEDED";
export const GET_TK_MWP_NAMES_FAILED = "GET_TK_MWP_NAMES_FAILED";
export const CLEAR_TK_MEMBER_WORKPLACES = "CLEAR_TK_MEMBER_WORKPLACES";
export const POST_TK_MWP_EXPORT_EXCEL_REQUEST =
  "POST_TK_MWP_EXPORT_EXCEL_REQUEST";
export const EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_REQUEST =
  "EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_REQUEST";

export const EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_REQUEST =
  "EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_REQUEST";

export const EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_SUCCEEDED =
  "EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_SUCCEEDED";
export const EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_FAILED =
  "EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_FAILED";

export const PREREGISTER_TK_MEMBER_WORKPLACES_REQUEST =
  "PREREGISTER_TK_MEMBER_WORKPLACES_REQUEST";
export const PREREGISTER_TK_MEMBER_WORKPLACES_SUCCEEDED =
  "PREREGISTER_TK_MEMBER_WORKPLACES_SUCCEEDED";
export const PREREGISTER_TK_MEMBER_WORKPLACES_FAILED =
  "PREREGISTER_TK_MEMBER_WORKPLACES_FAILED";

// MWP_CAMPS -> WORKPLACE CAMPAIGNS
export const GET_TK_MWP_CAMPS_REQUEST = "GET_TK_MWP_CAMPS_REQUEST";
export const GET_TK_MWP_CAMPS_SUCCEEDED = "GET_TK_MWP_CAMPS_SUCCEEDED";
export const GET_TK_MWP_CAMPS_FAILED = "GET_TK_MWP_CAMPS_FAILED";
export const CREATE_TK_MWP_CAMPS_REQUEST = "CREATE_TK_MWP_CAMPS_REQUEST";
export const SET_SHOW_MWP_CAMPS_ADD_MODAL = "SET_SHOW_MWP_CAMPS_ADD_MODAL";
export const EDIT_TK_MWP_CAMPS_REQUEST = "EDIT_TK_MWP_CAMPS_REQUEST";
export const SET_SHOW_MWP_CAMPS_EDIT_MODAL = "SET_SHOW_MWP_CAMPS_EDIT_MODAL";
export const DELETE_TK_MWP_CAMPS_REQUEST = "DELETE_TK_MWP_CAMPS_REQUEST";
export const SET_SHOW_MWP_CAMPS_DELETE_MODAL =
  "SET_SHOW_MWP_CAMPS_DELETE_MODAL";
export const PROCESS_TK_MWP_CAMPS_SUCCEEDED = "PROCESS_TK_MWP_CAMPS_SUCCEEDED";
export const PROCESS_TK_MWP_CAMPS_FAILED = "PROCESS_TK_MWP_CAMPS_FAILED";
export const CLEAR_TK_MWP_CAMPS = "CLEAR_TK_MWP_CAMPS";
export const POST_TK_CAMPS_EXPORT_EXCEL_REQUEST =
  "POST_TK_CAMPS_EXPORT_EXCEL_REQUEST";
export const GET_TK_CAMPS_PARTICIPANT_EXPORT_EXCEL_REQUEST =
  "GET_TK_CAMPS_PARTICIPANT_EXPORT_EXCEL_REQUEST";

// MWP_EMPLOYEES -> WORKPLACE EMPLOYEES
export const CREATE_TK_MWP_EMPLOYEES_REQUEST =
  "CREATE_TK_MWP_EMPLOYEES_REQUEST";
export const SET_SHOW_MWP_EMPLOYEES_ADD_MODAL =
  "SET_SHOW_MWP_EMPLOYEES_ADD_MODAL";
export const PROCESS_TK_MWP_EMPLOYEES_SUCCEEDED =
  "PROCESS_TK_MWP_EMPLOYEES_SUCCEEDED";
export const PROCESS_TK_MWP_EMPLOYEES_FAILED =
  "PROCESS_TK_MWP_EMPLOYEES_FAILED";
export const DELETE_TK_MWP_EMPLOYEES_REQUEST =
  "DELETE_TK_MWP_EMPLOYEES_REQUEST";
export const SET_SHOW_MWP_EMPLOYEES_DELETE_MODAL =
  "SET_SHOW_MWP_EMPLOYEES_DELETE_MODAL";
export const GET_TK_MWP_EMPLOYEES_REQUEST = "GET_TK_MWP_EMPLOYEES_REQUEST";
export const GET_TK_MWP_EMPLOYEES_SUCCEEDED = "GET_TK_MWP_EMPLOYEES_SUCCEEDED";
export const GET_TK_MWP_EMPLOYEES_FAILED = "GET_TK_MWP_EMPLOYEES_FAILED";

// CATEGORIES
export const GET_TK_CATEGORIES_REQUEST = "GET_TK_CATEGORIES_REQUEST";
export const GET_TK_CATEGORIES_SUCCEEDED = "GET_TK_CATEGORIES_SUCCEEDED";
export const GET_TK_CATEGORIES_FAILED = "GET_TK_CATEGORIES_FAILED";

// LOGIN
export const TK_LOGIN_REQUEST = "TK_LOGIN_REQUEST";
export const TK_LOGIN_SUCCEEDED = "TK_LOGIN_SUCCEEDED";
export const TK_LOGIN_FAILED = "TK_LOGIN_FAILED";
export const TK_LOGOUT = "TK_LOGOUT";
export const TK_USER_INFO_REQUEST = "TK_USER_INFO_REQUEST";
export const TK_USER_INFO_SUCCEEDED = "TK_USER_INFO_SUCCEEDED";
export const TK_USER_INFO_FAILED = "TK_USER_INFO_FAILED";

export const TK_REGISTER_REQUEST = "TK_REGISTER_REQUEST";
export const TK_REGISTER_SUCCEEDED = "TK_REGISTER_SUCCEEDED";
export const TK_REGISTER_FAILED = "TK_REGISTER_FAILED";

export const TK_UPDATE_LINKS = "TK_UPDATE_LINKS";

export const TK_VALIDATE_REQUEST = "TK_VALIDATE_REQUEST";
export const TK_VALIDATE_SUCCEEDED = "TK_VALIDATE_SUCCEEDED";
export const TK_VALIDATE_FAILED = "TK_VALIDATE_FAILED";
export const TK_VALIDATE_TIMEOUT = "TK_VALIDATE_TIMEOUT";
export const TK_VALIDATE_TIMEOUT_SUCCEEDED = "TK_VALIDATE_TIMEOUT_SUCCEEDED";
export const TK_VALIDATE_TIMEOUT_FAILED = "TK_VALIDATE_TIMEOUT_FAILED";

//FORGOTPASSWORD
export const TK_FORGOTPASSWORD_REQUEST = "TK_FORGOTPASSWORD_REQUEST";
export const TK_FORGOTPASSWORD_SUCCEEDED = "TK_FORGOTPASSWORD_SUCCEEDED";
export const TK_FORGOTPASSWORD_FAILED = "TK_FORGOTPASSWORD_FAILED";

//FORGOTPASSWORDCHS
export const TK_CHS_FORGOTPASSWORD_REQUEST = "TK_CHS_FORGOTPASSWORD_REQUEST";
export const TK_CHS_FORGOTPASSWORD_SUCCEEDED =
  "TK_CHS_FORGOTPASSWORD_SUCCEEDED";
export const TK_CHS_FORGOTPASSWORD_FAILED = "TK_CHS_FORGOTPASSWORD_FAILED";

//FORGOTPASSWORDMWP
export const TK_MWP_FORGOTPASSWORD_REQUEST = "TK_MWP_FORGOTPASSWORD_REQUEST";
export const TK_MWP_FORGOTPASSWORD_SUCCEEDED =
  "TK_MWP_FORGOTPASSWORD_SUCCEEDED";
export const TK_MWP_FORGOTPASSWORD_FAILED = "TK_MWP_FORGOTPASSWORD_FAILED";

//NEWPASSWORDMWP
export const TK_MWP_NEWPASSWORD_REQUEST = "TK_MWP_NEWPASSWORD_REQUEST";
export const TK_MWP_NEWPASSWORD_SUCCEEDED = "TK_MWP_NEWPASSWORD_SUCCEEDED";
export const TK_MWP_NEWPASSWORD_FAILED = "TK_MWP_NEWPASSWORD_FAILED";

//NEWPASSWORDCHS
export const TK_CHS_NEWPASSWORD_REQUEST = "TK_CHS_NEWPASSWORD_REQUEST";
export const TK_CHS_NEWPASSWORD_SUCCEEDED = "TK_CHS_NEWPASSWORD_SUCCEEDED";
export const TK_CHS_NEWPASSWORD_FAILED = "TK_CHS_NEWPASSWORD_FAILED";

// USER TRANSACTIONS
export const GET_TK_TRANSACTIONS_REQUEST = "GET_TK_TRANSACTIONS_REQUEST";
export const GET_TK_TRANSACTIONS_SUCCEEDED = "GET_TK_TRANSACTIONS_SUCCEEDED";
export const GET_TK_TRANSACTIONS_FAILED = "GET_TK_TRANSACTIONS_FAILED";

// ALL TRANSACTIONS
export const GET_TK_ALL_TRANSACTIONS_REQUEST =
  "GET_TK_ALL_TRANSACTIONS_REQUEST";
export const GET_TK_ALL_TRANSACTIONS_SUCCEEDED =
  "GET_TK_ALL_TRANSACTIONS_SUCCEEDED";
export const GET_TK_ALL_TRANSACTIONS_FAILED = "GET_TK_ALL_TRANSACTIONS_FAILED";
export const POST_TK_ALL_TRANSACTIONS_EXPORT_EXCEL_REQUEST =
  "POST_TK_ALL_TRANSACTIONS_EXPORT_EXCEL_REQUEST";

// ADMIN
// CARD HOLDERS
export const GET_TK_ADMIN_CHS_REQUEST = "GET_TK_ADMIN_CHS_REQUEST";
export const GET_TK_ADMIN_CHS_SUCCEEDED = "GET_TK_ADMIN_CHS_SUCCEEDED";
export const GET_TK_ADMIN_CHS_FAILED = "GET_TK_ADMIN_CHS_FAILED";
export const CREATE_TK_ADMIN_CHS_REQUEST = "CREATE_TK_ADMIN_CHS_REQUEST";
export const SET_SHOW_CHS_ADD_MODAL = "SET_SHOW_CHS_ADD_MODAL";
export const EDIT_TK_ADMIN_CHS_REQUEST = "EDIT_TK_ADMIN_CHS_REQUEST";
export const SET_SHOW_CHS_EDIT_MODAL = "SET_SHOW_CHS_EDIT_MODAL";
export const DELETE_TK_ADMIN_CHS_REQUEST = "DELETE_TK_ADMIN_CHS_REQUEST";
export const SET_SHOW_CHS_DELETE_MODAL = "SET_SHOW_CHS_DELETE_MODAL";
export const PROCESS_TK_ADMIN_CHS_SUCCEEDED = "PROCESS_TK_ADMIN_CHS_SUCCEEDED";
export const PROCESS_TK_ADMIN_CHS_FAILED = "PROCESS_TK_ADMIN_CHS_FAILED";
export const SET_TK_ADMIN_CARD_LIST = "SET_TK_ADMIN_CARD_LIST";
export const CREATE_TK_ADMIN_CARD_REQUEST = "CREATE_TK_ADMIN_CARD_REQUEST";
export const SET_SHOW_CARD_ADD_MODAL = "SET_SHOW_CARD_ADD_MODAL";
export const EDIT_TK_ADMIN_CARD_REQUEST = "EDIT_TK_ADMIN_CARD_REQUEST";
export const UPDATE_TK_ADMIN_ALL_CARDS_REQUEST =
  "UPDATE_TK_ADMIN_ALL_CARDS_REQUEST";
export const SET_SHOW_CARD_EDIT_MODAL = "SET_SHOW_CARD_EDIT_MODAL";
export const DELETE_TK_ADMIN_CARD_REQUEST = "DELETE_TK_ADMIN_CARD_REQUEST";
export const SET_SHOW_CARD_DELETE_MODAL = "SET_SHOW_CARD_DELETE_MODAL";
export const PROCESS_TK_ADMIN_CARD_SUCCEEDED =
  "PROCESS_TK_ADMIN_CARD_SUCCEEDED";
export const PROCESS_TK_ADMIN_CARD_FAILED = "PROCESS_TK_ADMIN_CARD_FAILED";
export const IS_CARD_NUMBER_ALREADY_USED_REQUEST =
  "IS_CARD_NUMBER_ALREADY_USED_REQUEST";
export const IS_CARD_NUMBER_ALREADY_USED_RESPONSE =
  "IS_CARD_NUMBER_ALREADY_USED_RESPONSE";
export const IS_CARD_NUMBER_ALREADY_USED_FAILED =
  "IS_CARD_NUMBER_ALREADY_USED_FAILED";
export const POST_TK_CHS_EXPORT_EXCEL_REQUEST =
  "POST_TK_CHS_EXPORT_EXCEL_REQUEST";
export const IS_TCKN_ALREADY_USED_REQUEST = "IS_TCKN_ALREADY_USED_REQUEST";
export const IS_TCKN_ALREADY_USED_RESPONSE = "IS_TCKN_ALREADY_USED_RESPONSE";
export const IS_TCKN_ALREADY_USED_FAILED = "IS_TCKN_ALREADY_USED_FAILED";

export const SET_SHOW_LOAD_MONEY_MODAL = "SET_SHOW_LOAD_MONEY_MODAL";
export const LOAD_MONEY_TK_ADMIN_CHS_REQUEST =
  "LOAD_MONEY_TK_ADMIN_CHS_REQUEST";
export const SET_SHOW_CHS_BALANCE_MODAL = "SET_SHOW_CHS_BALANCE_MODAL";

// BANNER
export const GET_TK_ADMIN_BANNERS_REQUEST = "GET_TK_ADMIN_BANNERS_REQUEST";
export const GET_TK_ADMIN_BANNERS_SUCCEEDED = "GET_TK_ADMIN_BANNERS_SUCCEEDED";
export const GET_TK_ADMIN_BANNERS_FAILED = "GET_TK_ADMIN_BANNERS_FAILED";
export const GET_TK_ADMIN_BANNERS_CONTENT_REQUEST =
  "GET_TK_ADMIN_BANNERS_CONTENT_REQUEST";
export const GET_TK_ADMIN_BANNERS_CONTENT_REQUEST_SUCCEDED =
  "GET_TK_ADMIN_BANNERS_CONTENT_REQUEST_SUCCEDED";
export const GET_TK_ADMIN_BANNERS_CONTENT_REQUEST_FAILED =
  "GET_TK_ADMIN_BANNERS_CONTENT_REQUEST_FAILED";
export const SET_SHOW_BANNERS_ADD_MODAL = "SET_SHOW_BANNERS_ADD_MODAL";
export const CREATE_TK_ADMIN_BANNERS_REQUEST =
  "CREATE_TK_ADMIN_BANNERS_REQUEST";
export const PROCESS_TK_ADMIN_BANNERS_SUCCEEDED =
  "PROCESS_TK_ADMIN_BANNERS_SUCCEEDED";
export const PROCESS_TK_ADMIN_BANNERS_FAILED =
  "PROCESS_TK_ADMIN_BANNERS_FAILED";
export const DELETE_TK_ADMIN_BANNERS_REQUEST =
  "DELETE_TK_ADMIN_BANNERS_REQUEST";
export const SET_SHOW_BANNERS_DELETE_MODAL = "SET_SHOW_BANNERS_DELETE_MODAL";
export const EDIT_TK_ADMIN_BANNERS_REQUEST = "EDIT_TK_ADMIN_BANNERS_REQUEST";
export const SET_SHOW_BANNERS_EDIT_MODAL = "SET_SHOW_BANNERS_EDIT_MODAL";

// NEWS
export const GET_TK_ADMIN_NEWS_REQUEST = "GET_TK_ADMIN_NEWS_REQUEST";
export const GET_TK_ADMIN_NEWS_SUCCEEDED = "GET_TK_ADMIN_NEWS_SUCCEEDED";
export const GET_TK_ADMIN_NEWS_FAILED = "GET_TK_ADMIN_NEWS_FAILED";
export const CREATE_TK_ADMIN_NEWS_REQUEST = "CREATE_TK_ADMIN_NEWS_REQUEST";
export const SET_SHOW_NEWS_ADD_MODAL = "SET_SHOW_NEWS_ADD_MODAL";
export const EDIT_TK_ADMIN_NEWS_REQUEST = "EDIT_TK_ADMIN_NEWS_REQUEST";
export const SET_SHOW_NEWS_EDIT_MODAL = "SET_SHOW_NEWS_EDIT_MODAL";
export const DELETE_TK_ADMIN_NEWS_REQUEST = "DELETE_TK_ADMIN_NEWS_REQUEST";
export const SET_SHOW_NEWS_DELETE_MODAL = "SET_SHOW_NEWS_DELETE_MODAL";
export const PROCESS_TK_ADMIN_NEWS_SUCCEEDED =
  "PROCESS_TK_ADMIN_NEWS_SUCCEEDED";
export const PROCESS_TK_ADMIN_NEWS_FAILED = "PROCESS_TK_ADMIN_NEWS_FAILED";
export const POST_TK_NEWS_EXPORT_EXCEL_REQUEST =
  "POST_TK_NEWS_EXPORT_EXCEL_REQUEST";

// CAMPS -> CAMPAIGNS
export const GET_TK_ADMIN_CAMPS_REQUEST = "GET_TK_ADMIN_CAMPS_REQUEST";
export const GET_TK_ADMIN_CAMPS_SUCCEEDED = "GET_TK_ADMIN_CAMPS_SUCCEEDED";
export const GET_TK_ADMIN_CAMPS_FAILED = "GET_TK_ADMIN_CAMPS_FAILED";
export const CREATE_TK_ADMIN_CAMPS_REQUEST = "CREATE_TK_ADMIN_CAMPS_REQUEST";
export const SET_SHOW_CAMPS_ADD_MODAL = "SET_SHOW_CAMPS_ADD_MODAL";
export const EDIT_TK_ADMIN_CAMPS_REQUEST = "EDIT_TK_ADMIN_CAMPS_REQUEST";
export const SET_SHOW_CAMPS_EDIT_MODAL = "SET_SHOW_CAMPS_EDIT_MODAL";
export const DELETE_TK_ADMIN_CAMPS_REQUEST = "DELETE_TK_ADMIN_CAMPS_REQUEST";
export const SET_SHOW_CAMPS_DELETE_MODAL = "SET_SHOW_CAMPS_DELETE_MODAL";
export const PROCESS_TK_ADMIN_CAMPS_SUCCEEDED =
  "PROCESS_TK_ADMIN_CAMPS_SUCCEEDED";
export const PROCESS_TK_ADMIN_CAMPS_FAILED = "PROCESS_TK_ADMIN_CAMPS_FAILED";

// MWP -> MEMBER WORKPLACES
export const GET_TK_ADMIN_MWP_REQUEST = "GET_TK_ADMIN_MWP_REQUEST";
export const GET_TK_ADMIN_MWP_SUCCEEDED = "GET_TK_ADMIN_MWP_SUCCEEDED";
export const GET_TK_ADMIN_MWP_FAILED = "GET_TK_ADMIN_MWP_FAILED";
export const CREATE_TK_ADMIN_MWP_REQUEST = "CREATE_TK_ADMIN_MWP_REQUEST";
export const SET_SHOW_MWP_ADD_MODAL = "SET_SHOW_MWP_ADD_MODAL";
export const EDIT_TK_ADMIN_MWP_REQUEST = "EDIT_TK_ADMIN_MWP_REQUEST";
export const SET_SHOW_MWP_EDIT_MODAL = "SET_SHOW_MWP_EDIT_MODAL";
export const DELETE_TK_ADMIN_MWP_REQUEST = "DELETE_TK_ADMIN_MWP_REQUEST";
export const SET_SHOW_MWP_DELETE_MODAL = "SET_SHOW_MWP_DELETE_MODAL";
export const SET_SHOW_MWP_TRANS_MODAL = "SET_SHOW_MWP_TRANS_MODAL";
export const PROCESS_TK_ADMIN_MWP_SUCCEEDED = "PROCESS_TK_ADMIN_MWP_SUCCEEDED";
export const PROCESS_TK_ADMIN_MWP_FAILED = "PROCESS_TK_ADMIN_MWP_FAILED";

// CATEGORIES
export const CREATE_TK_ADMIN_CATEGORIES_REQUEST =
  "CREATE_TK_ADMIN_CATEGORIES_REQUEST";
export const SET_SHOW_CATEGORIES_ADD_MODAL = "SET_SHOW_CATEGORIES_ADD_MODAL";
export const PROCESS_TK_ADMIN_CATEGORIES_SUCCEEDED =
  "PROCESS_TK_ADMIN_CATEGORIES_SUCCEEDED";
export const PROCESS_TK_ADMIN_CATEGORIES_FAILED =
  "PROCESS_TK_ADMIN_CATEGORIES_FAILED";
export const DELETE_TK_ADMIN_CATEGORIES_REQUEST =
  "DELETE_TK_ADMIN_CATEGORIES_REQUEST";
export const SET_SHOW_CATEGORIES_DELETE_MODAL =
  "SET_SHOW_CATEGORIES_DELETE_MODAL";

// ORGANIZATIONS
export const CREATE_TK_ADMIN_ORGANIZATIONS_REQUEST =
  "CREATE_TK_ADMIN_ORGANIZATIONS_REQUEST";
export const SET_SHOW_ORGANIZATIONS_ADD_MODAL =
  "SET_SHOW_ORGANIZATIONS_ADD_MODAL";
export const PROCESS_TK_ADMIN_ORGANIZATIONS_SUCCEEDED =
  "PROCESS_TK_ADMIN_ORGANIZATIONS_SUCCEEDED";
export const PROCESS_TK_ADMIN_ORGANIZATIONS_FAILED =
  "PROCESS_TK_ADMIN_ORGANIZATIONS_FAILED";
export const DELETE_TK_ADMIN_ORGANIZATIONS_REQUEST =
  "DELETE_TK_ADMIN_ORGANIZATIONS_REQUEST";
export const SET_SHOW_ORGANIZATIONS_DELETE_MODAL =
  "SET_SHOW_ORGANIZATIONS_DELETE_MODAL";
export const GET_TK_ORGANIZATIONS_REQUEST = "GET_TK_ORGANIZATIONS_REQUEST";
export const GET_TK_ORGANIZATIONS_SUCCEEDED = "GET_TK_ORGANIZATIONS_SUCCEEDED";
export const GET_TK_ORGANIZATIONS_FAILED = "GET_TK_ORGANIZATIONS_FAILED";
export const SEARCH_TK_ORGANIZATIONS_REQUEST =
  "SEARCH_TK_ORGANIZATIONS_REQUEST";
export const SEARCH_TK_ORGANIZATIONS_SUCCEEDED =
  "SEARCH_TK_ORGANIZATIONS_SUCCEEDED";
export const SEARCH_TK_ORGANIZATIONS_FAILED = "SEARCH_TK_ORGANIZATIONS_FAILED";

//PROFESSIONS
export const GET_TK_ADMIN_PROFESSIONS_REQUEST =
  "GET_TK_ADMIN_PROFESSIONS_REQUEST";
export const GET_TK_ADMIN_PROFESSIONS_SUCCEEDED =
  "TK_ADMIN_PROFESSIONS_SUCCEEDED";
export const GET_TK_ADMIN_PROFESSIONS_FAILED =
  "GET_TK_ADMIN_PROFESSIONS_FAILED";
export const GET_TK_PROFESSIONS_REQUEST = "GET_TK_PROFESSIONS_REQUEST";
export const GET_TK_PROFESSIONS_SUCCEEDED = "TK_PROFESSIONS_SUCCEEDED";
export const GET_TK_PROFESSIONS_FAILED = "GET_TK_PROFESSIONS_FAILED";

export const CREATE_TK_ADMIN_PROFESSIONS_REQUEST =
  "CREATE_TK_ADMIN_PROFESSIONS_REQUEST";
export const SET_SHOW_PROFESSIONS_ADD_MODAL = "SET_SHOW_PROFESSIONS_ADD_MODAL";
export const PROCESS_TK_ADMIN_PROFESSIONS_SUCCEEDED =
  "PROCESS_TK_ADMIN_PROFESSIONS_SUCCEEDED";
export const PROCESS_TK_ADMIN_PROFESSIONS_FAILED =
  "PROCESS_TK_ADMIN_PROFESSIONS_FAILED";
export const DELETE_TK_ADMIN_PROFESSIONS_REQUEST =
  "DELETE_TK_ADMIN_PROFESSIONS_REQUEST";
export const SET_SHOW_PROFESSIONS_DELETE_MODAL =
  "SET_SHOW_PROFESSIONS_DELETE_MODAL";
export const SEARCH_TK_PROFESSIONS_REQUEST = "SEARCH_TK_PROFESSIONS_REQUEST";
export const SEARCH_TK_PROFESSIONS_SUCCEEDED =
  "SEARCH_TK_PROFESSIONS_SUCCEEDED";
export const SEARCH_TK_PROFESSIONS_FAILED = "SEARCH_TK_PROFESSIONS_FAILED";

// CARD TYPES
export const CREATE_TK_ADMIN_CARDTYPES_REQUEST =
  "CREATE_TK_ADMIN_CARDTYPES_REQUEST";
export const SET_SHOW_CARDTYPES_ADD_MODAL = "SET_SHOW_CARDTYPES_ADD_MODAL";
export const PROCESS_TK_ADMIN_CARDTYPES_SUCCEEDED =
  "PROCESS_TK_ADMIN_CARDTYPES_SUCCEEDED";
export const PROCESS_TK_ADMIN_CARDTYPES_FAILED =
  "PROCESS_TK_ADMIN_CARDTYPES_FAILED";
export const DELETE_TK_ADMIN_CARDTYPES_REQUEST =
  "DELETE_TK_ADMIN_CARDTYPES_REQUEST";
export const SET_SHOW_CARDTYPES_DELETE_MODAL =
  "SET_SHOW_CARDTYPES_DELETE_MODAL";
export const GET_TK_CARDTYPES_REQUEST = "GET_TK_CARDTYPES_REQUEST";
export const GET_TK_CARDTYPES_REQUEST_WITH_ALL_OPTION =
  "GET_TK_CARDTYPES_REQUEST_WITH_ALL_OPTION";
export const GET_TK_CARDTYPES_SUCCEEDED = "GET_TK_CARDTYPES_SUCCEEDED";
export const GET_TK_CARDTYPES_FAILED = "GET_TK_CARDTYPES_FAILED";
export const SEARCH_TK_CARDTYPES_REQUEST = "SEARCH_TK_CARDTYPES_REQUEST";
export const SEARCH_TK_CARDTYPES_SUCCEEDED = "SEARCH_TK_CARDTYPES_SUCCEEDED";
export const SEARCH_TK_CARDTYPES_FAILED = "SEARCH_TK_CARDTYPES_FAILED";

// AU -> ADMIN USERS
export const GET_TK_ADMIN_AU_REQUEST = "GET_TK_ADMIN_AU_REQUEST";
export const GET_TK_ADMIN_AU_SUCCEEDED = "GET_TK_ADMIN_AU_SUCCEEDED";
export const GET_TK_ADMIN_AU_FAILED = "GET_TK_ADMIN_AU_FAILED";
export const CREATE_TK_ADMIN_AU_REQUEST = "CREATE_TK_ADMIN_AU_REQUEST";
export const SET_SHOW_AU_ADD_MODAL = "SET_SHOW_AU_ADD_MODAL";
export const EDIT_TK_ADMIN_AU_REQUEST = "EDIT_TK_ADMIN_AU_REQUEST";
export const SET_SHOW_AU_EDIT_MODAL = "SET_SHOW_AU_EDIT_MODAL";
export const DELETE_TK_ADMIN_AU_REQUEST = "DELETE_TK_ADMIN_AU_REQUEST";
export const SET_SHOW_AU_DELETE_MODAL = "SET_SHOW_AU_DELETE_MODAL";
export const PROCESS_TK_ADMIN_AU_SUCCEEDED = "PROCESS_TK_ADMIN_AU_SUCCEEDED";
export const PROCESS_TK_ADMIN_AU_FAILED = "PROCESS_TK_ADMIN_AU_FAILED";

//DASHBOARD

export const GET_TK_ADMIN_DASHBOARD_REQUEST = "GET_TK_ADMIN_DASHBOARD_REQUEST";
export const GET_TK_ADMIN_DASHBOARD_SUCCEEDED =
  "GET_TK_ADMIN_DASHBOARD_SUCCEEDED";
export const GET_TK_ADMIN_DASHBOARD_FAILED = "GET_TK_ADMIN_DASHBOARD_FAILED";

//REPORTS

export const GET_TK_ADMIN_REPORTS_TKART_REQUEST = "GET_TK_ADMIN_REPORTS_TKART_REQUEST";
export const GET_TK_ADMIN_REPORTS_TKART_SUCCEEDED =
  "GET_TK_ADMIN_REPORTS_TKART_SUCCEEDED";
export const GET_TK_ADMIN_REPORTS_TKART_FAILED = "GET_TK_ADMIN_REPORTS_TKART_FAILED";

export const GET_TK_ADMIN_REPORTS_GENCKART_REQUEST = "GET_TK_ADMIN_REPORTS_GENCKART_REQUEST";
export const GET_TK_ADMIN_REPORTS_GENCKART_SUCCEEDED =
  "GET_TK_ADMIN_REPORTS_GENCKART_SUCCEEDED";
export const GET_TK_ADMIN_REPORTS_GENCKART_FAILED = "GET_TK_ADMIN_REPORTS_GENCKART_FAILED";

export const GET_TK_ADMIN_REPORTS_GAZIKART_REQUEST = "GET_TK_ADMIN_REPORTS_GAZIKART_REQUEST";
export const GET_TK_ADMIN_REPORTS_GAZIKART_SUCCEEDED =
  "GET_TK_ADMIN_REPORTS_GAZIKART_SUCCEEDED";
export const GET_TK_ADMIN_REPORTS_GAZIKART_FAILED = "GET_TK_ADMIN_REPORTS_GAZIKART_FAILED";

export const GET_TK_ADMIN_REPORTS_ACTIVEUSERS_REQUEST = "GET_TK_ADMIN_REPORTS_ACTIVEUSERS_REQUEST";
export const GET_TK_ADMIN_REPORTS_ACTIVECOMPANIES_REQUEST = "GET_TK_ADMIN_REPORTS_ACTIVECOMPANIES_REQUEST";
export const GET_TK_ADMIN_REPORTS_MOSTCAMPAIGNS_REQUEST = "GET_TK_ADMIN_REPORTS_MOSTCAMPAIGNS_REQUEST";


// APU -> API USERS
export const GET_TK_ADMIN_APU_REQUEST = "GET_TK_ADMIN_APU_REQUEST";
export const GET_TK_ADMIN_APU_SUCCEEDED = "GET_TK_ADMIN_APU_SUCCEEDED";
export const GET_TK_ADMIN_APU_FAILED = "GET_TK_ADMIN_APU_FAILED";
export const CREATE_TK_ADMIN_APU_REQUEST_TOKEN =
  "CREATE_TK_ADMIN_APU_REQUEST_TOKEN";
export const CREATE_TK_ADMIN_APU_REQUEST_TOKEN_SUCCEED =
  "CREATE_TK_ADMIN_APU_REQUEST_TOKEN_SUCCEED";
export const CREATE_TK_ADMIN_APU_REQUEST = "CREATE_TK_ADMIN_APU_REQUEST";
export const SET_SHOW_APU_ADD_MODAL = "SET_SHOW_APU_ADD_MODAL";
export const EDIT_TK_ADMIN_APU_REQUEST = "EDIT_TK_ADMIN_APU_REQUEST";
export const SET_SHOW_APU_KEY_MODAL = "SET_SHOW_APU_KEY_MODAL";
export const DELETE_TK_ADMIN_APU_REQUEST = "DELETE_TK_ADMIN_APU_REQUEST";
export const SET_SHOW_APU_DELETE_MODAL = "SET_SHOW_APU_DELETE_MODAL";
export const PROCESS_TK_ADMIN_APU_SUCCEEDED = "PROCESS_TK_ADMIN_APU_SUCCEEDED";
export const PROCESS_TK_ADMIN_APU_FAILED = "PROCESS_TK_ADMIN_APU_FAILED";

// LINKS
export const GET_TK_ADMIN_LINKS_REQUEST = "GET_TK_ADMIN_LINKS_REQUEST";
export const GET_TK_ADMIN_LINKS_SUCCEEDED = "GET_TK_ADMIN_LINKS_SUCCEEDED";
export const GET_TK_ADMIN_LINKS_FAILED = "GET_TK_ADMIN_LINKS_FAILED";

export const CREATE_TK_ADMIN_LINKS_REQUEST = "CREATE_TK_ADMIN_LINKS_REQUEST";
export const PROCESS_TK_ADMIN_LINKS_SUCCEEDED =
  "PROCESS_TK_ADMIN_LINKS_SUCCEEDED";
export const PROCESS_TK_ADMIN_LINKS_FAILED = "PROCESS_TK_ADMIN_LINKS_FAILED";
export const DELETE_TK_ADMIN_LINKS_REQUEST = "DELETE_TK_ADMIN_LINKS_REQUEST";
export const EDIT_TK_ADMIN_LINKS_REQUEST = "EDIT_TK_ADMIN_LINKS_REQUEST";
