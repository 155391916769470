import * as types from '../actionTypes';

export const getTKGKNAdminRequest = reqParams => ({
    type: types.GET_TK_GKN_ADMIN_REQUEST,
    payload: reqParams
  });

  export const getTKGKNAdminSucceeded = ({data}) => ({
    type: types.GET_TK_GKN_ADMIN_SUCCEEDED,
    payload: {
      data
    }
  });
  
  export const getTKGKNAdminFailed = () => ({
    type: types.GET_TK_GKN_ADMIN_FAILED
  });

  export const setShowGKNAddModal = show => ({
    type: types.SET_SHOW_GKN_ADD_MODAL,
    payload: show
  });

  export const setShowGKNEditModal = show => ({
    type: types.SET_SHOW_GKN_EDIT_MODAL,
    payload: show
  });

  export const setShowGKNDeleteModal = show => ({
    type: types.SET_SHOW_GKN_DELETE_MODAL,
    payload: show
  });


  export const createTKAdminGKNRequest = reqParams => ({
    type: types.CREATE_TK_ADMIN_GKN_REQUEST,
    payload: reqParams
  
  })

  export const editTKAdminGKNRequest = reqParams => ({
    type: types.EDIT_TK_ADMIN_GKN_REQUEST,
    payload: reqParams
  
  })
  
  export const deleteTKAdminGKNRequest = reqParams => ({
    type: types.DELETE_TK_ADMIN_GKN_REQUEST,
    payload: reqParams
  
  })