import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/auth";
import {
  TK_LOGIN_REQUEST,
  TK_USER_INFO_REQUEST,
  TK_REGISTER_REQUEST,
  TK_FORGOTPASSWORD_REQUEST,
  TK_VALIDATE_REQUEST,
  TK_VALIDATE_TIMEOUT,
} from "../actionTypes";
import { customSwal } from "../../helpers/customSwal";
import { linkOrder } from "../../constants/general";
import moment from "moment";

function* watchLogin() {
  yield takeLatest(TK_LOGIN_REQUEST, login);
}

function* watchForgotPassword() {
  yield takeLatest(TK_FORGOTPASSWORD_REQUEST, forgotpassword);
}

function* forgotpassword(action) {
  try {
    const { email } = action.payload;

    const res = yield call(apiCall, "post", "forgotpassword", {
      email,
      client_type: "web",
    });
    yield call(
      customSwal,
      "Başarılı",
      "Şifreniz emailinize gönderildi.",
      "success"
    );
  } catch (err) {
    yield put(actions.tkForgotPasswordFailed());
    yield call(customSwal, "Hata", "E-posta gönderilemedi", "error");
  }
}

function* login(action) {
  try {
    const { history, email, tckn, password, vkn, loginType, navigate } =
      action.payload;

    const body = { password, client_type: "web" };
    if (email) {
      body.email = email;
    } else if (loginType === "INDIVIDUAL") {
      body.tckn = tckn;
    } else {
      body.vkn = vkn;
    }

    const res = yield call(apiCall, "post", "token", body);

    yield localStorage.setItem("user", JSON.stringify(res.data));
    yield localStorage.setItem("token", res.data.access_token);
    yield localStorage.setItem("userRole", res.data.role);
    yield localStorage.setItem("expires_in", res.data.expires_in);

    yield put(actions.tkGetUserInfo({ ...res.data }));

    yield put(actions.tkLoginSucceeded({ ...res.data }));

    if (history) {
      for (var i = 0; i < linkOrder.length; i++) {
        if (res.data.links.includes(linkOrder[i])) {
          yield history.replace(`/admin/${linkOrder[i]}`);
          break;
        }
      }
    }
    if (navigate) {
      yield navigate.push("/hesabim");
    }
  } catch (err) {
    console.log(err);
    yield put(actions.tkLoginFailed());
    if (err.response.data.errorCode == 401) {
      yield call(
        customSwal,
        "Hata",
        "Sisteme kayıtlı kullanıcı bulunamadı",
        "error"
      );
    } else if (err.response.data.errorCode == 403) {
      yield call(
        customSwal,
        "Uyarı",
        "Başvurunuz onayda beklemektedir",
        "info"
      );
    } else {
      if (err.response.data.errorCode == 410) {
        yield call(
          customSwal,
          "Uyarı",
          "Üye İş yeriniz Pasife alınmıştır. Detaylı bilgi ve sorularınız için 444 09 06 telefon numarasını arayabilirsiniz.",
          "info"
        );
      } else if (err.response.data.errorCode == 422) {
        yield call(
          customSwal,
          "Uyarı",
          "Girdiğiniz bilgiler hatalıdır. Şifrenizi bilmiyorsanız 'Şifremi Unuttum' seçeneği ile şifrenizi yenileyebilirsiniz",
          "info"
        );
      } else if (err.response.data.errorCode == 455) {
        yield call(
          customSwal,
          "Uyarı",
          "Sayın Firma Yetkilisi, süreci devam eden başvurunuz bulunduğu için işleminiz gerçekleştirilememektedir. Başvuru sonucunuz SMS ile tarafınıza bildirilecektir. İyi günler dileriz. Detaylı bilgi için; 444 09 06",
          "info"
        );
      } else if (action.payload.loginType === "INDIVIDUAL") {
        yield call(
          customSwal,
          "Uyarı",
          "Giriş yapabilmek için kayıt olmalısınız.",
          "info"
        );
      } else {
        if (action.payload.email) {
          yield call(
            customSwal,
            "Uyarı",
            "Yanlış E-posta veya Parola",
            "error"
          );
        } else {
          yield call(
            customSwal,
            "Hata",
            "Yanlış Vergi kimlik Numarası veya Parola",
            "error"
          );
        }
      }
    }
  }
}

function* watchUserInfo() {
  yield takeLatest(TK_USER_INFO_REQUEST, getUserInfo);
}

function* getUserInfo(action) {
  try {
    const userRes = yield call(apiCall, "get", "api/survey/users");

    yield localStorage.setItem("userInfo", JSON.stringify(userRes.data.data));
    yield put(actions.tkUserInfoSucceeded({ ...userRes.data.data }));
  } catch (err) {
    console.log(err);
    yield put(actions.tkUserInfoFailed());
  }
}

function* watchRegister() {
  yield takeLatest(TK_REGISTER_REQUEST, registerRequest);
}

function* registerRequest(action) {
  try {
    const { data, handleCheckSignupModal, handleValidationModal } =
      action.payload;
    const { telephone_number, name, surname, tckn, birthdate } = data;

    let today = moment();

    if (today.diff(moment(birthdate), "years", true) >= 14) {
      yield call(apiCall, "post", "token", {
        ...data,
        birthdate: moment(birthdate).utcOffset(0, true).toISOString(),
        status: "WFA",
      });

      yield put(
        actions.tkRegisterSucceeded({
          data: {
            telephone_number,
            tckn,
            name,
            surname,
          },
        })
      );

      yield call(handleCheckSignupModal, false);
      yield call(handleValidationModal, true, "validation");
    } else {
      yield call(
        customSwal,
        "Uyarı",
        "Tuzla Kart başvurusu 14 yaş ve üzeri için geçerlidir.",
        "info"
      );
    }
  } catch (err) {
    console.log(err);
    if (err.response.data.errorCode == 404) {
      yield call(
        customSwal,
        "Uyarı",
        "Kullanıcı kaydınız bulunmaktadır.",
        "info"
      );
    } else if (err.response.data.errorCode == 405) {
      yield call(
        customSwal,
        "",
        "Tuzla Kart başvuru talebiniz alınmıştır. Sisteme giriş yapabilmek için Tuzla Belediye Başkanlığı merkez binasında bulunan Tuzla Kart masasına gelerek ücretsiz kartınızı şahsen teslim almanız gerekmektedir. ",
        "info"
      );
    } else if (err.response.data.errorCode == 407) {
      yield call(
        customSwal,
        "Kimlik Sorgulama Hatası",
        "Lütfen TC kimlik numaranızı ve doğum tarihinizi kontrol ediniz.",
        "info"
      );
    } else if (err.response.data.code == 400) {
      yield call(customSwal, "Hata", err.response.data.error, "error");
    } else {
      yield call(
        customSwal,
        "Hata",
        "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
        "error"
      );
    }

    yield put(actions.tkRegisterFailed());
  }
}

function* watchValidateRequest() {
  yield takeLatest(TK_VALIDATE_REQUEST, validateRequest);
}

function* validateRequest(action) {
  try {
    const { data, handleValidationModal } = action.payload;
    const { tckn, name, surname, validation_code } = data;

    const res = yield call(apiCall, "post", `uservalidate/${tckn}`, {
      validation_code,
      name,
      surname,
    });

    yield put(actions.tkValidationSucceeded());
    yield call(customSwal, "Başarılı", res.data.message, "success");
    yield call(handleValidationModal, false, "validation");
  } catch (err) {
    console.log(err);
    yield put(actions.tkRegisterFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchValidateTimeout() {
  yield takeLatest(TK_VALIDATE_TIMEOUT, validateTimeout);
}

function* validateTimeout(action) {
  try {
    const { handleValidationModal } = action.payload;

    yield put(actions.tkValidationSucceeded());
    yield call(
      customSwal,
      "Başarısız",
      "Size verilen süre zarfında giriş yapamadınız",
      "error"
    );
    yield call(handleValidationModal, false, "validation");
  } catch (err) {
    console.log(err);
    yield put(actions.tkRegisterFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

export default function* authSaga() {
  yield all([
    fork(watchLogin),
    fork(watchForgotPassword),
    fork(watchUserInfo),
    fork(watchRegister),
    fork(watchValidateRequest),
    fork(watchValidateTimeout),
  ]);
}
