import React, { useRef,useState,useEffect  } from "react";

import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import TKLogo from "../../TKLogo/TKLogo";
import TKIcon from "../../TKIcon/TKIcon";
import { useDispatch, useSelector } from "react-redux";
import {  
  tkValidationRequest,
  tkValidationTimeOut } from "../../../store/actions/auth";

const TKValidationForm = ({ setShowModal,handleShowModal }) => {
    

  const {telephone_number, tckn,name,surname} = useSelector(state => state.auth);
  const [error, setErrors] = useState('');


  const dispatch = useDispatch();
  const {  minutes, seconds } = { minutes: 3, seconds: 0};
  const [[ mins, secs], setTime] = useState([minutes, seconds]);
    

    const tick = () => {
   
    
       if (mins !==0  && secs === 0) {
            setTime([ mins - 1, 59]);
        } else {
            setTime([ mins, secs - 1]);
        }
    };



    
   useEffect(() => {
    if (mins !== 0 || secs !== 0) {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
     }
     else {
      dispatch(tkValidationTimeOut({
        handleValidationModal : (show,field) => handleShowModal(show,field)
      }))

     }
    },[ mins, secs]);

  const validationCode = useRef();

  function validate(e) {
    e.preventDefault();
    const validation_code = validationCode?.current?.value;
    if (checkValidation()){
    dispatch(tkValidationRequest({
        data: {
            validation_code,
            tckn,
            name,
            surname
        },
        handleValidationModal : (show,field) => handleShowModal(show,field)
      }));
    }
  }

  const checkValidation = () => {
    
    const length = validationCode?.current?.value.length;

    if (length === 4) {
        return true;
    }
    else {
        setErrors('Kod 4 haneli olmalı')
        return false;
    }

  }

  return (
    <>
       <Card className="border-0 tk-login-form">
        <Card.Header className="d-flex bg-primary p-3 justify-content-center align-items-center">
          <Card.Title className="m-0 d-flex justify-content-center align-items-center">
            <div>
              <TKLogo size={150} />
            </div>
            <span>
              <small>
                <i className="text-white">| Doğrulama</i>
              </small>
            </span>
            <div className="position-absolute" style={{ right: "10px", top: "10px", transform: "rotate(45deg)" }} onClick={() => setShowModal(false)}>
              <TKIcon name="plus" color="white" size="15" />
            </div>
          </Card.Title>
        </Card.Header>

        <Card.Body className="p-5">
        <Form>
          <Form.Group className="mb-2" controlId="formBasicName">
              <Form.Label> Son dört hanesi ****{`${telephone_number}`.slice(-4) } olan telefon numaranıza gönderilen doğrulama kodunuzu giriniz *  <p>
              {`${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`} </p> </Form.Label>
              <Form.Control
               size="xs"
               maxLength="4"
               ref={validationCode}
                 />
              <Form.Text className="text-danger">{error}</Form.Text>
            </Form.Group>
            <div className="d-flex w-100 justify-content-end">
            <Button className="mt-2" variant="primary" type="submit"  onClick={(e) => validate(e)}>
              Doğrula
            </Button>
            </div>
        </Form>

        </Card.Body>
      </Card>
    </>
  );
};

export default TKValidationForm;

TKValidationForm.defaultProps = {
  setShowModal: () => {},
};
