import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/organizations';
import { 
  GET_TK_ORGANIZATIONS_REQUEST,
  CREATE_TK_ADMIN_ORGANIZATIONS_REQUEST,
  DELETE_TK_ADMIN_ORGANIZATIONS_REQUEST,
  SEARCH_TK_ORGANIZATIONS_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';

function* watchGetTKOrganizations() {
  yield takeEvery(GET_TK_ORGANIZATIONS_REQUEST, getTKOrganizations);
}

function* getTKOrganizations(action) {
  const SIZE = 50;
  try {
    const res = yield call(apiCall, 
      'get', 
      'api/loyalty/organization/getOrganizations'
    );

    yield put(actions.getTKOrganizationsSucceeded({
      data: [...res.data.data],
      page:0,
      search:'',
      totalPages: 0
    }));
    
  } catch (err) {
    console.log(err)
    yield put(actions.getTKOrganizationsFailed());
  }
}

function* watchCreateTKAdminOrganizations() {
  yield takeLatest(CREATE_TK_ADMIN_ORGANIZATIONS_REQUEST, createTKAdminOrganizations);
}

function* watchSearchTKOrganizations() {
  yield takeEvery(SEARCH_TK_ORGANIZATIONS_REQUEST, searchTKOrganizations);
}

function* searchTKOrganizations(action) {
  const SIZE = 50;
  try {
    const {page,search} = action.payload;
    const res = yield call(apiCall, 
      'post', 
      'api/loyalty/organization/searchorganizations',
      {
        page,
        search,
        size: SIZE
      }
    );
    yield put(actions.searchTKOrganizationsSucceeded({
      data: [...res.data.data],
      page,
      search,
      totalPages: Math.ceil(res.data.totalCount/SIZE)
    }));
  } catch (err) {
    console.log(err)
    yield put(actions.searchTKOrganizationsFailed());
  }
}

function* createTKAdminOrganizations(action) {
  try {
    const {data, handleSetShowOrganizationsAddModal} = action.payload;
    yield call(apiCall, 
      'post', 
      'api/loyalty/organization', 
      {
        ...data
      }
    );
    yield put(actions.processTKAdminOrganizationsSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Organizasyon başarıyla oluşturuldu',
      'success'
    );
    yield call(handleSetShowOrganizationsAddModal, false);
    if (action.payload.search === "") {
      yield put(actions.getTKOrganizationsRequest({page:action.payload.page , search: ''}));
    }
    else {
      yield put(actions.searchTKOrganizationsRequest({page:action.payload.page , search: action.payload.search}));   
    }
  } catch (err) {
    console.log(err)
    yield put(actions.processTKAdminOrganizationsFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


function* watchDeleteTKAdminOrganizations() {
  yield takeLatest(DELETE_TK_ADMIN_ORGANIZATIONS_REQUEST, deleteTKAdminOrganizations);
}

function* deleteTKAdminOrganizations(action) {
  try {
    const {selectedId} = action.payload;
    yield call(apiCall, 
      'put', 
      `api/loyalty/organization/${selectedId}/delete`
    );
    yield put(actions.processTKAdminOrganizationsSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Organizasyon başarıyla silindi',
      'success'
    );
    yield put(actions.setShowOrganizationsDeleteModal(false));
    if (action.payload.search === "") {
      yield put(actions.getTKOrganizationsRequest({page:action.payload.page , search: ''}));
    }
    else {
      yield put(actions.searchTKOrganizationsRequest({page:action.payload.page , search: action.payload.search}));   
    }
  } catch (err) {
    yield put(actions.processTKAdminOrganizationsFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

export default function* organizationsSaga() {
  yield all([
    fork(watchGetTKOrganizations),
    fork(watchCreateTKAdminOrganizations),
    fork(watchDeleteTKAdminOrganizations),
    fork(watchSearchTKOrganizations)
  ]);
}
