import * as types from '../actionTypes';

const initialState = {
  list: [],
  loading: false,
  error: false,
  admin: {
    processLoading: false,
    processError: false,
    showAddModal: false,
    showDeleteModal: false
  }
}

const categories = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_TK_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_CATEGORIES_SUCCEEDED:
      return {
        ...state,
        list: [...action.payload],
        loading: false,
        error: false
      }
    case types.GET_TK_CATEGORIES_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.CREATE_TK_ADMIN_CATEGORIES_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_CATEGORIES_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showAddModal: action.payload
        }
      }
    case types.PROCESS_TK_ADMIN_CATEGORIES_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_ADMIN_CATEGORIES_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: true
        }
      }
    case types.SET_SHOW_CATEGORIES_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload,
        }
      }
    case types.DELETE_TK_ADMIN_CATEGORIES_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    default:
      return state;
  }
};

export default categories;