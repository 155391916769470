import React from "react";
import "./MainLayout.scss";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { adminUserLinks } from "../../../../constants/general";

const MainSideBar = () => {
  const { links } = useSelector((state) => state.auth.user);

  const sidebarToggleClass =
    window.innerWidth <= 576 ? "sidebar-close" : "sidebar-open";
  const sidebarContentToggleClass =
    window.innerWidth <= 576 ? "sidebar-content-close" : "sidebar-content-open";

  return (
    <Nav
      className={"tk_msb d-block bg-white border sidebar " + sidebarToggleClass}
      id="sidebar-wrapper"
    >
      <br />
      <div
        className={"px-2 sidebar-content pb-4 " + sidebarContentToggleClass}
        id="sidebar-content-wrapper"
      >
        {adminUserLinks.map(
          ({ linkTitle, id, isPage }, index) =>
            links.includes(id) &&
            isPage && (
              <Nav.Item>
                <NavLink to={`/admin/${id}`} activeClassName="tk_msb-active">
                  {linkTitle}
                </NavLink>
              </Nav.Item>
            )
        )}
        {/* <Nav.Item>
          <NavLink to="/admin/card-holders" activeClassName="tk_msb-active">
            Kart Sahipleri
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/admin/member-workplaces" activeClassName="tk_msb-active">
            Üye İşyerleri
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/admin/campaigns" activeClassName="tk_msb-active">
            Kampanyalar
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/admin/news" activeClassName="tk_msb-active">
            Haberler
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/admin/banners" activeClassName="tk_msb-active">
            Afişler
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/admin/categories" activeClassName="tk_msb-active">
            Kategoriler
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/admin/complains" activeClassName="tk_msb-active">
            Şikayetler
          </NavLink>
        </Nav.Item> 
        <Nav.Item>
          <NavLink to="/admin/faq" activeClassName="tk_msb-active">
            SSS
          </NavLink>
        </Nav.Item> 

        <Nav.Item>
          <NavLink to="/admin/tkn" activeClassName="tk_msb-active">
            Tuzla Kart Nedir
          </NavLink>
        </Nav.Item> 

        <Nav.Item>
          <NavLink to="/admin/admin-users" activeClassName="tk_msb-active">
            Admin Kullanıcı Yönetimi
          </NavLink>
        </Nav.Item>  */}

        {/*
        
        <Nav.Item>
          <NavLink to="/admin/complains" activeClassName="tk_msb-active">
            Şikayetler
          </NavLink>
        </Nav.Item> 

         <Nav.Item>
          <NavLink to="/admin/settings" activeClassName="tk_msb-active">
            Ayarlar
          </NavLink>
        </Nav.Item> 
        
        
        */}
      </div>
    </Nav>
  );
};

export default MainSideBar;
