import React, { useEffect, useState } from "react";
import "./TKCardHoldersForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import TKFilterDropdown from "../../../TKFilterDropdown/TKFilterDropdown";
import TKCardsConfigureContainer from "./TKCardsConfigureContainer/TKCardsConfigureContainer";
import TKSelect from "../../../TKSelect/TKSelect";
import { statusLabel } from "../../../../constants/general";
import { getTKOrganizationsRequest } from "../../../../../src/store/actions/organizations";
import { getTKCardTypesRequest } from "../../../../../src/store/actions/cardTypes";
import { getTKAdminProfessionsRequest } from "../../../../../src/store/actions/professions";
import TKDatePicker from "../../TKInputs/TKDatePicker/TKDatePicker";
import { formatDate } from "../../../../helpers/utilities";

const TKCardHoldersForm = ({
  formData,
  formDataErrors,
  onChange,
  isEdit,
  cardsList,
  selectedId,
  user,
  checkCardNumberAlreadyUsed,
  checkTcknAlreadyUsed,
  cardError,
  tcknError,
}) => {
  const dispatch = useDispatch();
  const { listOrganization } = useSelector((state) => state.organizations);
  const { listCardTypes } = useSelector((state) => state.cardTypes);
  const { listProfessions } = useSelector((state) => state.professions);
  const [filteredListProfessions, filteredSetListProfessions] =
    useState(listProfessions);
  const [filteredListOrganization, filteredSetListOrganization] =
    useState(listOrganization);
  const [mangeticCodeField, setMagneticCodeField] = useState(false);
  const [sexState, setSexState] = useState([
    { sex: "ERKEK", _id: "ERKEK" },
    { sex: "KADIN", _id: "KADIN" },
  ]);
  const [status, setStatus] = useState([
    { status: "AKTİF", _id: "ACTIVE" },
    { status: "PASİF", _id: "PASSIVE" },
    { status: "ONAY BEKLİYOR", _id: "WFA" },
    { status: "RED", _id: "CANCELED" },
  ]);
  const [selectSearchOrganization, setSelectSearchOrganization] = useState("");
  const [selectSearchProfession, setSelectSearchProfession] = useState("");
  useEffect(() => {
    dispatch(getTKOrganizationsRequest());
    dispatch(getTKCardTypesRequest());
    dispatch(getTKAdminProfessionsRequest());
  }, [dispatch]);

  const handleDropdownOnChange = (id) => {
    const organization = [...formData?.organization];
    const idIndex = organization.indexOf(id);
    if (idIndex !== -1) {
      organization.splice(idIndex, 1);
      return onChange("organization", organization);
    }
    onChange("organization", [...organization, id]);
  };

  const handleCardTypeOnChange = (name, id) => {
    if (name === "GIDA KARTI") {
      onChange("food_card_selected", true);
      setMagneticCodeField(true);
    } else {
      onChange("food_card_selected", false);
      setMagneticCodeField(false);
    }
    onChange("type", [id]);
  };

  const onSelectChange = (name, value) => {
    if (value == "Backspace") {
      filteredSetListProfessions(listProfessions);
      filteredSetListOrganization(listOrganization);

      setSelectSearchProfession("");
      setSelectSearchOrganization("");
    } else if (
      value == "Capslock" ||
      value == "ArrowDown" ||
      value == "ArrowUp" ||
      value == "ArrowLeft" ||
      value == "ArrowRight"
    ) {
    } else if (name === "profession") {
      filteredSetListProfessions(
        listProfessions.filter(function (item) {
          if (
            item.name
              .toLowerCase()
              .startsWith((selectSearchProfession + value).toLowerCase())
          ) {
            setSelectSearchProfession(selectSearchProfession + value);

            return item;
          }
        })
      );
    } else if (name === "organization") {
      filteredSetListOrganization(
        listOrganization.filter(function (item) {
          if (
            item.name
              .toLowerCase()
              .startsWith((selectSearchOrganization + value).toLowerCase())
          ) {
            setSelectSearchOrganization(selectSearchOrganization + value);

            return item;
          }
        })
      );
    }
  };

  return (
    <Form className="tk_chs-form">
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>tckn *</Form.Label>
            <Form.Control
              as={InputMask}
              type="text"
              name="tckn"
              mask="99999999999"
              placeholder="TCKN"
              value={formData.tckn || ""}
              onBlur={() => {
                if (!isEdit) checkTcknAlreadyUsed();
              }}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.tckn}
              maskChar={null}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.tckn || tcknError}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>adı *</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Adı"
              value={formData.name || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.name}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.name}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>soyadı *</Form.Label>
            <Form.Control
              type="text"
              name="surname"
              placeholder="Soyadı"
              value={formData.surname || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.surname}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.surname}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>telefon *</Form.Label>
            <Form.Control
              as={InputMask}
              name="telephone_number"
              placeholder="Telefon"
              value={formData.telephone_number || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.telephone_number}
              mask="5999999999"
              maskChar={null}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.telephone_number}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Cinsiyet *</Form.Label>
            <TKSelect
              defaultTitle="Cinsiyet Seç"
              selectedItem={formData?.sex}
              itemSelector="sex"
              items={sexState}
              itemOnClick={(id) => onChange("sex", id)}
              isInvalid={formDataErrors.sex}
              name="sex"
            />
            <Form.Text className="text-danger">{formDataErrors?.sex}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>doğum tarihi *</Form.Label>
            <TKDatePicker
              className={formDataErrors?.birthdate && "is-invalid"}
              dateValue={formData.birthdate}
              dateFormat="P"
              placeholder={`ör: ${formatDate(new Date(), "P")}`}
              onChange={(date) => onChange("birthdate", date)}
              showTimeInput={false}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.birthdate}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>meslek *</Form.Label>
            <TKSelect
              defaultTitle="Meslek Seç"
              selectedItem={formData?.profession}
              itemSelector="name"
              items={filteredListProfessions}
              itemOnClick={(id) => onChange("profession", id)}
              isInvalid={formDataErrors.profession}
              search={selectSearchProfession}
              name="profession"
              onChange={(value) => onSelectChange("profession", value)}
              filter={true}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.profession}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>eposta</Form.Label>
            <Form.Control
              type="text"
              name="email"
              placeholder="Eposta"
              value={formData.email || ""}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
              }}
              isInvalid={formDataErrors.email}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.email}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Durum *</Form.Label>
            <TKSelect
              defaultTitle="Durum Seç"
              selectedItem={formData?.status}
              itemSelector="status"
              items={status}
              itemOnClick={(id) => onChange("status", id)}
              isInvalid={formDataErrors.status}
              name="status"
            />
            <Form.Text className="text-danger">
              {formDataErrors?.status}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Organizasyon</Form.Label>
            <TKFilterDropdown
              variant="light"
              title="Organizasyon seç"
              items={filteredListOrganization}
              itemSelector="name"
              selectedItems={formData.organization}
              itemOnClick={handleDropdownOnChange}
              isInvalid={formDataErrors.organization}
              search={selectSearchOrganization}
              name="organization"
              onChange={(value) => onSelectChange("organization", value)}
              filter={true}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.organization}
            </Form.Text>
          </Form.Group>
          {!isEdit && (
            <>
              <Form.Group>
                <Form.Label>kart no *</Form.Label>
                <Form.Control
                  as={InputMask}
                  name="number"
                  mask="99999999999999"
                  placeholder="Kart No"
                  value={formData.number || ""}
                  onBlur={(e) => {
                    checkCardNumberAlreadyUsed();
                  }}
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value?.replace(/ /g, ""));
                  }}
                  isInvalid={formDataErrors.number}
                  maskChar={null}
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.number || cardError}
                </Form.Text>
              </Form.Group>
              {mangeticCodeField && (
                <Form.Group>
                  <Form.Label>Manyetik Kod *</Form.Label>
                  <Form.Control
                    placeholder="Manyetik Kod"
                    value={formData.magnetic_code || ""}
                    isInvalid={formDataErrors.magnetic_code || cardError}
                    onChange={(e) => {
                      onChange("magnetic_code", e.target.value);
                    }}
                    readOnly={isEdit ? true : false}
                  />
                  <Form.Text className="text-danger">
                    {formDataErrors?.magnetic_code || cardError}
                  </Form.Text>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Kategori *</Form.Label>
                <div className="pl-2">
                  {listCardTypes.map(({ name, _id }, index) => (
                    <>
                      <Form.Check
                        type="checkbox"
                        id={`admin-user-listCardType-checkbox-${index}`}
                        label={name}
                        key={index}
                        name={name}
                        checked={formData?.type?.includes(_id)}
                        onChange={(e) =>
                          handleCardTypeOnChange(e.target.name, _id)
                        }
                      />
                    </>
                  ))}
                </div>
                <Form.Text className="text-danger">
                  {formDataErrors?.type}
                </Form.Text>
              </Form.Group>
            </>
          )}
          {isEdit && (
            <Form.Group>
              <Form.Label>kartlar</Form.Label>
              <TKCardsConfigureContainer
                userId={selectedId}
                user={user}
                list={cardsList}
              />
            </Form.Group>
          )}
          {cardError}
        </Col>
      </Row>
    </Form>
  );
};

export default TKCardHoldersForm;

TKCardHoldersForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {},
  checkCardNumberAlreadyUsed: () => {},
  checkTcknAlreadyUsed: () => {},
  isEdit: false,
  cardsList: [],
};
