import React from "react";
import "./TKFooter.scss";
import { Link } from "react-router-dom";
import TKIcon from "../TKIcon/TKIcon";
import TKLogo from "../TKLogo/TKLogo";
import instagram from "../../assets/images/instagram.png";
import TKButton from "../TKButton/TKButton";
import play_store from "../../assets/images/tuzla-kart-google-play-badge.png";
import apple_store from "../../assets/images/apple.png";
const TKFooter = () => {
  return (
    <div className="bg-primary tk_footer">
      <div>
        <div className="row tk_footer-links-wrapper">
          <Link exact="true" to="/">
            <TKLogo />
          </Link>
          <div className="tk_footer-contacttext">444 0 906</div>
          <div className="tk_footer-contact">
            <a href="https://ebelediye.tuzla.bel.tr/basvuruyonetimi/basvuru/bizeyazin">
              <TKButton variant="new">bize ulaşın</TKButton>
            </a>
          </div>
        </div>
        <div className="tk_footer-sm-icons">
          <a
            href="https://www.facebook.com/tuzlakartcom"
            className="fb-color"
            target="_blank"
            rel="noreferrer"
          >
            <TKIcon name="facebook" size="17" color="#09072f" />
          </a>
          <a
            href="https://twitter.com/tuzlakart"
            className="tw-color"
            target="_blank"
            rel="noreferrer"
          >
            <TKIcon name="twitter" size="17" color="#09072f" />
          </a>
          <a
            href="https://www.instagram.com/tuzlakart/"
            className="insta-color"
            target="_blank"
            rel="noreferrer"
          >
            <TKIcon name="instagram" size="17" color="#09072f" />
          </a>
        </div>
        <div className="tk_footer-mobillinks">
          <a
            href="https://play.google.com/store/apps/details?id=com.tuzlakart&pli=1"
            target="_blank"
            rel="noreferrer"
          >
            <img
              height={50}
              src={play_store}
              alt="Tuzlalı'nın kartı Mobil uygulama ile Google Play Mağazasında"
              title="Tuzla Kart "
              longdesc="https://tuzlakart.com/"
            ></img>
          </a>

          <a
            href="https://apps.apple.com/us/app/tuzla-kart/id1458336129?l=tr"
            target="_blank"
            rel="noreferrer"
          >
            <img
              height={50}
              src={apple_store}
              alt="Tuzlalı'nın kartı Mobil uygulama ile Apple Mağazasında"
              title="Tuzla Kart "
              longdesc="https://tuzlakart.com/"
            ></img>
          </a>
        </div>
        <div className="ml-5 mt-5 cursor-pointer">
          <a href="https://www.tuzkam.com/">
            <img src="https://www.tuzkam.com/UI/tr-TR/images/logo.png" />
          </a>
        </div>
        <div className="tk_footer-text">
          tuzla kart bir tuzla belediyesi projesidir.
        </div>
      </div>
    </div>
  );
};

export default TKFooter;
