import React from 'react';
import './TKButton.scss';

const TKButton = ({variant, className, onClick, loading, disabled, children}) => {
  return (
    <button 
      className={`tk_button btn-${variant} ${className}`.trim()}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {children}
    </button>
  );
};

export default TKButton;

TKButton.defaultProps = {
  variant: "primary",
  className: "",
  onClick: null,
  loading: false,
  disabled: false
}