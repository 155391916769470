import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TKDetailsImage from "../../../../../components/TKDetailsImage/TKDetailsImage";
import TKDetailsHeader from "../../../../../components/TKDetailsHeader/TKDetailsHeader";
import TKDetailsContent from "../../../../../components/TKDetailsContent/TKDetailsContent";
import TKDetailsAddress from "../../../../../components/TKDetailsAddress/TKDetailsAddress";
import { getTKMWPByIdRequest } from "../../../../../store/actions/memberWorkplaces";
import TKLoaderContainer from "../../../../../components/TKLoaderContainer/TKLoaderContainer";
import TKMap from "../../../../../components/TKMap/TKMap";

const MemberWorkplacesDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { displayMWP, loading } = useSelector(
    (state) => state.memberWorkplaces
  );

  const position = {
    lat: displayMWP?.map_lat,
    lng: displayMWP?.map_long,
  };

  useEffect(() => {
    dispatch(getTKMWPByIdRequest(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      {loading && <TKLoaderContainer />}
      {!loading && (
        <div className="container">
          <TKDetailsImage
            discount={displayMWP.discounts}
            src={
              displayMWP?.image
                ? `${process.env.REACT_APP_IMAGES_URL}${displayMWP.image}`
                : "https://media-cdn.tripadvisor.com/media/photo-m/1280/1b/33/f6/60/caption.jpg"
            }
          />
          <TKDetailsHeader header={displayMWP?.entitle}>
            {/* <TKRate rate={4} size="19"/> */}
          </TKDetailsHeader>
          <TKDetailsAddress company={displayMWP} />
          <TKDetailsContent
            title="kampanya detayları"
            desc={displayMWP?.detail}
          />
          {!isNaN(position.lat) && !isNaN(position.lng) && (
            <div className="tk_details-address-map">
              <TKMap position={position} center={position} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MemberWorkplacesDetails;
