import React from 'react';
import './TKContainerFooter.scss';
import saying from '../../assets/images/tuzla-kart-footer-baskan.png'

const TKContainerFooter = () => {
  return (
    <img className="tk_cf" src={saying} alt="saying"/>
  );
};

export default TKContainerFooter;