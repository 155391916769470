import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TKBannerForm.scss';
import { Form, Row, Col } from 'react-bootstrap';
import TKFileInput from '../../TKInputs/TKFileInput/TKFileInput';
import TKSelect from '../../../TKSelect/TKSelect';
import { bannertypes } from "../../../../constants/general";
import { 
  getTKAdminBannersContentRequest
} from "../../../../store/actions/banners";
import { statusLabel } from '../../../../constants/general';




const TKBannerForm = ({formData, formDataErrors, onChange, isCreate}) => {

  const {contentlist} = useSelector(state => state.banners.admin);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [status,setStatus] = useState([{ status: "AKTİF" , _id: "ACTIVE"}, {status: "PASİF" , _id:"PASSIVE" }]);



  const dispatch = useDispatch();


  

  useEffect(() => {
    if (isInitialRender) {
      if (formData.bannertype === "Haber")
      dispatch(getTKAdminBannersContentRequest(bannertypes.Haber)); 
      else 
      dispatch(getTKAdminBannersContentRequest(bannertypes.Kampanya));
      setIsInitialRender(false);   
     }
  });


  const handleContentList = (value) => {
  
  
    if (value === 'news') {
      dispatch(getTKAdminBannersContentRequest(bannertypes.Haber));    
      onChange('link','')
    }
    if (value === 'campaign') {

      dispatch(getTKAdminBannersContentRequest(bannertypes.Kampanya));    
      onChange('link','')
    }
    if (value == 'empty') {
      onChange('link','')

    }
  
   onChange('banner',value)
   onChange('bannertype',value)

  
  }


  const onSelectKeyPressed = (event,selector) => {
  
    if (selector === "status") { 
 
     status.filter(function(item) {
         if (item.status.toLowerCase().startsWith(event.toLowerCase())) {
 
           onChange("status", item._id)
 
         }
     })
     
   }

   if (selector === "banner") { 
 
    contentlist.filter(function(item) {
        if (item.short_detail.toLowerCase().startsWith(event.toLowerCase())) {

          onChange("banner", item._id)

        }
    })
    
  }
 
  }


  return (
    <Form className="tk_banner-form">
      <Form.Group>
        <Form.Label>başlık *</Form.Label>
        <Form.Control
          type="text"
          name="caption"
          placeholder="Başlık"
          value={formData.caption || ""}
          maxLength={100}
          onChange={e => onChange(e.target.name, e.target.value)}
          isInvalid={formDataErrors.caption}
        />
        <Form.Text className="text-danger">{formDataErrors?.caption}</Form.Text>
      </Form.Group>
      {!isCreate && (
        <Form.Group>
          <Form.Label>Durum *</Form.Label>
          <TKSelect
            defaultTitle="Afişin Durumunu Seç"
            selectedItem={formData?.status}
            itemSelector="status"
            items={status}
            itemOnClick={(id) => onChange("status", id)}
            isInvalid={formDataErrors.status}
            onKeyPress={(key,selector) => onSelectKeyPressed(key,selector)}
            name="status"
          />
          <Form.Text className="text-danger">{formDataErrors?.status}</Form.Text>
        </Form.Group>
      )}
      <Form.Group>
      <Form.Label>{formData.previousimage && <> Yüklü </>} Fotoğraf {formData.previousimage && <> : { formData.previousimage } </>} </Form.Label>
        <TKFileInput
          selectedFile={formData.image}
          onChange={(file) => onChange('image', file)}
          className={formDataErrors?.image ? "tk_file-input-error" : ""}
          imgsrc={formData.previousimage}
          isEdit={!isCreate}
        />
        <Form.Text className="text-danger">{formDataErrors?.image}</Form.Text>
      </Form.Group>

      <Form.Group className="mb-0">
        <Form.Check
          inline
          className="mb-2"
          type="radio"
          id="radio-link"
          label="Bağlantısız"
          name="banner"
          value={bannertypes.Empty}
          defaultChecked={formData?.bannertype === "Afiş"}
          onChange={e => { 
            handleContentList(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className="mb-0">
        <Form.Check
          inline
          className="mb-2"
          type="radio"
          id="radio-news"
          label="Haber Seç"
          name="banner"
          value={bannertypes.Haber}
          defaultChecked={formData?.bannertype === "Haber"}
          onChange={e => { 
            handleContentList(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          inline
          type="radio"
          id="radio-campaign"
          label="Kampanya Seç"
          name="banner"
          value={bannertypes.Kampanya}
          defaultChecked={formData?.bannertype === "Kampanya"}
          onChange={e => { 
            handleContentList(e.target.value);
          }}
        />
        <Form.Text className="text-danger">{formDataErrors?.loadType}</Form.Text>
      </Form.Group>

      <Form.Group>
        <Form.Check
          inline
          type="radio"
          id="link-type"
          label="Bağlantı"
          name="banner"
          value={bannertypes.Link}
          defaultChecked={formData?.bannertype === "Link"}
          onChange={e => { 
            handleContentList(e.target.value);
          }}
        />
      </Form.Group>
  {((formData.bannertype !== "Afiş" ) && (formData.bannertype !== "empty" ) && (formData.bannertype !== "Link") && (formData.bannertype !== "link"))  && (
      <Form.Group>
              <TKSelect
                defaultTitle="Seçiminizi Yapınız"
                selectedItem={formData.banner}
                itemSelector="short_detail"
                items={contentlist}
                itemOnClick={(id) => onChange("banner", id)}
                isInvalid={formDataErrors.type}
                onKeyPress={(key,selector) => onSelectKeyPressed(key,selector)}
                name="banner"
              />
              <Form.Text className="text-danger">{formDataErrors?.type}</Form.Text>
            </Form.Group>
       ) }  
 {((formData.bannertype === "link")  ||  (formData.bannertype === "Link"))  && (
<Form.Group>
        <Form.Control
          type="text"
          name="link"
          placeholder="ör: https://website.com/"
          value={formData.link || ""}
          maxLength={100}
          onChange={e => onChange(e.target.name, e.target.value)}
          isInvalid={formDataErrors.link}
        />
        <Form.Text className="text-danger">{formDataErrors?.link}</Form.Text>
      </Form.Group>
 )} 

<Form.Group>
                <Form.Label>sıra seçimi</Form.Label>
                <Form.Control
                  type="number"
                  className="w-50"
                  name="order_number"
                  value={formData.order_number || ""}
                  onChange={e => onChange(e.target.name, e.target.value)}
                  //isInvalid={formDataErrors?.order_number}
                />
                {/* 
                <Form.Text className="text-danger">
                  {formDataErrors?.order_number}
                </Form.Text>
                */}
          </Form.Group>

    </Form>
  );
};

export default TKBannerForm;

TKBannerForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {},
  isCreate: false,
}