import * as types from '../actionTypes';

const initialState = {
  listCardTypes: [],
  loading: false,
  error: false,
  search:'',
  admin: {
    processLoading: false,
    processError: false,
    showAddModal: false,
    showDeleteModal: false
  }
}

const cardTypes = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_TK_CARDTYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_CARDTYPES_SUCCEEDED:
      return {
        ...state,
        listCardTypes: [...action.payload],
        loading: false,
        error: false
      }
    case types.GET_TK_CARDTYPES_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.SEARCH_TK_CARDTYPES_REQUEST:
        return {
          ...state,
          loading: true,
          error: false
        }
    case types.SEARCH_TK_CARDTYPES_SUCCEEDED:
        return {
          ...state,
          listCardTypes: [...action.payload],
          loading: false,
          error: false
        }
    case types.SEARCH_TK_CARDTYPES_FAILED:
        return {
          ...state,
          loading: false,
          error: true
        }
    case types.CREATE_TK_ADMIN_CARDTYPES_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_CARDTYPES_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showAddModal: action.payload
        }
      }
    case types.PROCESS_TK_ADMIN_CARDTYPES_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_ADMIN_CARDTYPES_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: true
        }
      }
    case types.SET_SHOW_CARDTYPES_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload,
        }
      }
    case types.DELETE_TK_ADMIN_CARDTYPES_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    default:
      return state;
  }
};

export default cardTypes;