import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './TKLoginContainer.scss';
import TKButton from '../TKButton/TKButton';
import { Modal, Dropdown, Container, Row, Col } from 'react-bootstrap';
import TKLoginForm from './TKLoginForm/TKLoginForm';
import TKSignupForm from './TKSignupForm/TKSignupForm';
import { useAuth } from '../../context/authContext';
import TKUserCircleDropdown from '../AdminLayout/TKUserCircleDropdown/TKUserCircleDropdown';
import { tkLogout } from '../../store/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import TKUserInfo from './TKUserInfo/TKUserInfo';
import TKValidationForm from './TKValidationForm/TKValidationForm';
import TKForgotPasswordForm from './TKForgotPasswordForm/TKForgotPasswordForm';
import TKNewPasswordForm from './TKNewPasswordForm/TKNewPasswordForm';
import TKMWPForgotPasswordForm from './TKMWPForgotPasswordForm/TKMWPForgotPassword';
import {userRoles} from '../../constants/general';

const TKLoginContainer = ({user, ...props}) => {
  const isAuth = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [field, setField] = useState(isAuth ? 'userInfo' : 'login');
  const dispatch = useDispatch();

  const info = useSelector(state => state.auth.userInfo);

  useEffect(() => {
    if (isAuth && field !== "userInfo" && showModal) {
        setShowModal(false);
    }
  }, [isAuth, showModal]);

  const handleShowModal = (show, field) => {
    setField(field);
    setShowModal(show);
  }
  
  return (
    <div className="tk_login-container d-flex justify-content-center">
      {isAuth ? (
        <div className="p-2 ml-3">
          <TKUserCircleDropdown 
            size="20px" 
            iconSize="20"
            userName={`${info?.name ? info.name : ''} ${info?.surname ? info.surname : ''}`}
            customMenu={
              <Dropdown.Menu>
                          { info?.role === userRoles.USER && 
                <div className="px-2 py-1 text-center">
                  <div className="mx-2 my-1 p-1 px-3 bg-secondary text-white text-nowrap rounded font-weight-light">
                    {`Bakiyeniz: ${info?.balance ? info.balance : '0'} TL`}
                  </div>
                </div>}
                          
                <Dropdown.Item 
                  className=""
                  as={Link} 
                  to="/hesabim"
                >
                  Hesabım
                </Dropdown.Item>
                <Dropdown.Item 
                  className=""
                  onClick={() => dispatch(tkLogout())}
                >
                  Çıkış Yap
                </Dropdown.Item>
              </Dropdown.Menu>
            }
          />
        </div>
      ) : (
        <>
          {/* <TKButton variant="secondary" onClick={() => handleShowModal(true, "signup")}>
            Kayıt Ol
          </TKButton> */}
          <TKButton variant="new" onClick={() => handleShowModal(true, "login")}>
            Kayıt Ol / Giriş Yap
          </TKButton>
        </>
      )}
      <Modal
        className="tk_login-signup-modal"
        show={showModal}
        onHide={(h) => setShowModal(h)}
        size={field === "userInfo" ? "lg" : "md"}
        centered
        enforceFocus={false}
      >
        <Modal.Body className="p-0">
          {field === "login" && <TKLoginForm setShowModal={(p) => setShowModal(p)} handleShowModal = {handleShowModal}/>}
          {field === "signup" && <TKSignupForm setShowModal={(p) => setShowModal(p)} handleShowModal={handleShowModal} />}
          {field === "userInfo" && <TKUserInfo setShowModal={(p) => setShowModal(p)} />}
          {field === "validation" && <TKValidationForm setShowModal={(p) => setShowModal(p)} handleShowModal={handleShowModal} />}
          {field === "forgotpassword" && <TKForgotPasswordForm setShowModal={(p) => setShowModal(p)} handleShowModal={handleShowModal} />}
          {field === "newpassword" && <TKNewPasswordForm setShowModal={(p) => setShowModal(p)} />}
          {field === "mwpforgotpassword" && <TKMWPForgotPasswordForm setShowModal={(p) => setShowModal(p)} handleShowModal={handleShowModal} />}

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TKLoginContainer;

TKLoginContainer.defaultProps = {
  setShowModal: () => {},
  user: {
    "balance": 5,
    "role": "user",
    "_id": "61275718171a5c0c414c454e",
    "name": "Leonard",
    "surname": "Cohen",
    "tckn": "25369871235",
    "cards": [
        {
            "_id": "61275718171a5c0c414c454f",
            "number": "5555555555555555",
            "type": "TEACHER",
            "status": "ACTIVE",
            "given_date": "2021-08-26T08:55:52.476Z"
        }
    ],
    "created_at": "2021-08-26T08:55:52.477Z",
    "email": "admin@tubu",
  },
}