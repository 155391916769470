import Swal from "sweetalert2";

export const customSwal = (title, text, icon) => {
  const swalWithBootstrap = Swal.mixin({
    customClass: {
      confirmButton: 'tk_button btn-primary',
    },
    buttonsStyling: false
  })
  return swalWithBootstrap.fire({
    title,
    text,
    icon,
    confirmButtonText: 'Tamam'
  })
}