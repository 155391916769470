import React from 'react';
import './TKDetailsHeader.scss';

const TKDetailsHeader = ({header, children}) => {
  return (
    <div className="tk_details-header-container">
      <div className="tk_display-xl">
        {header}
      </div>
      <div>
        {children}
      </div>
    </div>
  );
};

export default TKDetailsHeader;

TKDetailsHeader.defaultProps = {
  header: ""
}