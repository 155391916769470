import React from 'react';
import './TKCard.scss';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { htmlStringToPlainTex } from '../../helpers/utilities';

// import TKRate from '../TKRate/TKRate';
// import TKIcon from '../TKIcon/TKIcon';

const TKCard = ({ border, shadow, size, rate, icon, bgColor, image, header, desc, member, onClick, campaign, discounts, badge, href,style }) => {
  const prepareClasses = () => {
    return `
      ${border ? 'tk_card-border' : ''}${shadow ? ' tk_card-shadow' : ''} tk_card-${size}${rate ? ' tk_card-rate' : ''}
      ${icon ? ' tk_card-icon' : ''}${bgColor ? ` tk_card-bg tk_card-bg-${bgColor}` : ''}
    `.trim();
  }

  const badgeClasses = () => {
    return `
      ${badge ? 'tk_card-badge' : 'd-none'}
    `.trim();
  }

  const backgroundImage = `url('${image ? `${process.env.REACT_APP_IMAGES_URL}${image}` : 'https://media-cdn.tripadvisor.com/media/photo-m/1280/1b/33/f6/60/caption.jpg'}')`;

  var default_discount = ""
  if (discounts) {
    default_discount = discounts.filter((d) => { return d.type?.name == "TUZLA KART" })[0];

  }
  return (
    <a href={href && href}>
      <div className={`tk_card ${prepareClasses()}`} onClick={onClick}>

        <span className={`${badgeClasses()}`}>{campaign?.amount ?
          `${campaign?.campaign_type === 'PERCENT' ? '%' + campaign?.amount : campaign?.amount+'₺'}` : (`${default_discount?.discounttype === 'PERCENT' ? '%'+default_discount?.amount : default_discount?.amount+'₺'}`)
        }</span>
        <div
          className="bg-img tk_card-img"
          style={{
            backgroundImage: backgroundImage,
          }}
        ></div>
     
        {size != "auto" && <><div className={`tk_card-header tk_card-header-${size === "auto" ? 'lg' : 'sm'}`} style={{textTransform: "none !important"}}>
          {campaign?.amount ?
            `${campaign?.amount}${campaign?.campaign_type === 'PERCENT' ? '%' : '₺'} indirim` :
            header
          }


        </div>
          <div className="tk_card-member">
            {member}
          </div>
          <div className="tk_card-desc">
            {desc}
          </div></>}

      </div>
    </a>
  );
};

export default TKCard;

TKCard.propTypes = {
  bgColor: PropTypes.oneOf(["", "white", "yellow", "red", "green", "dark-blue", "light-blue", "grey"])
}

TKCard.defaultProps = {
  size: "sm",
  border: true,
  shadow: true,
  rate: false,
  icon: false,
  bgColor: "",
  badge: false,
  image: "",
  header: "",
  desc: "",
  member: "",
  onClick: () => { },
  campaign: {}
}
