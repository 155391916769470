import React, { useState } from "react";
import "./TKDatePicker.scss";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../../../helpers/utilities";
import { tr } from "date-fns/locale";
import TKIcon from "../../../TKIcon/TKIcon";

setDefaultLocale(tr);

const TKDatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <div className="tk_datepicker_header">
      <TKIcon
        name="arrow"
        size="15"
        className="tk_datepicker_header-prev-month"
        onClick={decreaseMonth}
      />
      <div className="tk_datepicker_header-year-month">
        <div className="tk_datepicker_header-month">
          {date.toLocaleString("tr", { month: "long" })}
        </div>

        <div>
          <DatePicker
            customInput={
              <div className="tk_datepicker_header-year-picker">
                {date.getFullYear()}
                <TKIcon
                  name="arrow"
                  size="10"
                  className="tk_datepicker_header-year-picker-arrow"
                />
              </div>
            }
            calendarClassName="tk_dp-year-picker"
            selected={date}
            onChange={(a) => changeYear(a.getFullYear())}
            showYearPicker
            fixedHeight
            dateFormat="yyyy"
            showThreeColumnYearPicker
          />
        </div>
      </div>
      <TKIcon
        name="arrow"
        size="15"
        className="tk_datepicker_header-next-month"
        onClick={increaseMonth}
      />
    </div>
  );
};

const TKDatePicker = ({
  className,
  dateValue,
  placeholder,
  onChange,
  minDate,
  maxDate,
  readOnly,
  dateFormat,
  showTimeInput,
}) => {
  return (
    <DatePicker
      renderCustomHeader={TKDatePickerHeader}
      className={`form-control tk_dp ${className}`.trim()}
      dayClassName={() => "tk_dp-day"}
      weekDayClassName={() => "tk_dp-week"}
      selected={dateValue && new Date(dateValue)}
      dateFormat={dateFormat}
      placeholderText={placeholder}
      onChange={(date) => onChange(date)}
      minDate={minDate}
      maxDate={maxDate}
      readOnly={readOnly}
      // popperClassName="tk_dp-popper "
      // showTimeSelect
      timeInputLabel=""
      timeInputClassName="test"
      showTimeInput={showTimeInput}
    />
  );
};

export default TKDatePicker;

TKDatePicker.defaultProps = {
  className: "",
  dateValue: null,
  placeholder: `ör: ${formatDate(new Date(), "P p")}`,
  onChange: () => {},
  minDate: null,
  maxDate: null,
  readOnly: false,
  dateFormat: "P p",
  showTimeInput: true,
};
