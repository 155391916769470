import React from 'react';
import './TKDetailsImage.scss';




const TKDetailsImage = ({ src, discount }) => {
  var default_discount = ""
  if (discount) {
    default_discount = discount.filter((d) => { return d.type?.name == "TUZLA KART" })[0];

  }
  return (
    <div
      className="tk_details-image"
    >
      {discount &&
        <span className="tk_details-image-batch">{discount?.amount ?
          `${discount?.campaign_type === 'PERCENT' ? '%'+discount?.amount : discount?.amount+'₺'}` : (`${default_discount?.discounttype === 'PERCENT' ? '%'+default_discount?.amount : default_discount?.amount+'₺'}`)
        }</span>
      }

      <img
        src={src}
        className=" bg-img"
      >
      </img>
    </div>


  );
};

export default TKDetailsImage;

TKDetailsImage.defaultProps = {
  src: ""
}