import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './TKLoadMoneyContainer.scss';
import TKButton from '../../../../TKButton/TKButton';
import TKModal from '../../../TKModal/TKModal';
import { 
  setShowLoadMoneyModal,
  loadMoneyTKAdminCardRequest
} from "../../../../../store/actions/cardHolders";
import { validate } from '../../../../../helpers/validate';
import TKLoadMoneyForm from './TKLoadMoneyForm/TKLoadMoneyForm';

const TKLoadMoneyContainer = ({}) => {
  const DEFAULT_LOAD_TYPE = 'ORGANIZATION';
  const initialFormData = {
    loadType: DEFAULT_LOAD_TYPE,
    type: [],
    excel: null,
    amount: "",
    explanation:""
  }

  const initialFormDataValidate = {
    loadType: {
      required: true,
    },
    type: {
      required: true,
    },
    amount: {
      required: true,
      isNumeric: true,
    },
    explanation: {
      required:true
    }
  }

  const dispatch = useDispatch();
  const {processLoading, page, searchFormData, showLoadMoneyModal} = useSelector(state => state.cardHolders.admin);
  const [formData, setFormData] = useState({...initialFormData});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [formDataValidate, setFormDataValidate] = useState({...initialFormDataValidate});


  const checkValidation = () => {

    const errorMsgs = validate(formData, formDataValidate);
    
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({...errorMsgs});
      return false;
    }
    return true;
  }

  const handleSaveOnClick = () => {
    
    const check = checkValidation();
    if (check) {
      dispatch(loadMoneyTKAdminCardRequest({
        data: {
          ...formData,
        },
        page,
        searchFormData,
        handleSetShowLoadMoneyModal
      }));
    }
  }

  const handleOnChange = (name, value) => {
    
    formDataErrors[name] && 
    setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }));

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'loadType') {
      if (value === 'ORGANIZATION') {
        setFormDataValidate({
          ...formDataValidate, 
          excel: null,
          type: {
            required: true,
          }
        });
      } else {
        setFormDataValidate({
          ...formDataValidate,
          type: null,
          amount: null,
          excel: {
            required: true,
          }
        });
      }
    }
  }

  const handleSetShowLoadMoneyModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataValidate({...initialFormDataValidate});
      setFormDataErrors({});
    }
    dispatch(setShowLoadMoneyModal(show));
  }

  return (
    <div className="tk_load-money-btn ">
      <TKButton 
        onClick={(e) => {
          e.preventDefault();
          handleSetShowLoadMoneyModal(true)
        }}
      >
        Toplu Yükleme
      </TKButton>
      <TKModal
        className="tk_load-money-modal"
        show={showLoadMoneyModal}
        setShow={handleSetShowLoadMoneyModal}
        header={"Toplu Yükleme"}
        saveOnClick={handleSaveOnClick}
        processLoading={processLoading}
        saveBtnTitle="Yükle"
        size="lg"
      >
          < TKLoadMoneyForm
            formData={formData}
            formDataErrors={formDataErrors}
            onChange={handleOnChange}
          />
      </TKModal>
    </div>
  );
};

export default TKLoadMoneyContainer;

TKLoadMoneyContainer.defaultProps = {
  setShowModal: () => {},
  btnTitle: ""
}