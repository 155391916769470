import * as types from '../actionTypes';

export const getTKOrganizationsRequest = () => ({
  type: types.GET_TK_ORGANIZATIONS_REQUEST
});

export const getTKOrganizationsSucceeded = ({data, page, search, totalPages}) => ({
  type: types.GET_TK_ORGANIZATIONS_SUCCEEDED,
  payload: {
    data,
    page,
    search,
    totalPages
  }
});

export const getTKOrganizationsFailed = () => ({
  type: types.GET_TK_ORGANIZATIONS_FAILED
});


export const searchTKOrganizationsRequest = ({page,search}) => ({
  type: types.SEARCH_TK_ORGANIZATIONS_REQUEST,
  payload: {
    page,
    search
  }
});

export const searchTKOrganizationsSucceeded = ({data, page, search, totalPages}) => ({
  type: types.SEARCH_TK_ORGANIZATIONS_SUCCEEDED,
  payload: {
    data,
    page,
    search,
    totalPages
  }
});

export const searchTKOrganizationsFailed = () => ({
  type: types.SEARCH_TK_ORGANIZATIONS_FAILED
});

// ADMIN
// CREATE
export const createTKAdminOrganizationsRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_ORGANIZATIONS_REQUEST,
  payload: reqParams
});

export const setShowOrganizationsAddModal = show => ({
  type: types.SET_SHOW_ORGANIZATIONS_ADD_MODAL,
  payload: show
});

export const processTKAdminOrganizationsSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_ORGANIZATIONS_SUCCEEDED
});

export const processTKAdminOrganizationsFailed = () => ({
  type: types.PROCESS_TK_ADMIN_ORGANIZATIONS_FAILED
});

// DELETE
export const setShowOrganizationsDeleteModal = show => ({
  type: types.SET_SHOW_ORGANIZATIONS_DELETE_MODAL,
  payload: show
});

export const deleteTKAdminOrganizationsRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_ORGANIZATIONS_REQUEST,
  payload: reqParams
});
