import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import { 
    getTKAdminAPURequest, 
    createTKAdminAPURequest, 
    setShowAPUAddModal, 
    setShowAPUKeyModal,
    deleteTKAdminAPURequest, 
    setShowAPUDeleteModal,
    createTKAdminAPUToken
  } from "../../../../../store/actions/apiUsers";
import { apiUsersColumnConfig } from "../../../../../constants/columnsConfigurations";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKApiUserForm from "../../../../../components/AdminLayout/TKForm/TKApiUserForm/TKApiUserForm";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TKButton from "../../../../../components/TKButton/TKButton";


const TKApiUsers = () => {
  const initialFormData = {
    name:"",
    type: "",
    key:"",
    companyId: ""
  };
  const initialFormDataValidate = {
    name: {
      required: true
    },
    type:{
     required: true
    }
  };
  const dispatch = useDispatch();
  const {loading} = useSelector(state => state.tkns);
  const {list, token, processLoading, showAddModal, showApiKeyModal, showDeleteModal} = useSelector(state => state.apiUsers.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({...initialFormData});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({...initialFormDataValidate});

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterData = () => {
    dispatch(getTKAdminAPURequest({}));
  }

  const handleOnChange = (name, value) => {
    formDataErrors[name] && 
      setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }));
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({...errorMsgs});
      return false;
    }
    return true;
  }

  const handleSetShowAPUAddModal = show => {
    if (!show) {
      setFormData({...initialFormData});
      setFormDataErrors({});
    }
    dispatch(setShowAPUAddModal(show));
  }

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
        dispatch(createTKAdminAPURequest({
        data: {...formData},
        token,
        handleSetShowAPUAddModal
      }));
    }
  }

  

  const handleSetShowAPUDeleteModal = show => {
    dispatch(setShowAPUDeleteModal(show));
  }

  const handleShowDeleteModalClick = id => {
    setSelectedId(id);
    handleSetShowAPUDeleteModal(true);
  }

  const handleDeleteOnClick = () => {
    dispatch(deleteTKAdminAPURequest({
      selectedId
    }));
  }

  const handleOnKeyCreatorClick =() => {

    dispatch(createTKAdminAPUToken({company: formData.companyId}));

  }
  const handleShowKeyOnClick = row =>{

    setApiKey(list.find(apiUser => apiUser._id === row._id))
    
    handleSetShowAPUKeyModal(true);

  }

  const handleSetShowAPUKeyModal = (show) => {
    dispatch(setShowAPUKeyModal(show))
  }

  const apiUserColumns = apiUsersColumnConfig({
    onDelete: (id) => handleShowDeleteModalClick(id),
    onShowKey: (row) => handleShowKeyOnClick(row)
  });

  return (
    <>
      <TKAdminPageHeader
        header="Api Kullanıcı yönetimi"
      />
      <TKAddNewButton 
        showModal={showAddModal}
        setShowModal={handleSetShowAPUAddModal}
        btnTitle="Api Kullanıcı"
        modalHeader="Api Kullanıcı ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKApiUserForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          handleOnKeyCreatorClick={handleOnKeyCreatorClick}
          isCreate ={true}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && 
          <TKDataTable
            columns={apiUserColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            activePage={1}
            totalPages={1}
            handleFetchData={filterData}
            pagination={false}
          />
        }
        {loading && <TKLoader/>}
      </TKTableCard>
      <TKModal
        show={showApiKeyModal}
        setShow={handleSetShowAPUKeyModal}
        size="lg"
        header="Api Key"
        saveBtnTitle="Kapat"
        saveOnClick={(a) => handleSetShowAPUKeyModal(false)}
        processLoading={processLoading}
        cancelButtonHide={true}
      >
       {
         apiKey &&
         <CopyToClipboard text={apiKey.key}>
           <TKButton>
               Clipboarda kopyala
          </TKButton>
        </CopyToClipboard>
       }
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowAPUDeleteModal}
        header="Api Kullanıcı silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Api kullanıcısını silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKApiUsers;
