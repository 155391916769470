import * as types from '../actionTypes';

const initialState = {
    displayList: [],
    page: 0,
    hasMore: false,
    displayNews: {},
    loading: false,
    error: false,
    admin: {
      list: [],
      page: 0,
      totalPages: 0,
      processLoading: false,
      processError: false,
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false
    }
  }

  const tkns = (state = initialState, action) => {
    
    switch(action.type) {

      case types.GET_TK_TKN_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_TKN_ADMIN_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...action.payload.data]
        }
      }
    case types.GET_TK_TKN_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: true
    }
    case types.SET_SHOW_TKN_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showAddModal: action.payload
        }
    }  
    case types.SET_SHOW_TKN_EDIT_MODAL:
        return {
          ...state,
          admin: {
            ...state.admin,
            showEditModal: action.payload
          }
    }
    case types.SET_SHOW_TKN_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload
        }
    }  
    default:
          return state;
        }
    }

export default tkns;