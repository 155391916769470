import * as types from "../actionTypes";

// ADMIN
// LIST
export const getTKAdminLinksRequest = (reqParams) => ({
  type: types.GET_TK_ADMIN_LINKS_REQUEST,
  payload: reqParams,
});

export const getTKAdminLinksSucceeded = ({ data }) => ({
  type: types.GET_TK_ADMIN_LINKS_SUCCEEDED,
  payload: {
    data,
  },
});
export const getTKAdminLinksFailed = () => ({
  type: types.GET_TK_ADMIN_LINKS_FAILED,
});

// CREATE
export const createTKAdminLinksRequest = (reqParams) => ({
  type: types.CREATE_TK_ADMIN_LINKS_REQUEST,
  payload: reqParams,
});

// DELETE
export const deleteTKAdminLinksRequest = (reqParams) => ({
  type: types.DELETE_TK_ADMIN_LINKS_REQUEST,
  payload: reqParams,
});

// EDIT
export const editTKAdminLinksRequest = (reqParams) => ({
  type: types.EDIT_TK_ADMIN_LINKS_REQUEST,
  payload: reqParams,
});

export const processTKAdminLinksSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_LINKS_SUCCEEDED,
});

export const processTKAdminLinksFailed = () => ({
  type: types.PROCESS_TK_ADMIN_LINKS_FAILED,
});
