import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLinksForm from "../../../../../components/AdminLayout/TKForm/TKLinksForm/TKLinksForm";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import { linksColumnsConfig } from "../../../../../constants/columnsConfigurations";
import { validate } from "../../../../../helpers/validate";
import {
  createTKAdminLinksRequest,
  deleteTKAdminLinksRequest,
  getTKAdminLinksRequest,
} from "../../../../../store/actions/links";

const TKLinks = () => {
  const initialFormData = {
    title: "",
    link: "",
  };
  const initialFormDataValidate = {
    title: {
      required: true,
    },
    link: {
      required: true,
    },
  };
  const { loading } = useSelector((state) => state.links);

  const [show, setShow] = useState();
  const [showModal, setShowModal] = useState();
  const { list, processLoading } = useSelector((state) => state.links.admin);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [selectedId, setSelectedId] = useState(null);

  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });
  const [formDataErrors, setFormDataErrors] = useState({});

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    if (name !== "image") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        previousimage: null,
      }));
    }
  };
  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const linksColumns = linksColumnsConfig({
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = () => {
    dispatch(getTKAdminLinksRequest({}));
  };

  const dispatch = useDispatch();

  console.log(list);

  const handleSaveOnClick = () => {
    const check = checkValidation();

    if (check) {
      dispatch(
        createTKAdminLinksRequest({
          data: { ...formData },
        })
      );
    }
  };

  const handleDeleteOnClick = () => {
    dispatch(
      deleteTKAdminLinksRequest({
        selectedId,
      })
    );
  };

  useEffect(() => {
    if (processLoading) {
      setShow(false);
      setShowModal(false);
      setFormData({});
    }
  }, [processLoading]);

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  return (
    <>
      <TKAdminPageHeader header="Yararlı Linkler" />
      <TKAddNewButton
        showModal={show}
        setShowModal={setShow}
        btnTitle="Site"
        modalHeader="Site Ekle"
        handleSaveOnClick={handleSaveOnClick}
      >
        <TKLinksForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKAddNewButton>
      <TKTableCard>
        <TKDataTable
          columns={linksColumns}
          data={list}
          pagination={false}
        ></TKDataTable>
      </TKTableCard>
      <TKModal
        show={showModal}
        setShow={setShowModal}
        header="link silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
      >
        Linki silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKLinks;
