import * as types from '../actionTypes';

// ADMIN
// LIST
export const getTKAdminAPURequest = reqParams => ({
  type: types.GET_TK_ADMIN_APU_REQUEST,
  payload: reqParams
});

export const getTKAdminAPUSucceeded = ({data, page, totalPages}) => ({
  type: types.GET_TK_ADMIN_APU_SUCCEEDED,
  payload: {
    data,
    page,
    totalPages
  }
});

export const getTKAdminAPUFailed = () => ({
  type: types.GET_TK_ADMIN_AU_FAILED
});

// CREATE
export const createTKAdminAPURequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_APU_REQUEST,
  payload: reqParams
});

export const createTKAdminAPUToken = (reqParams) => ({
  type: types.CREATE_TK_ADMIN_APU_REQUEST_TOKEN,
  payload: reqParams
});

export const createTKAdminAPUTokenSucceed = ({token}) => ({
  type: types.CREATE_TK_ADMIN_APU_REQUEST_TOKEN_SUCCEED,
  payload: {
    token
  }
});

export const setShowAPUAddModal = show => ({
  type: types.SET_SHOW_APU_ADD_MODAL,
  payload: show
});

// EDIT
export const editTKAdminAPURequest = reqParams => ({
  type: types.EDIT_TK_ADMIN_APU_REQUEST,
  payload: reqParams
});

export const setShowAPUKeyModal = show => ({
  type: types.SET_SHOW_APU_KEY_MODAL,
  payload: show
});


// DELETE
export const deleteTKAdminAPURequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_APU_REQUEST,
  payload: reqParams
});

export const setShowAPUDeleteModal = show => ({
  type: types.SET_SHOW_APU_DELETE_MODAL,
  payload: show
});

export const processTKAdminAPUSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_APU_SUCCEEDED
});

export const processTKAdminAPUFailed = () => ({
  type: types.PROCESS_TK_ADMIN_APU_FAILED
});
