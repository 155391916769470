import * as types from "../actionTypes";
import { convertBannerType } from "../../constants/general";

const initialState = {
  displayList: [],
  page: 0,
  hasMore: false,
  loading: false,
  error: false,
  admin: {
    list: [],
    contentlist: [],
    // page: 0,
    // totalPages: 0,
    processLoading: false,
    processError: false,
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false,
  },
};

const links = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TK_ADMIN_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_TK_ADMIN_LINKS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...action.payload.data],
        },
      };
    case types.CREATE_TK_ADMIN_LINKS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false,
        },
      };
    case types.PROCESS_TK_ADMIN_LINKS_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false,
        },
      };
    case types.PROCESS_TK_ADMIN_LINKS_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: true,
        },
      };
    case types.DELETE_TK_ADMIN_LINKS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false,
        },
      };
    case types.EDIT_TK_ADMIN_LINKS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false,
        },
      };
    default:
      return state;
  }
};

export default links;
