import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TKLoadMoneyForm.scss';
import { Form, Row, Col } from 'react-bootstrap';
import TKFileInput from '../../../../TKInputs/TKFileInput/TKFileInput';
import { cardHoldersTypeList } from '../../../../../../constants/general';
import { checkOnChangeDecimal } from '../../../../../../helpers/validate';
import TKSelect from '../../../../../TKSelect/TKSelect';
import { 
  getTKOrganizationsRequest
} from "../../../../../../store/actions/organizations";

const TKLoadMoneyForm = ({formData, formDataErrors, onChange}) => {
  const ORGANIZATION = "ORGANIZATION";
  const TCKN_FILE = "TCKN_FILE";
 
  const dispatch = useDispatch();
  const {listOrganization} = useSelector(state => state.organizations);
  const [filteredListOrganization,filteredSetListOrganization] = useState(listOrganization);

  const [selectSearch,setSelectSearch] = useState('');

  
  
  useEffect(() => {
    dispatch(getTKOrganizationsRequest())
  }, [dispatch])

  const handleAmountOnChange = e => {
    let valid = true;
    let amountValue = e.target.value.toString();
    amountValue = amountValue.replace(',','.');

    if (amountValue && formData.loadType === ORGANIZATION) {
      valid = checkOnChangeDecimal(amountValue);
    }
    return valid && onChange(e.target.name, amountValue);
  }

  const handleExplanationOnChange = e => {
    
    onChange(e.target.name, e.target.value)

  }


  const onSelectChange = (name,value) => {


    if (value == 'Backspace') {

      filteredSetListOrganization(listOrganization)

      setSelectSearch('')

    }
    else if(value == 'Capslock' || value == 'ArrowDown' || value == 'ArrowUp' || value == 'ArrowLeft' || value == 'ArrowRight') {


    }
    else if (name === "organization") {

      filteredSetListOrganization(listOrganization.filter(function(item) {
      
        if (item.name.toLowerCase().startsWith((selectSearch +value).toLowerCase())) {
 
 
         setSelectSearch(selectSearch + value)
 
         return item
 
        }
      }))

    }
    
  }
 
  

  return (
    <Form className="tk_load-money-form">
      <Form.Group className="mb-0">
        <Form.Check
          inline
          className="mb-2"
          type="radio"
          id="deposit-organization"
          label="Organizasyon İle Toplu Yükleme"
          name="loadType"
          value={ORGANIZATION}
          defaultChecked={formData?.loadType === ORGANIZATION}
          onChange={e => { 
            onChange(e.target.name, e.target.value);
            onChange('excel', null);
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Check
          inline
          type="radio"
          id="deposit-tckn"
          label="TCKN İle Toplu Yükleme"
          name="loadType"
          value={TCKN_FILE}
          defaultChecked={formData?.loadType === TCKN_FILE}
          onChange={e => { 
            onChange(e.target.name, e.target.value);
            onChange('type', null);
          }}
        />
        <Form.Text className="text-danger">{formDataErrors?.loadType}</Form.Text>
      </Form.Group>
      <Form.Group>

      </Form.Group>
      <Row>
        <Col>
          {formData?.loadType === ORGANIZATION && (
            <Form.Group>
              <TKSelect
                defaultTitle="Organizasyon Seç"
                selectedItem={formData.type}
                itemSelector="name"
                items={filteredListOrganization}
                itemOnClick={(id) => onChange("type", id)}
                isInvalid={formDataErrors.type}
                onChange={(value) => onSelectChange('organization',value)}
                name="organization"
                filter={true}
                search={selectSearch}
                />
              <Form.Text className="text-danger">{formDataErrors?.type}</Form.Text>
            </Form.Group>
          )}
          {formData?.loadType === TCKN_FILE && (
            <Form.Group>
              <TKFileInput
                selectedFile={formData.excel}
                onChange={(file) => onChange('excel', file)}
                className={formDataErrors?.excel ? "tk_file-input-error" : ""}
                fileType="spreadsheet"
              />
              <Form.Text className="text-danger">{formDataErrors?.excel}</Form.Text>
            </Form.Group>
          )}
        </Col>
        <Col sm={7}>
          {
            formData.loadType === ORGANIZATION &&
            <Form.Group>
            <Form.Control
              type="text"
              name="amount"
              placeholder={`Yükleme Yapılacak Miktar`}
              value={formData.amount || ""}
              onChange={handleAmountOnChange}
              isInvalid={formDataErrors.amount}
            />
            <Form.Text className="text-danger">{formDataErrors?.amount}</Form.Text>
          </Form.Group>
          }
          </Col>
      </Row>
      {
       formData.loadType === TCKN_FILE &&
       <Form.Text className={"text-muted mb-3 "} >
          TCKN leri ve Yüklenecek miktarları excel dosyasına, ilk iki sütuna ve başlıksız bir şekilde giriniz.
      </Form.Text>  
      }
      <Form.Group>
            <Form.Control
              type="text"
              name="explanation"
              placeholder={`Açıklama Giriniz`}
              value={formData.explanation || ""}
              onChange={handleExplanationOnChange}
              isInvalid={formDataErrors.explanation}
            />
            <Form.Text className="text-danger">{formDataErrors?.explanation}</Form.Text>
      </Form.Group>
    
    </Form>
  );
};

export default TKLoadMoneyForm;

TKLoadMoneyForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {},
}