import React, { useEffect } from "react";
import "./TKNavbar.scss";
import { Dropdown, DropdownButton, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import TKLogo from "../TKLogo/TKLogo";
import TKButton from "../TKButton/TKButton";
import TKLoginContainer from "../TKLoginContainer/TKLoginContainer";
import TKIcon from "../TKIcon/TKIcon";
import instagram from "../../assets/images/instagram.png";
import { getTKAdminLinksRequest } from "../../store/actions/links";
import { useDispatch, useSelector } from "react-redux";

const TKNavbar = () => {
  const dispatch = useDispatch();

  const fakedata = [
    {
      title: "e-kütüphane",
      url: "https://google.com",
    },
    {
      title: "kariyer merkezi",
      url: "https://google.com",
    },
    {
      title: "Kurum Web",
      url: "https://google.com",
    },
  ];
  const { list } = useSelector((state) => state.links.admin);

  useEffect(() => {
    dispatch(getTKAdminLinksRequest());
  }, []);

  return (
    <nav>
      <div className="tk_navbar-topnavbar">
        <div className="container">
          <div className="tk_navbar-topsociallinks">
            <ul>
              <li>
                <a
                  className=""
                  href="https://www.facebook.com/tuzlakartcom"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TKIcon name="facebook" size="16" color="#db6314" />
                </a>
              </li>
              <li>
                <a
                  className=""
                  href="https://twitter.com/tuzlakart"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TKIcon name="twitter" size="16" color="#db6314" />
                </a>
              </li>
              <li>
                <a
                  className=""
                  href="https://www.instagram.com/tuzlakart/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TKIcon name="instagram" size="16" color="#db6314" />
                </a>
              </li>
            </ul>
          </div>
          <div className="tk_navbar-buttons">
            <TKLoginContainer />
          </div>
        </div>
      </div>
      <Navbar
        collapseOnSelect
        expand="xl"
        bg="primary"
        className="tk_navbar"
        variant="dark"
      >
        <div className="container">
          <Link exact="true" to="/" className="tk_brand">
            <TKLogo />
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <div className="tk_navbar-links-wrapper">
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="align-items-center mx-2">
                {/* <NavLink exact to="/" activeClassName="tk_nav-link-active">
              anasayfa
            </NavLink> */}

                <NavLink
                  to="/tuzla-kart-nedir"
                  activeClassName="tk_nav-link-active"
                >
                  tuzla Kart nedir
                </NavLink>
                <NavLink
                  to="/genc-kart-nedir"
                  activeClassName="tk_nav-link-active"
                >
                  genç kart nedir
                </NavLink>
                <NavLink
                  to="/uye-firmalar"
                  activeClassName="tk_nav-link-active"
                >
                  üyeler
                </NavLink>
                <NavLink to="/kampanyalar" activeClassName="tk_nav-link-active">
                  kampanyalar
                </NavLink>

                <NavLink to="/haberler" activeClassName="tk_nav-link-active">
                  haberler
                </NavLink>
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Yararlı Linkler"
                  className="navbar-dropdown"
                >
                  {list.map((index, key) => (
                    <Dropdown.Item href={index.link} target="_blank">
                      {index.title}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                <NavLink to="/faq" activeClassName="tk_nav-link-active">
                  S.S.S
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </nav>
  );
};

export default TKNavbar;
