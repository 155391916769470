import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { complainColumnsConfig } from "../../../../../constants/columnsConfigurations";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import { 
    getTKComplainsRequest,
    setShowComplainViewModal,
    seenTKComplainRequest
  } from "../../../../../store/actions/complain";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKComplainForm from "../../../../../components/AdminLayout/TKForm/TKComplainForm/TKComplainForm";

const TKComplains = () => {

  const initialFormData = {
     title: "",
     sender: "",
     created_at: null,
     seen_at: null
   };


    const dispatch = useDispatch();
    const [formData, setFormData] = useState({...initialFormData});
    const {list, page, totalPages, processLoading, showViewModal} = useSelector(state => state.complains.admin);
    const [sortedColumn, setSortedColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("desc");
    const {loading} = useSelector(state => state.complains);
    const [selectedId, setSelectedId] = useState(null);


    useEffect(() => {

        filterData(page,'');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterData = (page,search) => {
        dispatch(getTKComplainsRequest({
          page,
          search
        }));
      }


    const handleShowViewModalClick = row => {

      const {_id, title, sender,content,created_at,seen_at} = row;
      setSelectedId(_id);
      setFormData({
        title,
        sender,
        content,
        created_at,
        seen_at
      });
      handleSetShowComplainViewModal(true)
    
    }


    const handleSetShowComplainViewModal = show => {
      dispatch(setShowComplainViewModal(show));
    }


    const handleViewOnClick = () => {
      if (formData.seen_at === undefined) {
        dispatch(seenTKComplainRequest({
          selectedId
        }));
        handleSetShowComplainViewModal(false)
      } else {
        handleSetShowComplainViewModal(false)
      }
    }


    const complainColumns = complainColumnsConfig({
      onView: (row) => handleShowViewModalClick(row)
    });

    return (
        <>
         <TKAdminPageHeader
        header="Şikayet Yönetimi"
        />
        <TKTableCard>
         {!loading && 
            <TKDataTable
            columns={complainColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            activePage={page}
            totalPages={totalPages}
            handleFetchData={filterData}
            pagination={false}
          />
        }
            {loading && <TKLoader/>}
          </TKTableCard>
          <TKModal
            show={showViewModal}
            setShow={handleSetShowComplainViewModal}
            header="Şikayet Detay Formu"
            saveBtnTitle={formData.seen_at === undefined ? "Görüldü": "Kapat"}
            saveOnClick={handleViewOnClick}
            processLoading={processLoading}
            cancelButtonHide={true}
           >
            <TKComplainForm
              formData={formData}
            />
           </TKModal>
        </>
    )    
}

export default TKComplains;
