import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './TKBalance.scss';
import { checkOnChangeDecimal } from '../../../helpers/validate';
import { loadMoneyTKAdminCardRequest } from '../../../store/actions/cardHolders';
import { useDispatch, useSelector } from "react-redux";

const TKBalance = ({user, id, candeposit , ...props}) => {
  const dispatch = useDispatch();
  const {page, searchFormData} = useSelector(state => state.cardHolders.admin);
  const [amount, setAmount] = useState(null);
  const [amountError, setAmountError] = useState(null);
  const [explanation, setExplanation] = useState(null);
  const [explanationError, setExplanationError] = useState(null);
  const [updatedUserBalance, setUpdatedUserBalance] = useState(user.balance);


  const handleAmountOnChange = e => {
    let valid = true;
    let amountValue = e.target.value.toString();
    amountValue = amountValue.replace(',','.');
    if (e.target.value) {
      valid = checkOnChangeDecimal(amountValue);
    }
    if (valid) {
      setAmount(amountValue);
      setAmountError(null);
    }
  }

  const handleExplanationOnChange = e => {
    
      setExplanation(e.target.value)
      setExplanationError(null)
  
  }

  const deposit = () => {
    if(!amount || +amount === 0 && explanation === null) {
      setExplanationError("Gereklidir")
      setAmountError("0'dan büyük olmalı");
      return;
    }
    if (explanation === null || explanation === ''){
      setExplanationError("Gereklidir")
      return;
    }
    if (!amount || +amount === 0) {
      setAmountError("0'dan büyük olmalı");
      return;
    }
    else {
      dispatch(loadMoneyTKAdminCardRequest({
        data: {
          loadType: "TCKN",
          tckn: user.tckn,
          amount,
          explanation
        },
        page, 
        searchFormData,
        handleSetShowLoadMoneyModal: () => {}
      }));

      setUpdatedUserBalance(parseInt(updatedUserBalance)+parseInt(amount))

    }
  }

  return (
    <Container className="tk_balance-modal">
      <Row>
        <Col>
          <div>İsim Soyisim</div>
          <div className="tk_balance-modal-name">{`${user?.name} ${user?.surname}`}</div>
        </Col>
        <Col>
          <div>Bakiye</div>
          <div className="tk_balance-modal-balance">{`${updatedUserBalance} TL`}</div>
        </Col>
      </Row>
      <Row>
        <Col>
        { candeposit.includes('balance') &&
          <div className="d-flex flex-row mt-4 mb-2 tk_balance-modal-load-money">
            <div className="tk_balance-modal-load-money-input">
              <Form.Control
                type="text"
                name="amount"
                placeholder={`Yükleme Yapılacak Miktar`}
                value={amount || ""}
                onChange={handleAmountOnChange}
                isInvalid={amountError}
              />
            <Form.Text className="text-danger">{amountError}</Form.Text>
              <Form.Control
              type="text"
              name="explanation"
              placeholder={`Açıklama Giriniz`}
              value={explanation || ""}
              onChange={handleExplanationOnChange}
              isInvalid={explanationError}
            />
            <Form.Text className="text-danger">{explanationError}</Form.Text>
            </div>
            <div className="tk_balance-modal-load-money-button">
              <Button onClick={deposit}>Bakiye Yükle</Button>
            </div>
          </div>
        }
        </Col>
      </Row>
    </Container>
  );
}

export default TKBalance;

TKBalance.defaultProps = {
  user:  {},
  id: '',
}