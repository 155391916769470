import React, { useEffect } from 'react';
import './PublicLayout.scss';
import TKNavbar from '../../../components/TKNavbar/TKNavbar';
import TKFooter from '../../../components/TKFooter/TKFooter';
import TKContainerFooter from '../../../components/TKContainerFooter/TKContainerFooter';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TKBannerSlider from '../../../components/TKBannerSlider/TKBannerSlider';

const PublicLayout = ({ children }) => {
  const { path } = useLocation();
  const { homeBannersList } = useSelector((state) => state.homepage);

  const bannerList = homeBannersList.slice(0, 5);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  return (
    <div style={{ width: '100%' }}>
      <TKNavbar />
      {children.props.location !== undefined &&
        children.props.location.pathname === '/' && (
          <TKBannerSlider banners={homeBannersList} />
        )}
      <div>
        <div class="row">
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col">{children}</div>
        </div>
      </div>
      <div>
        <TKContainerFooter />
        <TKFooter />
      </div>
    </div>
  );
};

export default PublicLayout;
