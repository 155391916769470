import React from "react";
import "./TKFilterDropdown.scss";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import TKCustomToggle from "../TKSelect/TKCustomToggle";
import TKCheckbox from "../TKCheckbox/TKCheckbox";
import TKCustomDropdownMenu from "../TKSelect/TKCustomDropDownMenu";
import TKCustomFilterDropdownMenu from "./TKCustomFilterDropdownMenu";

const TKFilterDropdown = ({
  variant,
  id,
  title,
  items,
  itemSelector,
  selectedItems,
  itemOnClick,
  isInvalid,
  filter,
  onChange,
  search,
  show,
}) => {
  const selectedOrganizationNames = items
    .filter((item, i) => selectedItems.includes(item._id))
    .map((item, i) => `${item[itemSelector]}`)
    .join(", ");
  return (
    <Dropdown
      className={`tk_dropdown ${variant ? `tk_dropdown-${variant}` : ""} ${
        isInvalid ? "tk_dropdown-invalid" : ""
      }`.trim()}
    >
      
      <Dropdown.Toggle as={TKCustomToggle} id={id}>
        {!selectedItems.length ? (
          ""
        ) : (
          <OverlayTrigger
            placement="right"
            overlay={
              <Popover style={{ zIndex: "10000000" }}>
                <Popover.Content>
                  <div className="text-muted small p-1">
                    {selectedOrganizationNames}
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <span className={`mr-1 my-auto py-1 badge badge-pill`}>
              {selectedItems.length}
            </span>
          </OverlayTrigger>
        )}
        {selectedItems.length ? selectedOrganizationNames : title}
      </Dropdown.Toggle>
      {filter &&
      <Dropdown.Menu as={TKCustomFilterDropdownMenu} search={search} show={show} items={items} selectedOrganizationNames={selectedOrganizationNames} onChange={(name,value) => onChange(name,value) } selectedItems={selectedItems} itemSelector={itemSelector} itemOnClick={(id)=>{itemOnClick(id)}}>
      </Dropdown.Menu>
      }
      {!filter && (
        <Dropdown.Menu>
        {items.map(
          (item, i) =>
            selectedItems.includes(item._id) && (
              <div key={i} className="tk_dropdown-item">
                <TKCheckbox
                  label={item[itemSelector]}
                  value={item._id}
                  checked={true}
                  onChange={() => itemOnClick(item._id)}
                />
              </div>
            )
        )}
        {Boolean(selectedItems.length) && <hr className="my-1" />}
        {items.map(
          (item, i) =>
            !selectedItems.includes(item._id) && (
              <div key={i} className="tk_dropdown-item">
                <TKCheckbox
                  label={item[itemSelector]}
                  value={item._id}
                  checked={false}
                  onChange={() => itemOnClick(item._id)}
                />
              </div>
            )
        )}
      </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default TKFilterDropdown;

TKFilterDropdown.defaultProps = {
  variant: "",
  id: "tk-dropdown",
  title: "",
  items: [],
  itemSelector: "",
  selectedItems: [],
  itemOnClick: () => {},
  isInvalid: false,
};
