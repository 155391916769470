import React, { useEffect, useState } from "react";
import "./TKStatusContainer.scss";
import { Col, Form, Row } from "react-bootstrap";
import TKStatusCard from "./TKStatusCard/TKStatusCard";
import { cardStatus } from "../../constants/general";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import Spinner from "react-bootstrap/Spinner";
import TKExcelExportButton from "../AdminLayout/TKExcelExportButton/TKExcelExportButton";
import {
  getTKAdminReportsActiveCompaniesRequest,
  getTKAdminReportsActiveUsersRequest,
  getTKAdminReportsGaziKartRequest,
  getTKAdminReportsGencKartRequest,
  getTKAdminReportsMostCampaignsRequest,
  getTKAdminReportsTkartRequest,
} from "../../store/actions/reports";
import { useDispatch } from "react-redux";
import TKDatePicker from "../AdminLayout/TKInputs/TKDatePicker/TKDatePicker";
import TKReportDatePicker from "../AdminLayout/TKForm/TKReportDatePickerForm/TKReportDatePicker";
import TKButton from "../TKButton/TKButton";
import moment from "moment";

Chart.register(CategoryScale);

const TKStatusContainer = ({
  isDashboard,
  homeStatus,
  dashboardStatus,
  dashboardStatusLoading,
  tkartList,
  genckartList,
  gazikartList,
  reportsLoading,
}) => {
  let statusInfo;
  let campaignStatusInfo;
  let newsStatusInfo;
  let cardInfo;

  const [tumKartlarChartData, setTumKartlarChartData] = useState({
    labels: cardInfo?.map((data) => data.title),
    datasets: [
      {
        label: "Kart Tipine Göre Dağılım",
        data: cardInfo?.map((data) => data.amount),
        backgroundColor: [
          "#cdb4db",
          "#ffc8dd",
          "#ffafcc",
          "#bde0fe",
          "#a2d2ff",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const initialDatePickerFormData = {
    start_at: "",
    finish_at: "",
  };

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const [formDatePickerData, setFormDatePickerData] = useState({
    ...initialDatePickerFormData,
  });

  const [showSpecChart, setShowSpecChart] = useState(false);

  const handleDatePickerOnChange = (name, value) => {
    setFormDatePickerData((prevState) => ({
      ...prevState,
      [name]: moment(value).utc().format("M-D-Y"),
    }));
  };

  const dispatch = useDispatch();
  const [dailyChartData, setDailyChartData] = useState({
    //labels: tkartInfo?.map((data) => data.title),
    datasets: [
      {
        label: "Kart Tipine Göre Dağılım",
        data: [1, 2, 3],
        backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const [weeklyChartData, setWeeklyChartData] = useState({
    //labels: tkartInfo?.map((data) => data.title),
    datasets: [
      {
        label: "Kart Tipine Göre Dağılım",
        data: [1, 2, 3],
        backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const [monthlyChartData, setMonthlyChartData] = useState({
    //labels: tkartInfo?.map((data) => data.title),
    datasets: [
      {
        label: "Kart Tipine Göre Dağılım",
        data: [1, 2, 3],
        backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const [yearlyChartData, setYearlyChartData] = useState({
    //labels: tkartInfo?.map((data) => data.title),
    datasets: [
      {
        label: "Kart Tipine Göre Dağılım",
        data: [1, 2, 3],
        backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const [specDatesChartData, setSpecDatesChartData] = useState({
    //labels: tkartInfo?.map((data) => data.title),
    datasets: [
      {
        label: "Kart Tipine Göre Dağılım",
        data: [1, 2, 3],
        backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });

  const handleSubmit = () => {
    dispatch(getTKAdminReportsTkartRequest(formDatePickerData));
    dispatch(getTKAdminReportsGencKartRequest(formDatePickerData));
    dispatch(getTKAdminReportsGaziKartRequest(formDatePickerData));
    setShowSpecChart(true);
  };

  useEffect(() => {
    setTumKartlarChartData({
      labels: cardInfo?.map((data) => data.title),
      datasets: [
        {
          label: "Kart Tipine Göre Dağılım",
          data: cardInfo?.map((data) => data.amount),
          backgroundColor: [
            "#cdb4db",
            "#ffc8dd",
            "#ffafcc",
            "#bde0fe",
            "#a2d2ff",
          ],
          borderColor: "black",
          borderWidth: 2,
        },
      ],
    });
  }, [dashboardStatus]);

  useEffect(() => {
    setDailyChartData({
      labels: ["TUZLAKART", "GENÇKART", "GAZİKART"],
      datasets: [
        {
          label: "Kart Tipine Göre Günlük Eklenenler",
          data: [
            tkartList?.tuzlaCardDaily,
            genckartList?.gencCardDaily,
            gazikartList?.gaziCardDaily,
          ],
          backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
          borderColor: "black",
          borderWidth: 2,
        },
      ],
    });
  }, [tkartList, genckartList, gazikartList]);

  useEffect(() => {
    setSpecDatesChartData({
      labels: ["TUZLAKART", "GENÇKART", "GAZİKART"],
      datasets: [
        {
          label: "Kart Tipine Göre Haftalık Eklenenler",
          data: [
            tkartList?.tuzlaCardSpecDate,
            genckartList?.gencCardSpecDate,
            gazikartList?.gaziCardSpecDates,
          ],
          backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
          borderColor: "black",
          borderWidth: 2,
        },
      ],
    });
  }, [tkartList, genckartList, gazikartList]);

  useEffect(() => {
    setWeeklyChartData({
      labels: ["TUZLAKART", "GENÇKART", "GAZİKART"],
      datasets: [
        {
          label: "Kart Tipine Göre Haftalık Eklenenler",
          data: [
            tkartList?.tuzlaCardWeekly,
            genckartList?.gencCardWeekly,
            gazikartList?.gaziCardWeekly,
          ],
          backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
          borderColor: "black",
          borderWidth: 2,
        },
      ],
    });
  }, [tkartList, genckartList, gazikartList]);

  useEffect(() => {
    setMonthlyChartData({
      labels: ["TUZLAKART", "GENÇKART", "GAZİKART"],
      datasets: [
        {
          label: "Kart Tipine Göre Aylık Eklenenler",
          data: [
            tkartList?.tuzlaCardMonthly,
            genckartList?.gencCardMonthly,
            gazikartList?.gaziCardMonthly,
          ],
          backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
          borderColor: "black",
          borderWidth: 2,
        },
      ],
    });
  }, [tkartList, genckartList, gazikartList]);

  const handleExportActiveUsers = () => {
    dispatch(getTKAdminReportsActiveUsersRequest());
  };

  const handleExportActiveCompanies = () => {
    dispatch(getTKAdminReportsActiveCompaniesRequest());
  };

  const handleExportMostCampaigns = () => {
    dispatch(getTKAdminReportsMostCampaignsRequest());
  };

  useEffect(() => {
    setYearlyChartData({
      labels: ["TUZLAKART", "GENÇKART", "GAZİKART"],
      datasets: [
        {
          label: "Kart Tipine Göre Yıllık Eklenenler",
          data: [
            tkartList?.tuzlaCardYearly,
            genckartList?.gencCardYearly,
            gazikartList?.gaziCardYearly,
          ],
          backgroundColor: ["#cdb4db", "#ffc8dd", "#ffafcc"],
          borderColor: "black",
          borderWidth: 2,
        },
      ],
    });
  }, [tkartList, genckartList, gazikartList]);

  if (!isDashboard) {
    statusInfo = [
      {
        iconName: "store",
        title: "üye işyeri",
        amount: homeStatus.companyCount,
      },
      {
        iconName: "credit-card",
        title: "tuzla kartlılar",
        amount: homeStatus.userCount,
      },
      {
        iconName: "verified",
        title: "aylık işlem",
        amount: homeStatus.transactionsCount,
      },
    ];
  } else {
    statusInfo = [
      {
        iconName: "credit-card",
        title: "bugün eklenenler",
        amount: dashboardStatus.userDailyAdded,
      },
      {
        iconName: "credit-card",
        title: "son bir haftada eklenenler",
        amount: dashboardStatus.userWeeklyAdded,
      },
      {
        iconName: "credit-card",
        title: "son bir ayda eklenenler",
        amount: dashboardStatus.userMonthlyAdded,
      },
    ];
    if (dashboardStatus?.activeCardsCount) {
      cardInfo = [
        {
          title: dashboardStatus?.activeCardsCount[0]?._id[0],
          amount: dashboardStatus?.activeCardsCount[0]?.count,
        },
        {
          title: dashboardStatus?.activeCardsCount[1]?._id[0],
          amount: dashboardStatus?.activeCardsCount[1]?.count,
        },
        {
          title: dashboardStatus?.activeCardsCount[2]?._id[0],
          amount: dashboardStatus?.activeCardsCount[2]?.count,
        },
        {
          title: dashboardStatus?.activeCardsCount[3]?._id[0],
          amount: dashboardStatus?.activeCardsCount[3]?.count,
        },

        {
          title: dashboardStatus?.activeCardsCount[4]?._id[0],
          amount: dashboardStatus?.activeCardsCount[4]?.count,
        },
      ];
    }

    newsStatusInfo = [
      {
        iconName: "newspaper",
        title: "bugün eklenenler",
        amount: dashboardStatus.newsDailyAdded,
      },
      {
        iconName: "newspaper",
        title: "son bir haftada eklenenler",
        amount: dashboardStatus.newsWeeklyAdded,
      },
      {
        iconName: "newspaper",
        title: "son bir ayda eklenenler",
        amount: dashboardStatus.newsMonthlyAdded,
      },
    ];

    campaignStatusInfo = [
      {
        iconName: "restaurant",
        title: "bugün eklenenler",
        amount: dashboardStatus.campaignDailyAdded,
      },
      {
        iconName: "restaurant",
        title: "son bir haftada eklenenler",
        amount: dashboardStatus.campaignWeeklyAdded,
      },
      {
        iconName: "restaurant",
        title: "son bir ayda eklenenler",
        amount: dashboardStatus.campaignMonthlyAdded,
      },
    ];
  }
  return (
    <>
      {isDashboard && (
        <div style={{ marginTop: "20px", fontSize: "25px", color: "black" }}>
          TÜM KARTLAR
        </div>
      )}
      {isDashboard &&
        (dashboardStatusLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : (
          <Row>
            <Col>
              <Pie
                data={tumKartlarChartData}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Kart Tipine Göre Dağılım",
                    },
                  },
                }}
              />
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {cardInfo?.map((sI, index) => (
                <TKStatusCard
                  key={index}
                  title={sI.title}
                  amount={sI.amount}
                  admin={isDashboard}
                  block={"block"}
                />
              ))}
            </Col>
          </Row>
        ))}

      {isDashboard && (
        <>
          <hr></hr>
          <div style={{ marginTop: "20px", fontSize: "25px", color: "black" }}>
            TARİHLERE GÖRE EKLENEN KARTLAR
          </div>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "32px",
              marginBottom: "32px",
            }}
          >
            <Col lg={3}>
              {reportsLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : tkartList?.tuzlaCardDaily +
                  genckartList?.gencCardDaily +
                  gazikartList?.gaziCardDaily ==
                0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Bugün Yeni Eklenen Kart Yok
                </div>
              ) : (
                <>
                  <Doughnut
                    data={dailyChartData}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: "Kart Tipine Göre Günlük Eklenenler",
                        },
                      },
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <h6> {"TUZLAKART: " + tkartList?.tuzlaCardDaily}</h6>
                    <h6>{"GENÇKART: " + genckartList?.gencCardDaily}</h6>
                    <h6>{"GAZİKART: " + gazikartList?.gaziCardDaily}</h6>
                  </div>
                </>
              )}
            </Col>
            <Col lg={3}>
              {reportsLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : tkartList?.tuzlaCardWeekly +
                  genckartList?.gencCardWeekly +
                  gazikartList?.gaziCardWeekly ==
                0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Bu Hafta Yeni Eklenen Kart Yok
                </div>
              ) : (
                <>
                  <Doughnut
                    data={weeklyChartData}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: "Kart Tipine Göre Haftalık Eklenenler",
                        },
                      },
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <h6>
                      {" "}
                      {"TUZLAKART: " +
                        numberWithCommas(tkartList?.tuzlaCardWeekly)}
                    </h6>
                    <h6>
                      {"GENÇKART: " +
                        numberWithCommas(genckartList?.gencCardWeekly)}
                    </h6>
                    <h6>
                      {"GAZİKART: " +
                        numberWithCommas(gazikartList?.gaziCardWeekly)}
                    </h6>
                  </div>
                </>
              )}
            </Col>
            <Col lg={3}>
              {reportsLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : tkartList?.tuzlaCardMonthly +
                  genckartList?.gencCardMonthly +
                  gazikartList?.gaziCardMonthly ==
                0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Bu Ay Yeni Eklenen Kart Yok
                </div>
              ) : (
                <>
                  <Doughnut
                    data={monthlyChartData}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: "Kart Tipine Göre Aylık Eklenenler",
                        },
                      },
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <h6>
                      {" "}
                      {"TUZLAKART: " +
                        numberWithCommas(tkartList?.tuzlaCardMonthly)}
                    </h6>
                    <h6>
                      {"GENÇKART: " +
                        numberWithCommas(genckartList?.gencCardMonthly)}
                    </h6>
                    <h6>
                      {"GAZİKART: " +
                        numberWithCommas(gazikartList?.gaziCardMonthly)}
                    </h6>
                  </div>
                </>
              )}
            </Col>
            <Col lg={3}>
              {reportsLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : tkartList?.tuzlaCardYearly +
                  genckartList?.gencCardYearly +
                  gazikartList?.gaziCardYearly ==
                0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  Bu Yıl Yeni Eklenen Kart Yok
                </div>
              ) : (
                <>
                  <Doughnut
                    data={yearlyChartData}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: "Kart Tipine Göre Yıllık Eklenenler",
                        },
                      },
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "8px",
                      flexDirection: "column",
                    }}
                  >
                    <h6>
                      {" "}
                      {"TUZLAKART: " +
                        numberWithCommas(tkartList?.tuzlaCardYearly)}
                    </h6>
                    <h6>
                      {"GENÇKART: " +
                        numberWithCommas(genckartList?.gencCardYearly)}
                    </h6>
                    <h6>
                      {"GAZİKART: " +
                        numberWithCommas(gazikartList?.gaziCardYearly)}
                    </h6>
                  </div>
                </>
              )}
            </Col>
          </Row>
          <div style={{ marginTop: "20px", fontSize: "25px", color: "black" }}>
            İSTENİLEN TARİHLERE GÖRE EKLENEN KARTLAR
          </div>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "32px",
              marginBottom: "32px",
            }}
          >
            <Col lg={6}>
              {reportsLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <>
                  <TKReportDatePicker
                    formDatePickerData={formDatePickerData}
                    onChange={handleDatePickerOnChange}
                  ></TKReportDatePicker>
                  <TKButton variant="dark" onClick={handleSubmit}>
                    Raporu Getir
                  </TKButton>
                </>
              )}
            </Col>
            <Col lg={6}>
              {showSpecChart &&
                (reportsLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                ) : tkartList?.tuzlaCardSpecDate +
                    genckartList?.gencCardSpecDate +
                    gazikartList?.gaziCardSpecDates ==
                  0 ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Bu Dönemde Yeni Eklenen Kart Yok
                  </div>
                ) : (
                  <>
                    <Doughnut
                      data={specDatesChartData}
                      options={{
                        plugins: {
                          title: {
                            display: true,
                            text: "Kart Tipine Göre Dönemlik Eklenenler",
                          },
                        },
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "8px",
                        flexDirection: "column",
                      }}
                    >
                      <h6> {"TUZLAKART: " + tkartList?.tuzlaCardSpecDate}</h6>
                      <h6>{"GENÇKART: " + genckartList?.gencCardSpecDate}</h6>
                      <h6>{"GAZİKART: " + gazikartList?.gaziCardSpecDates}</h6>
                    </div>
                  </>
                ))}
            </Col>
          </Row>
        </>
      )}
      {isDashboard && (
        <div style={{ marginTop: "20px", fontSize: "25px", color: "black" }}>
          KULLANICILAR
        </div>
      )}
      <Row className="tk_status-container justify-content-around" xs={2} sm={3}>
        {dashboardStatusLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : (
          statusInfo.map((sI, index) => (
            <TKStatusCard
              key={index}
              iconName={sI.iconName}
              title={sI.title}
              amount={sI.amount}
              admin={isDashboard}
            />
          ))
        )}
      </Row>

      {isDashboard && (
        <>
          <div style={{ marginTop: "20px", fontSize: "25px", color: "black" }}>
            HABERLER
          </div>
          <Row
            className="tk_status-container justify-content-around"
            xs={2}
            sm={3}
          >
            {dashboardStatusLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              newsStatusInfo.map((sI, index) => (
                <TKStatusCard
                  key={index}
                  iconName={sI.iconName}
                  title={sI.title}
                  amount={sI.amount}
                  admin={true}
                />
              ))
            )}
          </Row>
          <div style={{ marginTop: "20px", fontSize: "25px", color: "black" }}>
            KAMPANYALAR
            <Row
              className="tk_status-container justify-content-around"
              xs={2}
              sm={3}
            >
              {dashboardStatusLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                campaignStatusInfo.map((sI, index) => (
                  <TKStatusCard
                    key={index}
                    iconName={sI.iconName}
                    title={sI.title}
                    amount={sI.amount}
                    admin={true}
                  />
                ))
              )}
            </Row>
          </div>

          <div style={{ marginTop: "20px", fontSize: "25px", color: "black" }}>
            LİSTELER
            <br></br>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col style={{ display: "flex", justifyContent: "flex-start" }}>
                <TKExcelExportButton
                  onClick={handleExportActiveUsers}
                  btnTitle="En Aktif Kullanıcılar"
                ></TKExcelExportButton>
                <TKExcelExportButton
                  onClick={handleExportActiveCompanies}
                  btnTitle="En Aktif Firmalar"
                ></TKExcelExportButton>
                <TKExcelExportButton
                  onClick={handleExportMostCampaigns}
                  btnTitle="En Çok Kampanyaya Katılanlar"
                ></TKExcelExportButton>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default TKStatusContainer;
TKStatusContainer.defaultProps = {
  isDashboard: false,
};
