import * as types from '../actionTypes';

// DASHBOARD
// LIST
export const getTKAdminDashboardRequest = reqParams => ({
  type: types.GET_TK_ADMIN_DASHBOARD_REQUEST,
  payload: reqParams
});

export const getTKAdminDashboardSucceeded = (data) => ({
  type: types.GET_TK_ADMIN_DASHBOARD_SUCCEEDED,
  payload: data

});

export const getTKAdminDashboardFailed = () => ({
  type: types.GET_TK_ADMIN_DASHBOARD_FAILED
});
