import React, { useEffect, useState } from "react";
import "./TKApiUserForm.scss";
import { Form, Row, Col } from "react-bootstrap";
import TKButton from "../../../TKButton/TKButton";
import TKSelect from "../../../TKSelect/TKSelect";
import { useDispatch, useSelector } from "react-redux";
import { getTKMWPNamesRequest } from "../../../../store/actions/memberWorkplaces";

const TKApiUserForm = ({
  formData,
  formDataErrors,
  onChange,
  handleOnKeyCreatorClick,
}) => {
  const dispatch = useDispatch();
  const { namesList } = useSelector((state) => state.memberWorkplaces);
  const [filteredNamesList, filteredSetNamesList] = useState(namesList);
  const [selectSearch, setSelectSearch] = useState("");

  useEffect(() => {
    dispatch(getTKMWPNamesRequest());
  }, [dispatch]);

  const onSelectChange = (name, value) => {
    if (value == "Backspace" && name === "companyId") {
      setSelectSearch("");
      filteredSetNamesList(namesList);
      onChange("companyId", "");
    }
    if (name === "companyId") {
      let searchparam = selectSearch.replace("i", "İ");

      filteredSetNamesList(
        namesList.filter(function (item) {
          let entitle = item.entitle.replace("i", "İ");

          if (
            entitle
              .toUpperCase()
              .startsWith((searchparam + value).toUpperCase())
          ) {
            return item;
          }
        })
      );

      if (value == "Backspace") {
        setSelectSearch("");
      } else if (
        value == "CapsLock" ||
        value == "ArrowDown" ||
        value == "ArrowUp" ||
        value == "ArrowLeft" ||
        value == "ArrowRight"
      ) {
      } else {
        setSelectSearch(searchparam + value);
      }
    }
  };
  return (
    <Form>
      <Form.Group>
        <Form.Label>Kullanıcı adı *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={formData.name}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          isInvalid={formDataErrors.caption}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Anahtar Tipi *</Form.Label>
        <Form.Control
          type="text"
          name="type"
          value={formData.type}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          isInvalid={formDataErrors.type}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>üye işyeri *</Form.Label>
        <TKSelect
          defaultTitle={formData?.companyId}
          selectedItem={formData?.companyId}
          itemSelector="entitle"
          items={filteredNamesList}
          itemOnClick={(id) => onChange("companyId", id)}
          isInvalid={formDataErrors.companyId}
          name="companyId"
          onChange={(value) => onSelectChange("companyId", value)}
          filter={true}
          search={selectSearch}
        />
        <Form.Text className="text-danger">{formDataErrors?.companyId}</Form.Text>
      </Form.Group>
      <Form.Group>
        <TKButton
          variant="light"
          onClick={(e) => {
            e.preventDefault();
            handleOnKeyCreatorClick();
          }}
        >
          Anahtar Oluştur
        </TKButton>
      </Form.Group>
    </Form>
  );
};

export default TKApiUserForm;

TKApiUserForm.defaultProps = {
  formData: {},
};
