import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import {
  getTKTKNAdminRequest,
  setShowTKNAddModal,
  setShowTKNEditModal,
  setShowTKNDeleteModal,
  createTKAdminTKNRequest,
  editTKAdminTKNRequest,
  deleteTKAdminTKNRequest,
} from "../../../../../store/actions/tkns";

import { faqColumnsConfig } from "../../../../../constants/columnsConfigurations";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKFAQForm from "../../../../../components/AdminLayout/TKForm/TKFAQForm/TKFAQForm";
import { EditorState, convertFromRaw } from "draft-js";
import { isJsonString } from "../../../../../helpers/utilities";

const TKTKN = () => {
  const initialFormData = {
    caption: "",
    detail: "",
    order_number: "",
  };
  const initialFormDataValidate = {
    caption: {
      required: true,
    },
    detail: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.tkns);
  const {
    list,
    page,
    processLoading,
    showAddModal,
    showEditModal,
    showDeleteModal,
  } = useSelector((state) => state.tkns.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = () => {
    dispatch(getTKTKNAdminRequest({}));
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowTKNAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowTKNAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        createTKAdminTKNRequest({
          data: { ...formData },
        })
      );
    }
  };

  const handleSetShowTKNEditModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowTKNEditModal(show));
  };

  const handleShowEditModalClick = (row) => {
    const { _id, caption, detail, order_number } = row;
    setSelectedId(_id);
    setFormData({
      caption,
      detail: isJsonString(detail)
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(detail)))
        : detail,
      order_number,
    });
    setFormDataValidate((prevState) => ({
      ...prevState,
    }));
    handleSetShowTKNEditModal(true);
  };

  const handleEditOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        editTKAdminTKNRequest({
          data: { ...formData },
          selectedId,
        })
      );
    }
  };

  const handleSetShowTKNDeleteModal = (show) => {
    dispatch(setShowTKNDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowTKNDeleteModal(true);
  };

  const handleDeleteOnClick = () => {
    dispatch(
      deleteTKAdminTKNRequest({
        selectedId,
      })
    );
  };

  const tknColumns = faqColumnsConfig({
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader header="Tuzla Kart Nedir yönetimi" />
      <TKAddNewButton
        showModal={showAddModal}
        setShowModal={handleSetShowTKNAddModal}
        btnTitle="Tuzla Kart Nedir"
        modalHeader="Tuzla Kart Nedir ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKFAQForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isCreate={true}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={tknColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            pagination={false}
            handleFetchData={filterData}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowTKNEditModal}
        header="Tuzla Kart Nedir güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
      >
        <TKFAQForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isEdit={true}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowTKNDeleteModal}
        header="Tuzla Kart Nedir silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Tuzla Kart Nedir başlığını silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKTKN;
