import * as types from '../actionTypes';

const initialState = {
  displayList: [],
  page: 0,
  hasMore: false,
  timedCamps: {
    currentCamps: [],
    futureCamps: [],
    oldCamps: []
  },
  displayCamps: {},
  loading: false,
  error: false,
  mwp: {
    list: [],
    page: 0,
    search: '',
    status: 'ACTIVE',
    totalPages: 0,
    processLoading: false,
    processError: false,
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false
  }
}

const memberWorkplaceCampaigns = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_TK_MWP_CAMPS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_MWP_CAMPS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        mwp: {
          ...state.mwp,
          list: [...action.payload.data],
          page: action.payload.page,
          search: action.payload.search,
          status: action.payload.status,
          totalPages: action.payload.totalPages
        }
      }
    case types.GET_TK_MWP_CAMPS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.CREATE_TK_MWP_CAMPS_REQUEST:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_MWP_CAMPS_ADD_MODAL:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          showAddModal: action.payload
        }
      }
    case types.EDIT_TK_MWP_CAMPS_REQUEST:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_MWP_CAMPS_EDIT_MODAL:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          showEditModal: action.payload
        }
      }
    case types.DELETE_TK_MWP_CAMPS_REQUEST:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_MWP_CAMPS_DELETE_MODAL:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          showDeleteModal: action.payload
        }
      }
    case types.PROCESS_TK_MWP_CAMPS_SUCCEEDED:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_MWP_CAMPS_FAILED:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: false,
          processError: true
        }
      }
    case types.CLEAR_TK_MWP_CAMPS:
      return { ...initialState }
    default:
      return state;
  }
}

export default memberWorkplaceCampaigns;