import React, { useEffect, useState } from "react";
import "./TKFilterContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import TKIcon from "../TKIcon/TKIcon";
import TKFilterDropdown from "../TKFilterDropdown/TKFilterDropdown";
import { getTKCategoriesRequest } from "../../store/actions/categories";
import { getTKMWPNamesRequest } from "../../store/actions/memberWorkplaces";
import { Form } from "react-bootstrap";
import TKSelect from "../TKSelect/TKSelect";
import { ililce } from "../../helpers/utilities";

const TKFilterContainer = ({
  filterTitle,
  category,
  selectedCategories,
  setSelectedCategories,
  brand,
  selectedBrands,
  setSelectedBrands,
  filterHandler,
  search,
  provincedistrict,
  formData,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.categories);
  const { namesList } = useSelector((state) => state.memberWorkplaces);
  const [searchText, setSearchText] = useState("");
  const [district, setDistrict] = useState(null);

  useEffect(() => {
    category && dispatch(getTKCategoriesRequest());
    brand && dispatch(getTKMWPNamesRequest());
  }, [dispatch, category, brand]);

  useEffect(() => {
    if (brand) {
      dispatch(getTKMWPNamesRequest(selectedCategories));
      selectedBrands.length > 0 && setSelectedBrands([]);
    }
    if (selectedCategories.length > 0) {
      filterHandler(selectedCategories, "categories");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, brand]);

  useEffect(() => {
    if (selectedBrands.length > 0) {
      filterHandler(selectedBrands, "companies");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrands]);

  useEffect(() => {
    if (searchText) {
      filterHandler(searchText, "search");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleSetFilters = (id, filterList, setFilterList, filterType) => {
    const idIndex = filterList.indexOf(id);
    if (idIndex !== -1) {
      const splicedFilterList = [...filterList];
      splicedFilterList.splice(idIndex, 1);
      if (splicedFilterList.length === 0) {
        if (filterType === "companies" && selectedCategories.length > 0) {
          filterHandler(selectedCategories, "categories");
        } else {
          filterHandler();
        }
      }
      return setFilterList(splicedFilterList);
    }
    setFilterList((prevState) => [...prevState, id]);
  };

  useEffect(() => {
    if (formData?.province) {
      const found = ililce.find((element) => element._id == formData.province);
      setDistrict(found.district);
      console.log(found.district);
    }
  }, [formData?.province]);

  return (
    <div className="bg-primary-light">
      <div className="container">
        <div className="tk_filter-container">
          <div className="tk_filter-title">
            <TKIcon name="group-2" color="#313131" size="20" />
            <span>{filterTitle} filtreleme</span>
          </div>

          <div className="tk_filter-select-group">
            {search && (
              <div className="tk_filter-search">
                <input
                  name="uye_isyeri"
                  placeholder="Üye İşyeri Ara"
                  class="tk_search-text-input form-control form-control-sm"
                  onChange={(text) => setSearchText(text.target.value)}
                  value={searchText}
                />
                {searchText && (
                  <button
                    className=""
                    onClick={() => {
                      filterHandler("", "search");
                      setSearchText("");
                    }}
                  >
                    x
                  </button>
                )}
              </div>
            )}
            {category && (
              <TKFilterDropdown
                title="Kategori Seç"
                items={list}
                itemSelector="name"
                selectedItems={selectedCategories}
                itemOnClick={(id) =>
                  handleSetFilters(
                    id,
                    selectedCategories,
                    setSelectedCategories
                  )
                }
              />
            )}
            {brand && (
              <TKFilterDropdown
                title="Marka Seç"
                items={namesList}
                itemSelector="entitle"
                selectedItems={selectedBrands}
                itemOnClick={(id) =>
                  handleSetFilters(
                    id,
                    selectedBrands,
                    setSelectedBrands,
                    "companies"
                  )
                }
              />
            )}
            {provincedistrict && (
              <div className="d-flex" style={{ gap: "5px" }}>
                <Form.Group
                  style={{ width: formData.province ? "50%" : "100%" }}
                >
                  <TKSelect
                    defaultTitle="İl Seç"
                    selectedItem={parseInt(formData?.province)}
                    itemSelector="province"
                    items={ililce}
                    itemOnClick={(id) => onChange("province", id)}
                    name="province"
                  />
                </Form.Group>
                {formData.province && district && (
                  <Form.Group className="flex-fill">
                    <TKSelect
                      defaultTitle="İlçe Seç"
                      selectedItem={parseInt(formData?.district)}
                      itemSelector="district"
                      items={district}
                      itemOnClick={(id) => onChange("district", id)}
                      name="district"
                    />
                  </Form.Group>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TKFilterContainer;

TKFilterContainer.defaultProps = {
  filterTitle: "",
  category: true,
  selectedCategories: [],
  setSelectedCategories: () => {},
  brand: false,
  selectedBrands: [],
  setSelectedBrands: () => {},
  filterHandler: () => {},
  provincedistrict: false,
};
