import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/complain';
import { 
  GET_TK_COMPLAIN_REQUEST,
  SEEN_TK_COMPLAIN_REQUEST
} from '../actionTypes';
import { apiCall } from '../../helpers/api';
import { customSwal } from '../../helpers/customSwal';



function* watchGetTKComplains() {
  yield takeEvery(GET_TK_COMPLAIN_REQUEST, getTKComplains);
}

function* watchSeenTKComplain() {
  yield takeEvery(SEEN_TK_COMPLAIN_REQUEST, seenTKComplain);
}



function* getTKComplains(action) {
  try {
    const res = yield call(apiCall, 
      'get',
      'api/loyalty/complain'
    );
    yield put(actions.getTKComplainsSucceeded({
      data: [...res.data.data]
    }));
  } catch (err) {
    yield put(actions.getTKComplainsFailed());
  }
}

function* seenTKComplain(action) {
  try {

  const {selectedId} = action.payload;
    yield call(apiCall, 
      'post',
      `api/loyalty/complain/${selectedId}/seen`
    );
    yield put(actions.getTKComplainsRequest());
    yield call(customSwal, 
      'Başarılı',
      'Şikayet görülme işlemi yapıldı...',
      'success'
    );
    yield put(actions.setShowComplainViewModal(false));
  } catch (err) {
    yield put(actions.getTKComplainsFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


export default function* complainsSaga() {
    yield all([
        fork(watchGetTKComplains),
        fork(watchSeenTKComplain)
      ]);
}