import React from 'react';
import { Form } from 'react-bootstrap';

const TKProfessionsForm = ({formData, formDataErrors, onChange}) => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>meslek adı *</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="Adı"
          value={formData.name || ""}
          onChange={e => {
            onChange(e.target.name, e.target.value);
          }}
          isInvalid={formDataErrors.name}
        />
        <Form.Text className="text-danger">{formDataErrors?.name}</Form.Text>
      </Form.Group>
    </Form>
  );
};

export default TKProfessionsForm;

TKProfessionsForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {}
}