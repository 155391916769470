import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/cardTypes';
import { 
  GET_TK_CARDTYPES_REQUEST,
  GET_TK_CARDTYPES_REQUEST_WITH_ALL_OPTION,
  CREATE_TK_ADMIN_CARDTYPES_REQUEST,
  DELETE_TK_ADMIN_CARDTYPES_REQUEST,
  SEARCH_TK_CARDTYPES_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';
import cardTypes from '../reducers/cardTypes';

function* watchGetTKCardTypes() {
  yield takeEvery(GET_TK_CARDTYPES_REQUEST, getTKCardTypes);
}

function* getTKCardTypes(action) {
  try {
    const res = yield call(apiCall, 
      'get', 
      'public/cardtype'
    );
    yield put(actions.getTKCardTypesSucceeded([...res.data.data]));
  } catch (err) {
    console.log(err)
    yield put(actions.getTKCardTypesFailed());
  }
}

function* watchGetTKCardTypesWithAllOption() {
  yield takeEvery(GET_TK_CARDTYPES_REQUEST_WITH_ALL_OPTION, getTKCardTypesWithAllOption);
}


function* getTKCardTypesWithAllOption(action) {
  
  const {cardtype} = action.payload;
  
  try {
    let res = yield call(apiCall, 
      'get', 
      'public/cardtype'
    );

    if (cardtype !== undefined)
    res.data.data.push({"_id":"11111111111","name":"TÜM KARTLAR","__v":0});

    yield put(actions.getTKCardTypesSucceeded([...res.data.data]));
  } catch (err) {
    console.log(err)
    yield put(actions.getTKCardTypesFailed());
  }
}


function* watchSearchTKCardTypes() {
  yield takeEvery(SEARCH_TK_CARDTYPES_REQUEST, searchTKCardTypes);
}

function* searchTKCardTypes(action) {
  try {
    const {search} = action.payload;
    const res = yield call(apiCall, 
      'post', 
      'api/loyalty/cardtype/searchcardtypes',
      {
        search
      }
    );
    
    console.log(res)

    yield put(actions.searchTKCardTypesSucceeded([...res.data.data]));
  } catch (err) {
    console.log(err)
    yield put(actions.searchTKCardTypesFailed());
  }
}

function* watchCreateTKAdminCardTypes() {
  yield takeLatest(CREATE_TK_ADMIN_CARDTYPES_REQUEST, createTKAdminCardTypes);
}

function* createTKAdminCardTypes(action) {
  try {
    const {data, handleSetShowCardTypesAddModal} = action.payload;
    yield call(apiCall, 
      'post', 
      'api/loyalty/cardtype', 
      {
        ...data
      }
    );
    yield put(actions.processTKAdminCardTypesSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kart Tipi başarıyla oluşturuldu',
      'success'
    );
    yield call(handleSetShowCardTypesAddModal, false);
    yield put(actions.getTKCardTypesRequest());
  } catch (err) {
    console.log(err)
    yield put(actions.processTKAdminCardTypesFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


function* watchDeleteTKAdminCardTypes() {
  yield takeLatest(DELETE_TK_ADMIN_CARDTYPES_REQUEST, deleteTKAdminCardTypes);
}

function* deleteTKAdminCardTypes(action) {
  try {
    const {selectedId} = action.payload;
    yield call(apiCall, 
      'put', 
      `api/loyalty/cardtype/${selectedId}/delete`
    );
    yield put(actions.processTKAdminCardTypesSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kart Tipi başarıyla silindi',
      'success'
    );
    yield put(actions.setShowCardTypesDeleteModal(false));
    yield put(actions.getTKCardTypesRequest());
  } catch (err) {
    yield put(actions.processTKAdminCardTypesFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

export default function* cardtypesSaga() {
  yield all([
    fork(watchGetTKCardTypes),
    fork(watchCreateTKAdminCardTypes),
    fork(watchDeleteTKAdminCardTypes),
    fork(watchSearchTKCardTypes),
    fork(watchGetTKCardTypesWithAllOption)
  ]);
}
