import React from "react";
import TKIcon from "../TKIcon/TKIcon";

const TKCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    title={children}
  >
    <span className="d-inline-block text-truncate overflow-hidden">
      {children}
    </span>
    <div className="d-inline-flex align-items-center">
      <TKIcon name="group" size="15" />
    </div>
  </button>
));

export default TKCustomToggle;
