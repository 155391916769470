import React, { useState } from "react";
import "./TKSelect.scss";
import { Dropdown } from "react-bootstrap";
import TKCustomToggle from "./TKCustomToggle";
import TKCustomDropdownMenu from "./TKCustomDropDownMenu";

const TKSelect = ({
  variant,
  id,
  defaultTitle,
  selectedItem,
  itemSelector,
  items,
  itemOnClick,
  isInvalid,
  onChange,
  show,
  search,
  filter,
}) => {
  const foundItem = items.find((item) => item._id === selectedItem);

  return (
    <Dropdown
      show={show}
      className={`tk_select ${variant ? `tk_select-${variant}` : ""} ${
        isInvalid ? "tk_select-invalid" : ""
      }`.trim()}
    >
      <Dropdown.Toggle as={TKCustomToggle} id={id}>
        {foundItem ? foundItem[itemSelector] : defaultTitle}
      </Dropdown.Toggle>
      {filter && (
        <Dropdown.Menu
          as={TKCustomDropdownMenu}
          search={search}
          show={show}
          items={items}
          onChange={(name, value) => onChange(name, value)}
          selectedItem={selectedItem}
          itemSelector={itemSelector}
          itemOnClick={(id) => {
            itemOnClick(id);
          }}
        ></Dropdown.Menu>
      )}
      {!filter && (
        <Dropdown.Menu>
          {items.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => itemOnClick(item._id)}
              className={`text-truncate ${
                item._id === selectedItem ? "tk_select-item-active" : ""
              }`.trim()}
            >
              {item[itemSelector]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default TKSelect;

TKSelect.defaultProps = {
  variant: "",
  filter: false,
  id: "tk-select",
  defaultTitle: "",
  selectedItem: "",
  itemSelector: "",
  items: [],
  itemOnClick: () => {},
  isInvalid: false,
};
