import { number } from 'prop-types';
import * as types from '../actionTypes';

const initialState = {
  loading: false,
  error: false,
  tckn:null,
  admin: {
    list: [],
    page: 0,
    search: '',
    searchFormData:{
      tckn:"",
      name:"",
      surname:"",
      card:"",
      role:"user",
      cardtype:undefined,
      status:"ACTIVE",
      created_start:null,
      created_end:null,
    },
    totalPages: 0,
    cardError:'',
    tcknError:'',
    processLoading: false,
    processError: false,
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false,
    showBalanceModal: false,
    cards: {
      list: [],
      processLoading: false,
      processError: false,
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
    },
    showLoadMoneyModal: false,
  }
}

const cardHolders = (state = initialState, action) => {

  switch(action.type) {
    case types.POST_TK_CHS_EXPORT_EXCEL_REQUEST:
       return {
         ...state
       }
    case types.GET_TK_ADMIN_CHS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        admin: {
          ...state.admin,
          searchFormData:action.payload.searchFormData
        }
      }
    case types.GET_TK_ADMIN_CHS_SUCCEEDED:

      console.log(action.payload.data)
      const cardHoldersList = action.payload.data.map(cardHoldersItem => {
        console.log(cardHoldersItem)
        cardHoldersItem.activeCard = "Yok";
        cardHoldersItem.cards.map(cardItem => {
          if (cardItem.status === "ACTIVE") {
            cardHoldersItem.activeCard = cardItem.number
          }
        });
        return cardHoldersItem
      },[])

      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...cardHoldersList],
          page: action.payload.page,
          search: action.payload.search,
          totalPages: action.payload.totalPages
        }
      }
    case types.GET_TK_ADMIN_CHS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
      case types.IS_CARD_NUMBER_ALREADY_USED_RESPONSE:
        return {
          ...state,
          loading: false,
          admin: {
            ...state.admin,
            cardError: action.payload.isUsed
          }
    } 
    case types.IS_TCKN_ALREADY_USED_RESPONSE:
      return {
        ...state,
        loading: false,
        admin: {
          ...state.admin,
          tcknError: action.payload.isUsed
        }
    } 
    case types.IS_CARD_NUMBER_ALREADY_USED_FAILED:
        return {
          ...state,
          loading: false,
          error: true
    } 
    case types.CREATE_TK_ADMIN_CHS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_CHS_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          tcknError:'',
          showAddModal: action.payload,
         
        },
      }
    case types.SET_SHOW_LOAD_MONEY_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showLoadMoneyModal: action.payload
        }
      }
    case types.LOAD_MONEY_TK_ADMIN_CHS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.EDIT_TK_ADMIN_CHS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_CHS_EDIT_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          tcknError:'',
          showEditModal: action.payload
        },
        
      }
    case types.DELETE_TK_ADMIN_CHS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_CHS_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload
        }
      }
    case types.PROCESS_TK_ADMIN_CHS_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_ADMIN_CHS_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: true
        }
      }
    case types.SET_TK_ADMIN_CARD_LIST:
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            list: action.payload
          }
        }
      }
    case types.CREATE_TK_ADMIN_CARD_REQUEST:
      // logic will be different for card
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            processLoading: true,
            processError: false
          }
        }
      }
    case types.SET_SHOW_CARD_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            showAddModal: action.payload
          },
          cardError: ''
        }
      }
    case types.EDIT_TK_ADMIN_CARD_REQUEST:
      // logic will be different for card
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            processLoading: true,
            processError: false
          }
        }
      }
    case types.UPDATE_TK_ADMIN_ALL_CARDS_REQUEST:
      // logic will be different for card
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            processLoading: true,
            processError: false
          }
        }
      }
    case types.SET_SHOW_CARD_EDIT_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            showEditModal: action.payload
          },
          cardError: ''
        }
      }
    case types.DELETE_TK_ADMIN_CARD_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            processLoading: true,
            processError: false
          }
        }
      }
    case types.SET_SHOW_CARD_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            showDeleteModal: action.payload
          }
        }
      }
    case types.PROCESS_TK_ADMIN_CARD_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            processLoading: false,
            processError: false
          }
        }
      }
    case types.PROCESS_TK_ADMIN_CARD_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          cards: {
            ...state.admin.cards,
            processLoading: false,
            processError: true
          }
        }
      }
    case types.SET_SHOW_CHS_BALANCE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showBalanceModal: action.payload,
        }
      }

    case types.TK_CHS_FORGOTPASSWORD_SUCCEEDED: 
     return {
       ...state,
      tckn : action.payload.data.tckn
     }
    default:
      return state;
  }
}

export default cardHolders;
