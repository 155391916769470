import * as types from '../actionTypes';


export const tkGetTransactionsRequest = () => ({
  type: types.GET_TK_TRANSACTIONS_REQUEST,
})


export const tkGetTransactionsSucceeded = transactions => ({
  type: types.GET_TK_TRANSACTIONS_SUCCEEDED,
  payload: transactions
})

export const tkGetTransactionsFailed = () => ({
  type: types.GET_TK_TRANSACTIONS_FAILED
})