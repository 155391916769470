import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/memberWorkplaceEmployees';
import { 
  GET_TK_MWP_EMPLOYEES_REQUEST,
  CREATE_TK_MWP_EMPLOYEES_REQUEST,
  DELETE_TK_MWP_EMPLOYEES_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';
import {userRoles} from '../../constants/general';

function* watchGetTKMWPEmployees() {
  yield takeEvery(GET_TK_MWP_EMPLOYEES_REQUEST, getTKMWPEmployees);
}

function* getTKMWPEmployees(action) {
  try {
    const res = yield call(apiCall, 
      'get', 
      'api/companyuser/employees', 
    );
    yield put(actions.getTKMWPEmployeesSucceeded([...res.data.data]));
  } catch (err) {
    console.log(err)
    yield put(actions.getTKMWPEmployeesFailed());
  }
}

function* watchCreateTKMWPEmployees() {
  yield takeLatest(CREATE_TK_MWP_EMPLOYEES_REQUEST, createTKMWPEmployees);
}

function* createTKMWPEmployees(action) {
  try {
    const {data, handleSetShowMWPEmployeesAddModal} = action.payload;
    yield call(apiCall, 
      'post', 
      'api/companyuser/addemployee', 
      {
        ...data
      }
    );
    yield put(actions.processTKMWPEmployeesSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Çalışan başarıyla eklendi.',
      'success'
    );
    yield call(handleSetShowMWPEmployeesAddModal, false);
    yield put(actions.getTKMWPEmployeesRequest());
  } catch (err) {
    console.log(err)
    yield put(actions.processTKMWPEmployeesFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


function* watchDeleteTKMWPEmployees() {
  yield takeLatest(DELETE_TK_MWP_EMPLOYEES_REQUEST, deleteTKMWPEmployees);
}

function* deleteTKMWPEmployees(action) {
  try {
    const {selectedId, selectedTckn} = action.payload;
    yield call(apiCall, 
      'post', 
      `api/companyuser/removeemployee`,
      {
        tckn: selectedTckn,
      }
    );
    yield put(actions.processTKMWPEmployeesSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Çalışan başarıyla silindi',
      'success'
    );
    yield put(actions.setShowMWPEmployeesDeleteModal(false));
    yield put(actions.getTKMWPEmployeesRequest());
  } catch (err) {
    yield put(actions.processTKMWPEmployeesFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

export default function* memberWorkplaceEmployeesSaga() {
  yield all([
    fork(watchGetTKMWPEmployees),
    fork(watchCreateTKMWPEmployees),
    fork(watchDeleteTKMWPEmployees),
  ]);
}
