import * as types from '../actionTypes';

// PUBLIC
export const clearTKMWPCamps = () => ({
  type: types.CLEAR_TK_MWP_CAMPS
});

// LIST
export const getTKMWPCampsRequest = reqParams => ({
  type: types.GET_TK_MWP_CAMPS_REQUEST,
  payload: reqParams
});

export const getTKMWPCampsSucceeded = ({data, page, search, status, totalPages}) => ({
  type: types.GET_TK_MWP_CAMPS_SUCCEEDED,
  payload: {
    data,
    page,
    search,
    status,
    totalPages
  }
});

export const getTKMWPCampsFailed = () => ({
  type: types.GET_TK_MWP_CAMPS_FAILED
});

// CREATE
export const createTKMWPCampsRequest = reqParams => ({
  type: types.CREATE_TK_MWP_CAMPS_REQUEST,
  payload: reqParams
});

export const setShowCampsAddModal = show => ({
  type: types.SET_SHOW_MWP_CAMPS_ADD_MODAL,
  payload: show
});

// EDIT
export const editTKMWPCampsRequest = reqParams => ({
  type: types.EDIT_TK_MWP_CAMPS_REQUEST,
  payload: reqParams
});

export const setShowCampsEditModal = show => ({
  type: types.SET_SHOW_MWP_CAMPS_EDIT_MODAL,
  payload: show
});

// DELETE
export const deleteTKMWPCampsRequest = reqParams => ({
  type: types.DELETE_TK_MWP_CAMPS_REQUEST,
  payload: reqParams
});

export const setShowCampsDeleteModal = show => ({
  type: types.SET_SHOW_MWP_CAMPS_DELETE_MODAL,
  payload: show
});

export const processTKMWPCampsSucceeded = () => ({
  type: types.PROCESS_TK_MWP_CAMPS_SUCCEEDED
});

export const processTKMWPCampsFailed = () => ({
  type: types.PROCESS_TK_MWP_CAMPS_FAILED
});