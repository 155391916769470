import * as types from '../actionTypes';

export const getTKTKNAdminRequest = reqParams => ({
    type: types.GET_TK_TKN_ADMIN_REQUEST,
    payload: reqParams
  });

  export const getTKTKNAdminSucceeded = ({data}) => ({
    type: types.GET_TK_TKN_ADMIN_SUCCEEDED,
    payload: {
      data
    }
  });
  
  export const getTKTKNAdminFailed = () => ({
    type: types.GET_TK_TKN_ADMIN_FAILED
  });

  export const setShowTKNAddModal = show => ({
    type: types.SET_SHOW_TKN_ADD_MODAL,
    payload: show
  });

  export const setShowTKNEditModal = show => ({
    type: types.SET_SHOW_TKN_EDIT_MODAL,
    payload: show
  });

  export const setShowTKNDeleteModal = show => ({
    type: types.SET_SHOW_TKN_DELETE_MODAL,
    payload: show
  });


  export const createTKAdminTKNRequest = reqParams => ({
    type: types.CREATE_TK_ADMIN_TKN_REQUEST,
    payload: reqParams
  
  })

  export const editTKAdminTKNRequest = reqParams => ({
    type: types.EDIT_TK_ADMIN_TKN_REQUEST,
    payload: reqParams
  
  })
  
  export const deleteTKAdminTKNRequest = reqParams => ({
    type: types.DELETE_TK_ADMIN_TKN_REQUEST,
    payload: reqParams
  
  })