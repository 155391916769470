import React from 'react';
import './TKPageHeader.scss';

const TKPageHeader = ({header}) => {
  return (
    <div className="tk_display-xxl tk_page-header">
      <div className="header-image">
      <div className="tk_display-xxl">
      <div className='container'>
        <div className="tk_display-text tk_display-xxl">
        {header}
        </div>
      </div>
      </div>
      </div>
      
      
    </div>
  );
};

export default TKPageHeader;

TKPageHeader.defaultProps = {
  header: ""
}