import React, { useEffect } from 'react';
import './TKComplainForm.scss';
import { Form, Row, Col } from 'react-bootstrap';
import TKDatePicker from '../../TKInputs/TKDatePicker/TKDatePicker';



const TKComplainForm = ({formData}) => {
    return (
      <Form>
      <Row>
      <Col> 
        <Form.Group>
          <Form.Label>Şikayet Başlığı</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={formData.title}
            readOnly
          />
        </Form.Group>
       </Col>
      <Col>
        <Form.Group>
          <Form.Label>Şikayet Detayı</Form.Label>
          <Form.Control as="textarea"  rows={10} value={formData.content}  readOnly/>
         </Form.Group>
      </Col>
      </Row>
      <Row>
      <Col>
         <Form.Group>
            <Form.Label>Şikayet Yapan Kişi</Form.Label>
            <Form.Control
            type="text"
            name="sender"
            value={formData.sender}
            readOnly
          />
        </Form.Group>
        </Col>
    </Row>
    <Row>
      <Col>
       <Form.Group>
          <Form.Label>Şikayet Tarihi</Form.Label>
          <TKDatePicker
              dateValue={formData.created_at}
              readOnly={true}
            />
        </Form.Group>  
      </Col>
    </Row>
    <Row>
      <Col>
      {formData.seen_at &&
      <Form.Group>
          <Form.Label>Görülme Tarihi</Form.Label>
          <TKDatePicker
              dateValue={formData.seen_at}
              readOnly={true}
            />
        </Form.Group>
        }
      </Col>
    </Row>  
      </Form>
    );
  };
  
  export default TKComplainForm;
  
  TKComplainForm.defaultProps = {
    formData: {}
  }