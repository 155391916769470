import React, { useEffect } from "react";
import "./MainLayout.scss";
import { Container } from "react-bootstrap";
import MainTopBar from "./MainTopBar";
import MainSideBar from "./MainSideBar";

const MainLayout = ({ children }) => {
  const contentToggleClass = 
    window.innerWidth <= 576 ? "content-close" : "content-open"
  ;
  useEffect(() => {
    const sidebar = document.getElementById("sidebar-wrapper");
    const content = document.getElementById("content-wrapper");
    const sidebarContent = document.getElementById("sidebar-content-wrapper");

    if (window.innerWidth <= 576) {
      sidebar.classList.remove("sidebar-open");
      sidebar.classList.add("sidebar-close");

      content.classList.remove("content-open");
      content.classList.add("content-close");

      sidebarContent.classList.remove("sidebar-content-open");
      sidebarContent.classList.add("sidebar-content-close");
    }
  }, []);
  return (
    <div>
      <MainTopBar {...children.props} />
      <div className="d-flex flex-row">
        <MainSideBar {...children.props} />
        <Container
          fluid
          className={"content " + contentToggleClass}
          id="content-wrapper"
        >
          <div className="tk_container-bg"></div>
          {children}
        </Container>
      </div>
    </div>
  );
};

export default MainLayout;
