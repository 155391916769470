import React from 'react';
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';

const TKMWPEmployeeForm = ({formData, formDataErrors, onChange}) => {
  return (
    <Form>
       <Form.Group>
        <Form.Label>tckn *</Form.Label>
        <Form.Control
          as={InputMask}
          type="text"
          name="tckn"
          placeholder="TCKN"
          value={formData.tckn || ""}
          onChange={e => {
            onChange(e.target.name, e.target.value);
          }}
          isInvalid={formDataErrors.tckn}
          mask="99999999999"
          maskChar={null}
        />
        <Form.Text className="text-danger">{formDataErrors?.tckn}</Form.Text>
      </Form.Group>
    </Form>
  );
};

export default TKMWPEmployeeForm;

TKMWPEmployeeForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {}
}