import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKAddNewButton from "../../../../../components/AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";

import {
  getTKAdminBannersRequest,
  setShowBannersAddModal,
  createTKAdminBannersRequest,
  setShowBannersDeleteModal,
  deleteTKAdminBannersRequest,
  setShowBannersEditModal,
  editTKAdminBannersRequest,
} from "../../../../../store/actions/banners";

import { bannersColumnsConfig } from "../../../../../constants/columnsConfigurations";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKBannerForm from "../../../../../components/AdminLayout/TKForm/TKBannerForm/TKBannerForm";

const TKBanners = () => {
  const DEFAULT_CONTENT_TYPE = "empty";

  const initialFormData = {
    caption: "",
    status: "ACTIVE",
    image: null,
    previousimage: null,
    banner: DEFAULT_CONTENT_TYPE,
    link: "",
    bannertype: "",
    order_number: ""
  };
  const initialFormDataValidate = {
    caption: {
      required: true,
    },
    status: {
      required: true,
    },
    image: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.banners);
  const {
    list,
    page,
    totalPages,
    processLoading,
    showAddModal,
    showEditModal,
    showDeleteModal,
  } = useSelector((state) => state.banners.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });

  useEffect(() => {
    filterData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = (page) => {
    dispatch(getTKAdminBannersRequest({}));
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    if (name !== "image") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        previousimage: null,
      }));
    }
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    console.log(errorMsgs);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowBannersAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowBannersAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();

    if (check) {
      const lastBannerNumber = list.reduce((max, banner) => {
        const { image } = banner;
        const num = image.match(/\d+(?=\.|$)/);
        if (num) {
          return Math.max(max, num);
        }
        return max;
      }, 1);
      let imageName = `banner${lastBannerNumber + 1}`;
      if (formData["image"].name.match(/\..+$/)?.length) {
        imageName = imageName + formData["image"].name.match(/\..+$/)[0];
      }

      dispatch(
        createTKAdminBannersRequest({
          data: { ...formData },
          page,
          imageName,
          handleSetShowBannersAddModal,
        })
      );
    }
  };

  const handleSetShowBannersEditModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowBannersEditModal(show));
  };

  const handleShowEditModalClick = (row) => {
    const { _id, caption, status, image, banner, link , order_number} = row;
    setSelectedId(_id);
    setFormData({
      caption,
      status,
      image: null,
      previousimage: image,
      banner: link,
      link: banner === "Haber" ? "" : banner === "Kampanya" ? "" : link,
      bannertype: banner,
      order_number
    });
    setFormDataValidate((prevState) => ({
      ...prevState,
      image: {},
    }));
    handleSetShowBannersEditModal(true);
  };

  const handleEditOnClick = () => {
    const check = checkValidation();
    let imageName = list.find((banner) => banner._id === selectedId).image;
    if (formData["image"] && formData["image"].name.match(/\..+$/)?.length) {
      imageName = imageName + formData["image"].name.match(/\..+$/)[0];
    }

    if (check) {
      dispatch(
        editTKAdminBannersRequest({
          data: { ...formData },
          selectedId,
          page,
          imageName,
          handleSetShowBannersEditModal,
        })
      );
    }
  };

  const handleSetShowBannersDeleteModal = (show) => {
    dispatch(setShowBannersDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowBannersDeleteModal(true);
  };

  const handleDeleteOnClick = () => {
    dispatch(
      deleteTKAdminBannersRequest({
        selectedId,
        page,
      })
    );
  };

  const bannersColumns = bannersColumnsConfig({
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAdminPageHeader header="afişler yönetimi" />
      <TKAddNewButton
        showModal={showAddModal}
        setShowModal={handleSetShowBannersAddModal}
        btnTitle="afiş"
        modalHeader="afiş ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKBannerForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isCreate={true}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={bannersColumns}
            data={list}
            sortedColumn={sortedColumn}
            sortDirection={sortDirection}
            activePage={1}
            totalPages={1}
            handleFetchData={filterData}
            pagination={false}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowBannersEditModal}
        header="afiş güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
      >
        <TKBannerForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowBannersDeleteModal}
        header="afiş silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Afişi silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKBanners;
