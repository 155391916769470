import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch } from 'react-redux';

const TKEditor = ({ editorState, setEditorState }) => {
  const dispatch = useDispatch();
  const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    // TODO: catch an error
    reader.onerror = (error) => {};
  };

  const imageUploadCallback = (file) =>
    new Promise((resolve, reject) =>
      getFileBase64(file, (data) => resolve({ data: { link: data } }))
    );

  return (
    <div className="tk_editor">
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(data) => setEditorState('detail', data)}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: imageUploadCallback },
        }}
      />
    </div>
  );
};

export default TKEditor;
