import React, { useRef,useState  } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import TKLogo from "../../TKLogo/TKLogo";
import TKIcon from "../../TKIcon/TKIcon";
import { useDispatch, useSelector } from "react-redux";
import { tkMWPForgotPasswordRequest } from "../../../store/actions/memberWorkplaces";

const TKMWPForgotPasswordForm = ({ setShowModal,handleShowModal }) => {
    
  const [error, setErrors] = useState('');


  const dispatch = useDispatch();

  const vknRef = useRef();

  function validate(e) {
    e.preventDefault();
    const vkn = vknRef?.current?.value;
    if (checkValidation()){
    dispatch(tkMWPForgotPasswordRequest({
        data: {
            vkn
        },
        handleForgotPasswordModal : show => setShowModal(show),
        handleNewPasswordModal : (show,field) => handleShowModal(show,field)
      }));
    }
  }

  const checkValidation = () => {
    
    const length = vknRef?.current?.value.length;

    if (length >= 10) {
        return true;
    }
    else {
        setErrors('Vergi Kimlik Numarası en az 10 haneli olmalı')
        return false;
    }

  }

  return (
    <>
       <Card className="border-0 tk-login-form">
        <Card.Header className="d-flex bg-primary p-3 justify-content-center align-items-center">
          <Card.Title className="m-0 d-flex justify-content-center align-items-center">
            <div>
              <TKLogo size={150} />
            </div>
            <span>
              <small>
                <i className="text-white">| Şifremi Unuttum</i>
              </small>
            </span>
            <div className="position-absolute" style={{ right: "10px", top: "10px", transform: "rotate(45deg)" }} onClick={() => setShowModal(false)}>
              <TKIcon name="plus" color="white" size="15" />
            </div>
          </Card.Title>
        </Card.Header>

        <Card.Body className="p-5">
        <Form>
          <Form.Group className="mb-2" controlId="formBasicName">
              <Form.Label> Lütfen Vergi Kimlik Numaranızı giriniz * </Form.Label>
              <Form.Control
               size="xs"
               maxLength="11"
               ref={vknRef}
                 />
              <Form.Text className="text-danger">{error}</Form.Text>
            </Form.Group>
            <div className="d-flex w-100 justify-content-end">
            <Button className="mt-2" variant="primary" type="submit"  onClick={(e) => validate(e)}>
              Sms Gönder
            </Button>
            </div>
        </Form>

        </Card.Body>
      </Card>
    </>
  );
};

export default TKMWPForgotPasswordForm;

TKMWPForgotPasswordForm.defaultProps = {
  setShowModal: () => {},
};
