import * as types from '../actionTypes';


export const tkGetAllTransactionsRequest = reqParams => ({
  type: types.GET_TK_ALL_TRANSACTIONS_REQUEST,
  payload: reqParams,
})


export const tkGetAllTransactionsSucceeded = ({data, page, totalPages, searchFormData}) => ({
  type: types.GET_TK_ALL_TRANSACTIONS_SUCCEEDED,
  payload: {
    data,
    page,
    searchFormData,
    totalPages
  }
})

export const tkGetAllTransactionsFailed = () => ({
  type: types.GET_TK_ALL_TRANSACTIONS_FAILED
})

export const tkPostAllTransactionsExportExcelRequest = reqParams => ({
  type: types.POST_TK_ALL_TRANSACTIONS_EXPORT_EXCEL_REQUEST,
  payload: reqParams
});