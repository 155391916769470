import React, { useState } from 'react';
import './TKCampaignRequestContainer.scss';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TKContentForm from '../AdminLayout/TKForm/TKContentForm/TKContentForm';
import TKModal from '../AdminLayout/TKModal/TKModal';
import { validate } from '../../helpers/validate';
import { preregisterTKMemberWorkplacesRequest } from '../../store/actions/memberWorkplaces';

const TKCampaignRequestContainer = ({...props}) => {
  const DEFAULT_TYPE = "PERCENT";
  const DEFAULT_STATUS = "ACTIVE";
  const DEFAULT_CAMPAIGN_CODE_TYPE = "GENERATE";

  const initialFormData = {
    short_detail: "",
    detail: "",
    plain_detail: "",
    image: null,
    start_at: null,
    finish_at: null,
    campaign_type: DEFAULT_TYPE,
    amount: "",
    owner: null,
    status: DEFAULT_STATUS,
    max_participant: "",
    campaign_code: { 
      type: DEFAULT_CAMPAIGN_CODE_TYPE,
      excel: null,
      fixed_code: null,
    },
  };

  const initialFormDataValidate = {
    short_detail: {
      required: true
    },
    detail: {
      required: true
    },
    image: {
      required: true
    },
    start_at: {
      required: true
    },
    finish_at: {
      required: true
    },
    campaign_type: {
      required: true
    },
    amount: {
      required: true,
      isNumeric: true,
      isPercent: "campaign_type",
    },
    owner: {
      required: true,
    },
    status: {
      required: true,
    },
    campaign_code: {
      isValidCampaignCodeGenerator: true,
    },
    max_participant: {
      isNumeric: true,
    }
  };


  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.campaigns);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [formDataValidate, setFormDataValidate] = useState({});

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({...errorMsgs});
      return false;
    }
    return true;
  }

  const handleShowModal = (show) => {
    setFormData({...initialFormData});
    setFormDataValidate({...initialFormDataValidate});
    setShowModal(show);
  }

  const handleOnChange = (name, value) => {
    formDataErrors[name] && 
      setFormDataErrors(prevState => ({
        ...prevState,
        [name]: ""
      }));
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      // public registration request
      // dispatch(preregisterTKMemberWorkplacesRequest({
      //   data: {...formData},
      //   handleShowModal
      // }))
      console.log("submit", formData);
    }
  }

  return (
    <div className="tk_campaign-request-container d-flex justify-content-end">
      <Button 
        variant="outline-primary" 
        onClick={() => handleShowModal(true)}
        className="tk_campaign-request-container-button"
      >
        Kampanya Başvurusu
      </Button>
      <TKModal
        className="tk_campaign-request-container-modal"
        show={showModal}
        setShow={handleShowModal}
        header="Kampanya Başvuru Formu"
        saveBtnTitle="Başvur"
        saveOnClick={handleSaveOnClick}
        processLoading={loading}
        size="xl"
      >
        <TKContentForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isPublic={true}
        />
      </TKModal>
    </div>
  );
};

export default TKCampaignRequestContainer;

TKCampaignRequestContainer.defaultProps = {
  setShowModal: () => {},
}