import React from 'react';
import './TKImageSlider.scss';
import TKSlider from '../TKSliderContainer/TKSlider/TKSlider';

const TKImageSlider = ({images}) => {
  return (
    <div className="tk_image-slider">
      <TKSlider customSettings={{ arrows: true }}>
        {images.map((image, i) => 
          <div key={i}>
            <div
              className="bg-img tk_image-slider-img"
              style={{
                backgroundImage: `url(${image ? 
                  `${process.env.REACT_APP_IMAGES_URL}${image}` : 
                  'https://media-cdn.tripadvisor.com/media/photo-m/1280/1b/33/f6/60/caption.jpg'}
                )`
              }}
            >
            </div>
          </div>
        )}
      </TKSlider>
    </div>
  );
};

export default TKImageSlider;

TKImageSlider.defaultProps = {
  images: []
}