import * as types from '../actionTypes';

const initialState = {
  dashboardStatus:{},
  dashboardStatusLoading: false,
  dashboardStatusError: false,
}

const dashboard = (state = initialState, action) => {


  switch(action.type) {
    case types.GET_TK_ADMIN_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboardStatusLoading: true,
        dashboardStatusError: false,
      }
    case types.GET_TK_ADMIN_DASHBOARD_SUCCEEDED:
      return {
        ...state,
        dashboardStatus: action.payload,
        dashboardStatusLoading: false,
        dashboardStatusError: false,
      }
    case types.GET_TK_ADMIN_DASHBOARD_FAILED:
      return {
        ...state,
        dashboardStatusLoading: false,
        dashboardStatusError: true,
      }
      default:
        return state;
    }
  }

  export default dashboard;