import * as types from "../actionTypes";

export const tkLoginRequest = (
  history,
  email,
  password,
  tckn,
  vkn,
  loginType,
  navigate
) => ({
  type: types.TK_LOGIN_REQUEST,
  payload: {
    history,
    email,
    tckn,
    password,
    vkn,
    loginType,
    navigate,
  },
});

export const tkLoginSucceeded = (user) => ({
  type: types.TK_LOGIN_SUCCEEDED,
  payload: user,
});

export const tkLoginFailed = () => ({
  type: types.TK_LOGIN_FAILED,
});

export const tkLogout = () => ({
  type: types.TK_LOGOUT,
});

export const tkForgotPasswordRequest = (email) => ({
  type: types.TK_FORGOTPASSWORD_REQUEST,
  payload: {
    email,
  },
});

export const tkForgotPasswordSucceeded = (user) => ({
  type: types.TK_FORGOTPASSWORD_SUCCEEDED,
  payload: user,
});

export const tkForgotPasswordFailed = () => ({
  type: types.TK_FORGOTPASSWORD_FAILED,
});

export const tkGetUserInfo = () => ({
  type: types.TK_USER_INFO_REQUEST,
});

export const tkUserInfoSucceeded = (userInfo) => ({
  type: types.TK_USER_INFO_SUCCEEDED,
  payload: userInfo,
});

export const tkUserInfoFailed = () => ({
  type: types.TK_USER_INFO_FAILED,
});

export const tkRegisterRequest = (data) => ({
  type: types.TK_REGISTER_REQUEST,
  payload: data,
});

export const tkRegisterSucceeded = (data) => ({
  type: types.TK_REGISTER_SUCCEEDED,
  payload: data,
});

export const tkRegisterFailed = () => ({
  type: types.TK_REGISTER_FAILED,
});

export const tkValidationRequest = (data) => ({
  type: types.TK_VALIDATE_REQUEST,
  payload: data,
});

export const tkValidationSucceeded = () => ({
  type: types.TK_VALIDATE_SUCCEEDED,
});

export const tkValidationFailed = () => ({
  type: types.TK_VALIDATE_FAILED,
});

export const tkValidationTimeOut = (data) => ({
  type: types.TK_VALIDATE_TIMEOUT,
  payload: data,
});

export const tkValidationTimeOutSucceeded = () => ({
  type: types.TK_VALIDATE_TIMEOUT_SUCCEEDED,
});

export const tkValidationTimeOutFailed = () => ({
  type: types.TK_VALIDATE_TIMEOUT_FAILED,
});
