import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAdminPageHeader from "../../../../../components/AdminLayout/TKAdminPageHeader/TKAdminPageHeader";
import TKTableCard from "../../../../../components/AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../../../components/AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../../../components/TKLoaderContainer/TKLoader/TKLoader";
import {
  getTKAdminMWPRequest,
  createTKAdminMWPRequest,
  setShowMWPAddModal,
  editTKAdminMWPRequest,
  setShowMWPEditModal,
  deleteTKAdminMWPRequest,
  setShowMWPDeleteModal,
  setShowMWPTransModal,
  postTKMWPExportExcelRequest,
  postTKMWPTransExportExcelRequest,
  exportTKCompanyDiscountExportExcelRequest,
} from "../../../../../store/actions/memberWorkplaces";
import { mwpColumnsConfig } from "../../../../../constants/columnsConfigurations";
import TKMemberWorkplacesForm from "../../../../../components/AdminLayout/TKForm/TKMemberWorkplacesForm/TKMemberWorkplacesForm";
import { validate } from "../../../../../helpers/validate";
import TKModal from "../../../../../components/AdminLayout/TKModal/TKModal";
import TKAddExportContainer from "../../../../../components/TKAddExportContainer/TKAddExportContainer";
import TKSearchText from "../../../../../components/TKSearchText/TKSearchText";
import TKReportDatePicker from "../../../../../components/AdminLayout/TKForm/TKReportDatePickerForm/TKReportDatePicker";
import { Height } from "@material-ui/icons";
import TKButton from "../../../../../components/TKButton/TKButton";
import { formatUrl } from "../../../../../helpers/utilities";
import { Form } from "react-bootstrap";

const TKMemberWorkplaces = () => {
  const rejectReasonInput = useRef();

  const DEFAULT_DISCOUNT_TYPE = "PERCENT";
  const DEFAULT_STATUS = "ACTIVE";
  const DEFAULT_IS_ONLINE = "true";

  const SEARCH_PARAMS = [
    {
      name: "vkn",
      holder: "Vergi Kimlik Numarası ile Ara",
    },
    {
      name: "entitle",
      holder: "İşletme Adı ile Ara",
    },
  ];

  const initialSearchFormData = {
    vkn: "",
    entitle: "",
    commercial_title: "",
    status: DEFAULT_STATUS,
  };

  const initialDatePickerFormData = {
    start_at: "",
    finish_at: "",
  };

  const initialFormData = {
    name: "",
    surname: "",
    personalEmail: "",
    vkn: "",
    entitle: "",
    address: "",
    phone: "",
    managerphone: "",
    email: "",
    web_site: "",
    image: null,
    previousimage: null,
    category: [],
    detail: "",
    landingPhone: "",
    status: DEFAULT_STATUS,
    isOnline: DEFAULT_IS_ONLINE,
    rejection: "",
    history_comment: "",
    deactivation_date: new Date(),
    contract_date: new Date(),
    position: {},
    discounts: [],
    province: "",
    district: "",
  };

  const initialFormDataValidate = {
    name: {
      required: true,
    },
    surname: {
      required: true,
    },
    personalEmail: {
      isEmail: true,
    },
    vkn: {
      required: true,
      minLenght: 10,
    },
    entitle: {
      required: true,
    },
    province: {
      required: true,
    },
    address: {
      required: true,
    },
    phone: {
      required: true,
      phoneLength: 10,
    },
    managerphone: {
      required: true,
      phoneLength: 10,
    },
    email: {
      isEmail: true,
    },
    web_site: {
      isWebSite: true,
    },
    image: {
      required: true,
    },
    category: {
      required: true,
    },
    status: {
      required: true,
    },
    position: {
      isPosition: true,
    },
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.memberWorkplaces);
  const {
    list,
    page,
    search,
    status,
    totalPages,
    processLoading,
    showAddModal,
    showEditModal,
    showDeleteModal,
    showTransModal,
  } = useSelector((state) => state.memberWorkplaces.admin);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({
    ...initialFormDataValidate,
  });
  const [searchFormData, setSearchFormData] = useState({
    ...initialSearchFormData,
  });
  const [formDatePickerData, setFormDatePickerData] = useState({
    ...initialDatePickerFormData,
  });
  const [smsFlag, setSmsFlag] = useState(false);
  const [exportCompanySelectedFlag, setExportCompanySelectedFlag] =
    useState(true);

  useEffect(() => {
    filterData(page, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData?.status == "PASSIVE") {
      console.log("IF", formDataValidate);
      setFormDataValidate((prevState) => ({
        ...prevState,
        rejection: { required: true },
      }));
    }
  }, [formData]);

  const doSearch = (name, value, stat) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    dispatch(
      getTKAdminMWPRequest({
        page: 0,
        searchFormData: {
          ...searchFormData,
          [name]: value,
        },
      })
    );
  };

  const filterData = (page) => {
    dispatch(
      getTKAdminMWPRequest({
        page,
        searchFormData,
      })
    );
  };

  const handleOnChange = (name, value) => {
    if (name === "discounttype") {
      let newDiscounts = formData.discounts.map((discount) => {
        discount.discounttype = value;
        return discount;
      });

      formDataErrors[name] &&
        setFormDataErrors((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      setFormData((prevState) => ({
        ...prevState,
        discounts: [...newDiscounts],
      }));
    } else {
      if (name !== "image") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
          previousimage: null,
        }));
      }
    }
  };

  const handleDatePickerOnChange = (name, value) => {
    setFormDatePickerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowMWPAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowMWPAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        createTKAdminMWPRequest({
          data: {
            ...formData,
            s_entitle: formatUrl(formData.entitle.trim()),
            entitle: formData.entitle.trim(),
            address: formData.address.trim(),
            email: formData.email.trim(),
            web_site: formData.web_site?.trim(),
            detail: formData.detail?.trim(),
          },
          page,
          search,
          status,
          handleSetShowMWPAddModal,
        })
      );
    }
  };

  const handleSetShowMWPEditModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowMWPEditModal(show));
  };

  const handleShowEditModalClick = (row) => {
    const {
      _id,
      name,
      surname,
      personalEmail,
      vkn,
      entitle,
      address,
      managerphone,
      phone,
      landingPhone,
      email,
      web_site,
      category,
      detail,
      status,
      amount,
      map_long,
      map_lat,
      discounts,
      image,
      province,
      district,
      rejection,
      history_comment,
      isOnline,
      deactivation_date,
      contract_date,
    } = row;
    setSelectedId(_id);
    if (status === "WFA") {
      setSmsFlag(true);
    } else {
      setSmsFlag(false);
    }
    setFormData({
      name,
      surname,
      personalEmail,
      vkn,
      entitle,
      address,
      managerphone,
      phone,
      landingPhone,
      email,
      web_site,
      category,
      detail,
      status,
      amount,
      image: null,
      position: {
        lat: map_lat,
        lng: map_long,
      },
      discounts,
      province,
      district,
      previousimage: image,
      rejection,
      history_comment,
      isOnline,
      deactivation_date,
      contract_date,
    });
    setFormDataValidate((prevState) => ({
      ...prevState,
      image: {},
    }));
    handleSetShowMWPEditModal(true);
  };

  const handleEditOnClick = () => {
    const check = checkValidation();

    if (check) {
      dispatch(
        editTKAdminMWPRequest({
          data: {
            ...formData,
            s_entitle: formatUrl(formData.entitle.trim()),
            entitle: formData.entitle.trim(),
            address: formData.address.trim(),
            email: formData.email.trim(),
            web_site: formData.web_site?.trim(),
            detail: formData.detail.trim(),
          },
          selectedId,
          page,
          searchFormData,
          status,
          smsFlag,
          handleSetShowMWPEditModal,
        })
      );
    }
  };

  const handleSetShowMWPDeleteModal = (show) => {
    dispatch(setShowMWPDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    setSelectedId(id);
    handleSetShowMWPDeleteModal(true);
  };

  const handleMWPTransExportModalClick = (id, exportCompanySelectedFlag) => {
    setSelectedId(id);
    setExportCompanySelectedFlag(exportCompanySelectedFlag);
    handleSetShowMWPTransExportModal(true);
  };

  const handleSetShowMWPTransExportModal = (show) => {
    dispatch(setShowMWPTransModal(show));
  };

  const handleMWPTransExportClick = () => {
    if (exportCompanySelectedFlag) {
      dispatch(
        exportTKCompanyDiscountExportExcelRequest({
          selectedId,
          formDatePickerData,
        })
      );
    } else {
      dispatch(
        exportTKCompanyDiscountExportExcelRequest({
          searchFormData,
          formDatePickerData,
        })
      );
    }
  };

  const handleExportClick = () => {
    dispatch(
      postTKMWPExportExcelRequest({
        searchFormData,
      })
    );
  };

  const handleDeleteOnClick = () => {
    const rejectreason = rejectReasonInput?.current?.value;
    const user = list.find((r) => r._id === selectedId);
    dispatch(
      deleteTKAdminMWPRequest({
        selectedId,
        page,
        vkn: user?.vkn,
        statucomment: rejectreason ? rejectreason : undefined,
        currentStatus: user?.status,
        searchFormData,
        status,
      })
    );
  };

  const mwpColumns = mwpColumnsConfig({
    onEdit: (row) => handleShowEditModalClick(row),
    onDelete: (id) => handleShowDeleteModalClick(id),
    onExport: (id) => handleMWPTransExportModalClick(id, true),
  });

  return (
    <>
      <TKAdminPageHeader header="üye işyerleri yönetimi" />

      <TKSearchText
        searchparams={SEARCH_PARAMS}
        doSearch={doSearch}
        status={searchFormData.status}
        isStatus={true}
      />

      <TKButton
        onClick={() => {
          setExportCompanySelectedFlag(false);
          handleSetShowMWPTransExportModal(true);
        }}
      >
        Seçili Üye İş Yerleri Rapor Al
      </TKButton>

      <TKAddExportContainer
        type="Üye İş Yeri"
        showAddModal={showAddModal}
        handleSetShowAddModal={handleSetShowMWPAddModal}
        modalHeader="üye işyeri ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
        size="xl"
        formData={formData}
        formDataErrors={formDataErrors}
        handleOnChange={handleOnChange}
        handleExportClick={handleExportClick}
      ></TKAddExportContainer>

      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={mwpColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            activePage={page}
            totalPages={totalPages}
            handleFetchData={filterData}
            searchedText={search}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowMWPEditModal}
        header="üye işyeri güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
        size="xl"
      >
        <TKMemberWorkplacesForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isEdit={true}
        />
      </TKModal>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowMWPDeleteModal}
        header="üye işyeri silme/red işlemleri"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        {list.find((r) => r._id === selectedId)?.status === "WFA" ? (
          <div>
            <div className="my-1">
              Başvuruyu reddetmek üzeresiniz, onaylıyor musunuz?
            </div>
            <div className="my-1">
              Onaylıyorsanız ret sebebini aşağıda belirtiniz:
            </div>
            <Form.Control
              as="textarea"
              ref={rejectReasonInput}
              placeHolder="Ret sebebi..."
              className="w-100"
              rows="4"
            />
          </div>
        ) : (
          "kart sahibi silmek üzeresiniz, onaylıyor musunuz?"
        )}
      </TKModal>
      <TKModal
        show={showTransModal}
        setShow={handleSetShowMWPTransExportModal}
        header="üye işyeri hareket formu"
        saveBtnTitle="Rapor Al"
        saveOnClick={handleMWPTransExportClick}
        processLoading={processLoading}
        size="md"
      >
        <>
          <div style={{ height: "450px" }}>
            <TKReportDatePicker
              formDatePickerData={formDatePickerData}
              onChange={handleDatePickerOnChange}
            ></TKReportDatePicker>
          </div>
        </>
      </TKModal>
    </>
  );
};

export default TKMemberWorkplaces;
