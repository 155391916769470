import * as types from '../actionTypes';

const initialState = {
  listMWPEmployees: [],
  loading: false,
  error: false,
  mwp: {
    processLoading: false,
    processError: false,
    showAddModal: false,
    showDeleteModal: false
  }
}

const memberWorkplaceEmployees = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_TK_MWP_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_MWP_EMPLOYEES_SUCCEEDED:
      return {
        ...state,
        listMWPEmployees: [...action.payload],
        loading: false,
        error: false
      }
    case types.GET_TK_MWP_EMPLOYEES_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.CREATE_TK_MWP_EMPLOYEES_REQUEST:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_MWP_EMPLOYEES_ADD_MODAL:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          showAddModal: action.payload
        }
      }
    case types.PROCESS_TK_MWP_EMPLOYEES_SUCCEEDED:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_MWP_EMPLOYEES_FAILED:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: false,
          processError: true
        }
      }
    case types.SET_SHOW_MWP_EMPLOYEES_DELETE_MODAL:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          showDeleteModal: action.payload,
        }
      }
    case types.DELETE_TK_MWP_EMPLOYEES_REQUEST:
      return {
        ...state,
        mwp: {
          ...state.mwp,
          processLoading: true,
          processError: false
        }
      }
    default:
      return state;
  }
};

export default memberWorkplaceEmployees;